// Functionality of this code : This page contains the University profile section
// Input : None
// Output : University profile
// Written by: Initial team VK, KR

import { Button, TextField } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as firebase from 'firebase';
import React, { useEffect, useImperativeHandle } from 'react';
import Box from '@material-ui/core/Box';
import school from '../assets/school.png';

// import { toast } from 'react-toastify';
import toast from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
// toast.configure();

const AutocompleteOverwrite = withStyles({
  root: {
    marginRight: 8,
    '& .MuiInputBase-root.Mui-disabled': {
      '& .MuiAutocomplete-popupIndicator': {
        color: 'rgba(0, 0, 0, 0)',
      },
      color: 'rgba(0, 0, 0, 1)',
    },
  },
})(Autocomplete);

const useStyles = makeStyles(theme => ({
  button: {
    width: 120,
  },
  input: {
    background: 'white',
  },
}));

//University Profile page
export const UniversityProfile = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    country: { title: 'Singapore' },
    state: { title: 'Singapore' },
    address: '',
    // universities: [{ name: '' }],
    universities: '',
    account_name: '',
    account_no: '',
    bank_name: '',
    domain: '',
    countries: [{ title: '' }],
    states: [{ title: '' }],
  });

  // const [university, setUniversity] = React.useState({ name: '' });
  const [university, setUniversity] = React.useState('');
  const [country, setCountry] = React.useState({ title: 'Singapore' });
  const [mystate, setMyState] = React.useState({ title: 'Singapore' });
  const role = props.role;
  const [error, setError] = React.useState('');

  useImperativeHandle(ref, () => ({
    getAlert() {
      submitPref();
    },
  }));

  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    // conutry
    fetch(process.env.REACT_APP_PROXY + '/data/countries', requestOptions)
      .then(res => res.json())
      .then(data => {
        if (data.success === false) {
          setError(data.message);
        } else {
          setState(prevState => {
            const countries = data.data;
            return { ...prevState, countries };
          });
        }
      });
    // STATE
    fetch(process.env.REACT_APP_PROXY + '/data/states', requestOptions)
      .then(res => res.json())
      .then(data => {
        if (data.success === false) {
          setError(data.message);
        } else {
          setState(prevState => {
            const states = data.data;
            return { ...prevState, states };
          });
        }
      });

    //university
    // fetch(process.env.REACT_APP_PROXY + '/data/university', requestOptions)
    //   .then(res => res.json())
    //   .then(data => {
    //     if (data.success === false) {
    //       setError(data.message);
    //     } else {
    //       setState(prevState => {
    //         const universities = data.data;
    //         return { ...prevState, universities };
    //       });
    //     }
    //   });
  }, []);

  useEffect(() => {
    if (state.countries.length >= 1 && state.states.length >= 1) {
      let uni = '';
      let unis = {};
      firebase
        .auth()
        .currentUser.getIdToken()
        .then(function (idToken) {
          const getRequestOptions = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              auth: idToken,
            },
          };
          fetch(process.env.REACT_APP_PROXY + '/user/pref', getRequestOptions)
            .then(res => res.json())
            .then(data => {
              if (data.success === false) {
                setError(data.message);
              } else {
                if (unis) {
                  if (typeof unis.find === 'function') {
                    setUniversity(
                      unis.find(d => d.name === data.message[0].university)
                    );
                  }
                }
                if (state.countries.length >= 1) {
                  let x = state.countries.find(d => d.title === 'Singapore');
                  if (x) {
                    setCountry(x);
                  }
                }
                const singaporeState = state.states.find(
                  d => d.title === 'Singapore'
                );
                if (singaporeState) {
                  setMyState(singaporeState);
                }

                if (unis) {
                  setUniversity(data.message[0].university || '');
                }

                uni = data.message[0].university;

                setState(prevState => {
                  const country = data.message[0].country;
                  const state = data.message[0].state;

                  const account_no = data.message[0].account_number;
                  const { account_name, bank_name, domain } = data.message[0];

                  const address = data.message[0].address;
                  let university = {};
                  if (unis) {
                    if (typeof unis.find === 'function') {
                      university = unis.find(
                        d => d.name === data.message[0].university
                      );
                    }
                  }
                  return {
                    ...prevState,
                    country,
                    state,
                    address,
                    university,
                    account_name,
                    account_no,
                    bank_name,
                    domain,
                  };
                });
              }
            });
        });

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fetch(process.env.REACT_APP_PROXY + '/data/university', requestOptions)
        .then(res => res.json())
        .then(data => {
          if (data.success === false) {
            setError(data.message);
          } else {
            if (uni !== '') {
              setUniversity(data.data.find(d => d.name === uni));
            }
            unis = data.data;
            setState(prevState => {
              const universities = data.data;
              return { ...prevState, universities };
            });
          }
        });
    }
  }, [state.countries, state.states]);

  const checkData = () => {
    var error = '';
    if (state.country.title && !error) {
      error = 'please set country';
    }
    if (state.state.title && !error) {
      error = 'please set state';
    }
    if (university && !error) {
      error = 'please set university';
    }
    if (state.address && !error) {
      error = 'please set address';
    }
    if (state.account_name && !error) {
      error = 'please set account name';
    }
    if (state.account_no && !error) {
      error = 'please set account no';
    }
    if (state.bank_name && !error) {
      error = 'please set bank name';
    }
    if (state.domain && !error) {
      error = 'please set domain';
    }

    setError(error);
    if (error) return true;
    else return false;
  };

  const submitPref = () => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(function (idToken) {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            auth: idToken,
          },
          body: JSON.stringify({
            state: mystate.title,
            country: country.title,
            university: university,
            address: state.address,
            account_name: state.account_name,
            account_number: state.account_no,
            bank_name: state.bank_name,
            domain: state.domain,
          }),
        };
        fetch(process.env.REACT_APP_PROXY + '/user/pref', requestOptions)
          .then(res => res.json())
          .then(data => {
            if (data.success) {
              toast.success('Deatails updated Successfully');
              props.setOpen(false);
            } else {
              toast.error(data.message, { autoClose: 2000 });
              setError(data.message);
            }
          })
          .catch(function (error) {
            toast.error(error, { autoClose: 2000 });
          });
      });
  };
  return (
    // <div className={classes.space}>
    //   <CardMedia
    //     style={{ height: '120px', width: '120px', float: 'left' }}
    //     image={school}
    //   />
    //   <br />
    //   <div style={{ marginLeft: 20 }}>
    //     {/* <AutocompleteOverwrite
    //       id="combo-box-demo"
    //       options={
    //         role === 'organization'
    //           ? state.countries.filter(w => w.title !== 'Any')
    //           : state.countries
    //       }
    //       getOptionLabel={option => option.title}
    //       style={{
    //         width: '90%',
    //         marginTop: 20,

    //         color: 'rgba(0, 0, 0, 1) !important',
    //         opacity: '1',
    //       }}
    //       value={country && country.title ? country : ''}
    //       onInputChange={e => {
    //         if (e && e.target.value !== '') {
    //         }
    //       }}
    //       onChange={(e, v, r) => {
    //         if (v != null) {
    //           setCountry(state.countries.find(d => d.title === v.title));
    //           setMyState('');
    //           setState(prevState => {
    //             const country = v.title;
    //             return { ...prevState, country };
    //           });
    //           setUniversity({ name: '' });
    //         }
    //       }}
    //       noOptionsText="Press confirm"
    //       renderInput={params => (
    //         <TextField
    //           {...params}
    //           label="Country"
    //           variant="outlined"
    //           className={classes.input}
    //         />
    //       )}
    //     /> */}
    //     {/* <AutocompleteOverwrite
    //       id="combo-box-demo"
    //       options={
    //         state.states.length > 1
    //           ? state.states.filter(w => {
    //               if (w && country) {
    //                 return (
    //                   w.title !== 'Any' &&
    //                   (country.title !== '' ? country.title === w.country : 1)
    //                 );
    //               }
    //             })
    //           : state.states
    //       }
    //       value={mystate}
    //       getOptionLabel={option => option.title}
    //       style={{ width: '90%', marginTop: 20 }}
    //       onInputChange={e => {
    //         if (e && e.target.value !== '') {
    //         }
    //       }}
    //       onChange={(e, v, r) => {
    //         if (v != null) {
    //           setMyState(state.states.find(d => d.title === v.title));
    //           setState(prevState => {
    //             const state = v.title;

    //             return { ...prevState, state };
    //           });
    //         }
    //       }}
    //       noOptionsText="Press confirm"
    //       renderInput={params => (
    //         <TextField
    //           {...params}
    //           label="State"
    //           variant="outlined"
    //           className={classes.input}
    //         />
    //       )}
    //     /> */}
    //      {/* <AutocompleteOverwrite
    //       id="combo-box-demo"
    //       options={
    //         role === 'student'
    //           ? state.universities.filter(
    //               w =>
    //                 w.name !== 'Any' &&
    //                 (mystate !== '' ? mystate.title === w.state : 1) &&
    //                 (country !== '' ? country.title === w.country : 1)
    //             )
    //           : state.universities.filter(
    //               w =>
    //                 w.name === 'Any' ||
    //                 ((mystate !== '' ? mystate.title === w.state : 1) &&
    //                   (country !== '' ? country.title === w.country : 1))
    //             )
    //       }
    //       value={university}
    //       getOptionLabel={option => option.name}
    //       style={{ width: '100%', marginTop: 20 }}
    //       onInputChange={e => {
    //         if (e && e.target.value !== '') {
    //           // Handle input change if needed
    //         }
    //       }}
    //       onChange={(e, v, r) => {
    //         if (v != null) {
    //           setUniversity(
    //             v.name ? state.universities.find(d => d.name === v.name) : 'Any'
    //           );
    //           setState(prevState => {
    //             let university;
    //             if (v.name) {
    //               university = v.name;
    //             } else {
    //               university = 'Any';
    //             }
    //             return { ...prevState, university };
    //           });
    //         }
    //       }}
    //       noOptionsText="Press confirm"
    //       renderInput={params => (
    //         <TextField
    //           {...params}
    //           label="University"
    //           variant="outlined"
    //           className={classes.input}
    //         />
    //       )}
    //     />  */}
    //     <br />

    //     <TextField
    //       variant="outlined"
    //       className={classes.input}
    //       value={university}
    //       onChange={e => setUniversity(e.target.value)}
    //     />

    //     <TextField
    //       label="Address"
    //       fullWidth
    //       style={{ width: '90%', marginTop: 25 }}
    //       variant="outlined"
    //       value={state.address}
    //       onChange={e => {
    //         e.persist();
    //         setState(prevState => {
    //           const address = e.target.value;
    //           return { ...prevState, address };
    //         });
    //       }}
    //       className={classes.input}
    //     />
    //     <TextField
    //       label="Account Name"
    //       fullWidth
    //       style={{ width: '90%', marginTop: 25 }}
    //       variant="outlined"
    //       value={state.account_name}
    //       onChange={e => {
    //         e.persist();
    //         setState(prevState => {
    //           const account_name = e.target.value;
    //           return { ...prevState, account_name };
    //         });
    //       }}
    //       className={classes.input}
    //     />
    //     <TextField
    //       label="Account No."
    //       fullWidth
    //       style={{ width: '90%', marginTop: 25 }}
    //       variant="outlined"
    //       value={state.account_no}
    //       onChange={e => {
    //         e.persist();
    //         setState(prevState => {
    //           const account_no = e.target.value;
    //           return { ...prevState, account_no };
    //         });
    //       }}
    //       className={classes.input}
    //     />
    //     <TextField
    //       label="Name Of Bank"
    //       fullWidth
    //       value={state.bank_name}
    //       style={{ width: '90%', marginTop: 25 }}
    //       variant="outlined"
    //       onChange={e => {
    //         e.persist();
    //         setState(prevState => {
    //           const bank_name = e.target.value;
    //           return { ...prevState, bank_name };
    //         });
    //       }}
    //       className={classes.input}
    //     />

    //     <TextField
    //       label="Domain"
    //       fullWidth
    //       value={state.domain}
    //       style={{ width: '90%', marginTop: 25 }}
    //       variant="outlined"
    //       onChange={e => {
    //         e.persist();
    //         setState(prevState => {
    //           const domain = e.target.value;
    //           return { ...prevState, domain };
    //         });
    //       }}
    //       className={classes.input}
    //     />
    //   </div>
    //   <br />
    //   <br />

    //   {!props.disabled ? (
    //     <Box display="flex" flexDirection="row-reverse" p={1} m={1}>
    //       <Button onClick={submitPref} color="primary">
    //         Submit
    //       </Button>
    //     </Box>
    //   ) : (
    //     ''
    //   )}
    // </div>

    <div className="admin-modal2 preferences-modal">
      <div className="form-bottom">
        <form action="">
          <div className="form-group">
            <div className="top-admin">
              <div className="admin-img">
                <img src={school} alt="" />
              </div>
              <input
                type="text"
                placeholder="University name"
                value={university}
                onChange={e => setUniversity(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group">
            <input
              type="text"
              placeholder="Address"
              value={state.address}
              onChange={e => {
                e.persist();
                setState(prevState => {
                  const address = e.target.value;
                  return { ...prevState, address };
                });
              }}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              placeholder="Account Name"
              value={state.account_name}
              onChange={e => {
                e.persist();
                setState(prevState => {
                  const account_name = e.target.value;
                  return { ...prevState, account_name };
                });
              }}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              placeholder="Account No."
              value={state.account_no}
              onChange={e => {
                e.persist();
                setState(prevState => {
                  const account_no = e.target.value;
                  return { ...prevState, account_no };
                });
              }}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              placeholder="Name of Bank"
              value={state.bank_name}
              onChange={e => {
                e.persist();
                setState(prevState => {
                  const bank_name = e.target.value;
                  return { ...prevState, bank_name };
                });
              }}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              placeholder="Domain"
              value={state.domain}
              onChange={e => {
                e.persist();
                setState(prevState => {
                  const domain = e.target.value;
                  return { ...prevState, domain };
                });
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
});
