import React from 'react';
import './style.css';
import sponserImg from '../../assets/newui/Sponser.png';

const Sponser = props => {
  return (
    <div>
      <div className="tabs-content">
        <h3>For Sponsor</h3>
        <div className="text-img-box">
          <div className="left-box student-details-box">
            <div className="student-position-box">
              <div className="com-student-details">
                <div className="text-box">
                  <h4>Sponsor a student</h4>
                  <p>
                    With our unique direct- to - payment model, you can pay to
                    the educational or commercial institute to fulfil the
                    student need.
                  </p>
                </div>
                <div className="counter-box">
                  <span>3</span>
                </div>
              </div>
            </div>
          </div>
          <div className="center-box student-details-box">
            <div className="img-box">
              <img src={sponserImg} alt="" />
            </div>
          </div>
          <div className="right-box student-details-box">
            <div className="student-position-box">
              <div className="com-student-details">
                <div className="counter-box">
                  <span>1</span>
                </div>
                <div className="text-box">
                  <h4>Create your profile</h4>
                  <p>
                    Register on the site and set your sponsorship preferences.
                  </p>
                </div>
              </div>
              <div className="com-student-details">
                <div className="counter-box">
                  <span>2</span>
                </div>
                <div className="text-box">
                  <h4>Receive a notification</h4>
                  <h4>
                    <p>
                      Based on your preference a student request will be sent to
                      you.
                    </p>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-box">
          <a
            style={{ background: '#77000D' }}
            onClick={() => {
              props.setPage('auth');
            }}
            className="btn btn-primary"
          >
            Get Started
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sponser;
