// Functionality of this code : This code contains voucher details sponsor having
// Input : None
// Output : Sponsor Voucher details
// Written by: CIS

import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as firebase from 'firebase';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import tableIcons from '../admin/MaterialTableIcons';
import Typography from '@material-ui/core/Typography';

export default function SponsorVoucher(props) {
  const theme = useTheme();
  const [gOptions, setG] = React.useState([]);
  const [gifts, setGift] = React.useState([]);
  const [gOptionsUsed, setGUsed] = React.useState([]);
  const [giftsUsed, setGiftUsed] = React.useState([]);
  const columns = [
    // {
    //   title: 'Voucher id',
    //   field: 'id',
    // },
    {
      title: 'Voucher Name',
      field: 'label',
    },
    {
      title: 'Amount',
      field: 'amount',
    },
    {
      title: 'Category',
      field: 'category',
    },
    {
      title: 'Expiry Date',
      field: 'expiry',
      type: 'date',
    },
  ];
  function handleClick() {
    props.setPage('uniqegift');
    props.setData('');
  }
  function handleClickOnline() {
    props.setPage('uniqegiftOnline');
    props.setData('');
  }
  const columnsG = [
    {
      title: 'Voucher Name',
      field: 'label',
    },
    {
      title: 'Amount',
      field: 'amount',
    },
    {
      title: 'Category',
      field: 'category',
    },
    {
      title: 'Gifted To',
      field: 'email',
    },
    {
      title: 'Gifted Date',
      field: 'date',
      type: 'date',
    },
  ];

  const onBack = () => {
    props.setPage('dashboard');
  };

  let temp = [];
  let tempUsed = [];

  const dataF = async () => {
    await firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then(async function (tokenResult) {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            auth: tokenResult.token,
          },
          body: JSON.stringify({ tokenResult }),
        };
        let val;
        fetch(process.env.REACT_APP_PROXY + '/user/cartItem', requestOptions)
          .then(res => res.json())
          .then(async data => {
            data.data.map(e => {
              val = e;
              gifts.push(val);
            });

            await gifts.map(e => {
              let obj = {};
              obj.value = e.id;
              obj.label = e.product_name;
              obj.id = e.id;
              obj.amount = e.amount;
              obj.food_option = e.food_option;
              obj.category = e.category;
              obj.product = e.product_id;
              obj.expiry = e.expiry_date;
              obj.url = e.url;
              temp.push(obj);
              setG([...temp]);
            });
          })
          .catch(error => {});

        fetch(process.env.REACT_APP_PROXY + '/user/cartItemAll', requestOptions)
          .then(res => res.json())
          .then(async data => {
            data.data.map(e => {
              val = e;
              giftsUsed.push(val);
            });

            await giftsUsed.map(e => {
              let obj = {};
              obj.value = e.id;
              obj.label = e.product_name;
              obj.id = e.id;
              obj.amount = e.amount;
              obj.product = e.product_id;
              obj.food_option = e.food_option;
              obj.email = e.email;
              obj.category = e.category;
              obj.date = e.gift_date;
              obj.expiry = e.expiry_date;
              obj.url = e.url;
              tempUsed.push(obj);
              setGUsed([...tempUsed]);
            });
          })
          .catch(error => {});
      });
  };

  React.useEffect(() => {
    dataF();
  }, []);

  return (
    <>
      <Container
        maxWidth="lg"
        component="main"
        style={{ paddingTop: 90, marginBottom: '10px' }}
      >
        <Typography
          variant="body1"
          paragraph
          align="center"
          style={{ fontSize: '22px' }}
        >
          Purchased Vouchers
        </Typography>
        <MaterialTable
          // isLoading={gOptions.length > 0 ? false : true}
          icons={tableIcons}
          title=""
          options={{
            actionsColumnIndex: -1,
            search: false,
            pageSize: 3,
            pageSizeOptions: [3, 5, 10, 20],
            toolbar: false,
            paging: true,
            headerStyle: {
              backgroundColor: theme.palette.primary.main,
              color: 'white',
            },
            actionsCellStyle: {
              minWidth: 80,
            },
          }}
          columns={columns}
          data={gOptions}
        />
        <button
          // variant="contained"
          className="btn btn-dark"
          style={{
            margin: '20px 30% 0',
            backgroundColor: '#232323',
            color: '#f2f2f2',
            fontSize: '19px',
            fontWeight: '700',
            padding: '6px 27px',
            '&:hover': {
              background: '#232323',
            },
          }}
          onClick={handleClick}
        >
          Buy New Voucher
        </button>
        <button
          // variant="contained"
          // color="dark"
          className="btn btn-dark"
          style={{
            margin: '20px -25% 0',
            backgroundColor: '#232323',
            color: '#f2f2f2',
            fontSize: '19px',
            fontWeight: '700',
            padding: '6px 27px',
            '&:hover': {
              background: '#232323',
            },
          }}
          onClick={handleClickOnline}
        >
          Buy New Voucher Online
        </button>
      </Container>
      <Container
        maxWidth="lg"
        component="main"
        style={{ paddingTop: 90, marginBottom: '70px' }}
      >
        <Typography
          variant="body1"
          paragraph
          align="center"
          style={{ fontSize: '22px' }}
        >
          Gifted Vouchers
        </Typography>
        <MaterialTable
          // isLoading={gOptionsUsed.length > 0 ? false : true}
          icons={tableIcons}
          title=""
          options={{
            actionsColumnIndex: -1,
            search: false,
            pageSize: 3,
            pageSizeOptions: [3, 5, 10, 20],
            toolbar: false,
            paging: true,
            headerStyle: {
              backgroundColor: theme.palette.primary.main,
              color: 'white',
            },
            actionsCellStyle: {
              minWidth: 80,
            },
          }}
          columns={columnsG}
          data={gOptionsUsed}
        />
        <button
          className="btn btn-dark"
          style={{
            width: '14%',
            height: '40px',
            margin: '20px 40% -50px',
            backgroundColor: '#232323',
            color: '#f2f2f2',
            fontSize: '19px',
            fontWeight: '700',
            padding: '6px 27px',
            '&:hover': {
              background: '#232323',
            },
          }}
          onClick={onBack}
        >
          Back
        </button>
      </Container>
    </>
  );
}
