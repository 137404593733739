import React from 'react';
import './style.css';
import organiztionImg from '../../assets/newui/organisation.png';

const Organisation = props => {
  return (
    <div>
      <div className="tabs-content">
        <h3>For Organisation</h3>
        <div className="text-img-box">
          <div className="left-box student-details-box">
            <div className="student-position-box">
              <div className="com-student-details">
                <div className="text-box">
                  <h4>View the reports</h4>
                  <p>
                    View the reports on the number of requests raised by
                    students referred by your organization and how many requests
                    were supported.
                  </p>
                </div>
                <div className="counter-box">
                  <span>3</span>
                </div>
              </div>
            </div>
          </div>
          <div className="center-box student-details-box">
            <div className="img-box">
              <img src={organiztionImg} alt="" />
            </div>
          </div>
          <div className="right-box student-details-box">
            <div className="student-position-box">
              <div className="com-student-details">
                <div className="counter-box">
                  <span>1</span>
                </div>
                <div className="text-box">
                  <h4>Create your organisation profile</h4>
                  <p>Register on the site.</p>
                </div>
              </div>
              <div className="com-student-details">
                <div className="counter-box">
                  <span>2</span>
                </div>
                <div className="text-box">
                  <h4>Refer students</h4>
                  <h4>
                    <p>
                      Provide the reference access code for students who apply
                      through your organization.
                    </p>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-box">
          <a
            style={{ background: '#77000D' }}
            onClick={() => {
              props.setPage('auth');
            }}
            className="btn btn-primary"
          >
            Get Started
          </a>
        </div>
      </div>
    </div>
  );
};

export default Organisation;
