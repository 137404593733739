// Functionality of this code : This code contains student application request
// Input : None
// Output : Student application
// Written by: CIS
import '../css/studentRequest.css';
import React, { useEffect, useState, useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField } from '@material-ui/core';
import FlatButton from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import axios from 'axios';
import { Autocomplete } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import NumberFormat from 'react-number-format';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';
import * as firebase from 'firebase';
// import Backdrop from '@material-ui/core/Backdrop';
// import CircularProgress from '@material-ui/core/CircularProgress';
import LaptopChromebookIcon from '@material-ui/icons/LaptopChromebook';
// import PaymentIcon from '@material-ui/icons/Payment';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import books from '../assets/icon2.png';
import housing from '../assets/housing.jpg';
import laptop from '../assets/laptop.jpg';
import school from '../assets/icon1.png';
import insurance from '../assets/insurance.jpg';
import moment from 'moment';
// import mealPlan from '../assets/mealPlan.jpg';
import airmiles from '../assets/airmiles.jpg';
import glasses from '../assets/icon3.png';
import mrt from '../assets/mrt.jpg';
import certification from '../assets/certification.jpg';
import accessibility from '../assets/accessibility.jpg';
import counsel from '../assets/counsel.png';
import others from '../assets/others.jpg';
import { withStyles } from '@material-ui/core/styles';
// import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';
import { formatDate } from '../utils/dateUtils';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../utils/spinner';
import toast from 'react-hot-toast';
import { createTooltip } from '../utils/MyTooltip';
import { setDate } from 'date-fns';
import AlertMessage from '../common/AlertMessage';
import { useDispatch, useSelector } from 'react-redux';
import { SetIsEligible } from '../store/actions/eligibilityAction';
import { v4 as uuidv4 } from 'uuid';

// toast.configure();

// const AutocompleteOverwrite = withStyles({
//   root: {
//     marginRight: 8,
//     '& .MuiInputBase-root.Mui-disabled': {
//       '& .MuiAutocomplete-popupIndicator': {
//         color: 'rgba(0, 0, 0, 0)',
//       },
//       color: 'rgba(0, 0, 0, 1)',
//     },
//   },
// })(Autocomplete);

function MilesFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  const [loading, setLoading] = React.useState(false);

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      suffix="mi"
    />
  );
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

function NumberFormatCustom2(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

function MilesNeeded(props) {
  const useStyles2 = makeStyles({
    iptext: {
      width: '45%',
      margin: '4px 10px 10px 10px',
      '@media (max-width: 767px)': {
        width: '100%',
      },
    },
  });
  const classes = useStyles2();
  const [values, setValues] = React.useState({
    textmask: '(1  )    -    ',
    numberformat: '1000',
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    props.setState(prevState => {
      const need_amount = event.target.value;
      return { ...prevState, need_amount };
    });
  };

  return (
    <TextField
      className={classes.iptext}
      label={props.name}
      onChange={handleChange}
      value={props.state.need_amount}
      name="numberformat"
      id="formatted-numberformat-input"
      variant="outlined"
      InputProps={{
        inputComponent: MilesFormatCustom,
      }}
    />
  );
}

// function FundNeeded(props) {
//   const useStyles2 = makeStyles({
//     iptext: {
//       width: '45%',
//       margin: '4px 10px 10px 10px',
//       '@media (max-width: 767px)': {
//         width: '100%',
//       },
//     },
//   });
//   const classes = useStyles2();
//   const [values, setValues] = React.useState({
//     textmask: '(1  )    -    ',
//     numberformat: '1000',
//   });
//   const [error, setError] = React.useState('');
//   const handleChange = event => {
//     var val = event.target.value;
//     if (val < 0) {
//       setError('please enter correct values');
//     } else if (props.name === 'Laptop' && val > 1000) {
//       setError('cannot exceed $1000');
//     } else if (props.name === 'Books' && val > 50) {
//       setError('cannot exceed $50');
//     } else if (props.name === 'Food and Beverage' && val > 50) {
//       setError('cannot exceed $50');
//     } else if (props.name === 'MRT' && val > 50) {
//       setError('cannot exceed $50');
//     } else if (props.name === 'Others' && val > 50) {
//       setError('cannot exceed $50');
//     } else if (props.name === 'Tuition' && val > 100) {
//       setError('cannot exceed $100');
//     } else if (val > 100) {
//       setError('cannot exceed $100');
//     } else {
//       setError('');
//     }
//     setValues({
//       ...values,
//       [event.target.name]: event.target.value,
//     });
//     props.setState(prevState => {
//       const need_amount = event.target.value;
//       return { ...prevState, need_amount };
//     });
//   };

//   return (
//     <TextField
//       className={classes.iptext}
//       label={props.name === 'Laptop' ? 'Cost of ' + props.name : 'Amount'}
//       onChange={handleChange}
//       value={props.state.need_amount}
//       placeholder={
//         props.name === 'Laptop'
//           ? 'cannot exceed $1000'
//           : props.name === 'Books'
//           ? 'cannot exceed $50'
//           : props.name === 'Food and Beverage'
//           ? 'cannot exceed $50'
//           : props.name === 'MRT'
//           ? 'cannot exceed $50'
//           : props.name === 'Others'
//           ? 'cannot exceed $50'
//           : props.name === 'Tuition'
//           ? 'cannot exceed $100'
//           : 'cannot exceed $100'
//       }
//       name="numberformat"
//       id="formatted-numberformat-input"
//       error={error}
//       variant="outlined"
//       InputProps={{
//         inputComponent: NumberFormatCustom,
//       }}
//     />
//   );
// }

// function ShareWith(props) {
//   const useStyles2 = makeStyles({
//     iptext: {
//       margin: '4px 10px 10px 10px',
//       width: '94%',
//       '@media (max-width: 767px)': {
//         width: '100%',
//       },
//     },
//   });
//   const classes = useStyles2();
//   return (
//     <div style={{ width: '100%' }}>
//       <Typography variant="subtitle1" color="textSecondary">
//         Share your request with your own sponsor network (optional)
//       </Typography>
//       <br />
//       <div style={{ width: '100%' }}>
//         <TextField
//           id="outlined-textarea"
//           label="emails to share with"
//           placeholder="Press enter to add more emails"
//           multiline
//           variant="outlined"
//           className={classes.iptext}
//           rows={4}
//           rowsMax={10}
//         />
//       </div>
//     </div>
//   );
// }

const useStyles = makeStyles(theme => ({
  cardMedia: {
    width: 120,
  },
  card: { wordWrap: 'break-word', minHeight: '257.09px' },
  cardContenth1: {
    color: '#ed0a3f',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    whiteSpace: 'nowrap',
    lineHeight: '30px',
    marginLeft: '12px',
  },
  cardGrid: { width: 1012 },
  cardContentDetail: {
    backgroundColor: 'transparent',
    height: '87px',
    letterSpacing: '0.20px',
    textAlign: 'left',
    wordWrap: 'break-word',
    marginLeft: '12px',
  },
  rectangleBox: {
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #00000029',
    height: '400px',
    left: '278px',
    position: 'absolute',
    top: '589px',
    width: '414px',
  },
  headLoginButton: {
    backgroundColor: '#232323',
    color: '#f2f2f2',
    '&:hover': {
      background: '#232323',
    },
  },
}));

const VolunteerHoursSlider = props => {
  const useStyles2 = makeStyles({
    root: {
      width: 250,
    },
    input: {
      width: 42,
    },
    block1: {
      width: '100%',
      margin: '10px 0px 10px 0px',
      '@media (max-width: 767px)': {
        width: '100%',
      },
    },
    block2: {
      width: '47%',
      float: 'left',
      '@media (max-width: 767px)': {
        width: '100%',
      },
    },
    main: {
      width: '50%',
      float: 'right',
      '@media (max-width: 767px)': {
        width: '100%',
        marginRight: '15px',
      },
    },
    main2: {
      width: '50%',
      float: 'left',
      '@media (max-width: 767px)': {
        width: '100%',
        marginRight: '15px',
      },
    },
    icon: {
      width: '10%',
      float: 'left',
      marginTop: '14px',
      '@media (max-width: 767px)': {},
    },
    ipbox: {
      width: '70%',
      float: 'right',
      marginRight: '28px',
      '@media (max-width: 767px)': {
        float: 'left',
      },
    },
    textp: {
      '@media (max-width: 767px)': {
        marginLeft: '-10px',
      },
    },
  });
  const classes = useStyles2();
  return (
    <div className={classes.block1}>
      {/* <div className={classes.block2}>
        <Typography
          color="primary"
          id="input-slider"
          gutterBottom
          className={classes.textp}
        >
          Indicate the volunteer hours you will commit to
        </Typography>
      </div> */}
      <div className={classes.main2}>
        <div className={classes.icon}>
          {' '}
          <WatchLaterIcon />
        </div>
        <div className={classes.ipbox}>
          <TextField
            label="Volunteer hours"
            onChange={e => {
              if (e.target.value) props.setVolunteer_hours(e.target.value);
            }}
            value={props.volunteer_hours ? props.volunteer_hours : 1}
            placeholder={
              props.state.need_amount
                ? 'hrs not more then ' + props.state.need_amount / 10
                : ''
            }
            name="numberformat"
            id="formatted-numberformat-input"
            variant="outlined"
            InputProps={{
              inputComponent: NumberFormatCustom2,
            }}
          />
        </div>
      </div>
      <br />
    </div>
  );
};

const DonationTimesSlider = props => {
  const [value, setValue] = React.useState(0);
  const handleSliderChange = (event, newValue) => {
    props.setRequests_willing_to_donate(newValue);
    setValue(newValue);
  };
  const useStyles2 = makeStyles({
    root: {
      width: 250,
      marginTop: 20,
    },
    input: {
      width: '100%',
      paddingLeft: 5,
    },
    main: {
      width: '100%',
      margin: '10px 0px 10px 0px',
      '@media (max-width: 767px)': {
        width: '100%',
      },
    },
    ptext: {
      width: '30%',
      float: 'left',
      '@media (max-width: 767px)': {
        width: '100%',
      },
    },
    icon: {
      width: '7%',
      float: 'left',
      marginTop: '14px',
      '@media (max-width: 767px)': {
        width: '100%',
      },
    },
    block1: {
      width: '70%',
      float: 'right',
      '@media (max-width: 767px)': {
        width: '100%',
      },
    },
    block2: {
      width: '43%',
      float: 'left',
      '@media (max-width: 767px)': {
        width: '85%',
        marginLeft: '50px',
        marginTop: '-45px',
      },
    },
    block3: {
      width: '50%',
      float: 'right',
      '@media (max-width: 767px)': {
        width: '100%',
        marginTop: '10px',
        marginRight: '5px',
      },
    },
    block4: {
      width: '80%',
      color: 'rgba(0, 0, 0, 1) !important',
      opacity: '1',
      marginRight: 25,
      '@media (max-width: 767px)': {
        width: '100%',
      },
    },
  });
  const classes = useStyles2();
  return (
    <div className={classes.main}>
      {/* <div> */}
      {/* <Typography color="primary" id="input-slider" gutterBottom>
          Indicate the number of requests you will sponsor
        </Typography> */}
      <Typography color="primary" id="input-slider" gutterBottom>
        Thank you for agree to sign up as a sponosor in future.
      </Typography>
      {/* </div> */}
      {/* <div className={classes.block1}>
        <div className={classes.icon}>
          <PaymentIcon />
        </div>
        <div className={classes.block2}>
          <TextField
            label="number of requests"
            onChange={e => {
              if (e.target.value)
                props.setRequests_willing_to_donate(e.target.value);
            }}
            value={
              props.requests_willing_to_donate
                ? props.requests_willing_to_donate
                : null
            }
            name="numberformat"
            id="formatted-numberformat-input"
            variant="outlined"
            InputProps={{
              inputComponent: NumberFormatCustom2,
            }}
          />
        </div>
        <div className={classes.block3}>
          <AutocompleteOverwrite
            id="combo-box-demo"
            options={requestTypes}
            getOptionLabel={option => option.name}
            className={classes.block4}
            onInputChange={e => {
              if (e && e.target.value !== '') {
              }
            }}
            onChange={(e, v, r) => {}}
            // value={props.state.request_type}
            noOptionsText="Please select"
            renderInput={params => (
              <TextField
                {...params}
                label="Request Types"
                variant="outlined"
                className={classes.input}
              />
            )}
          />
        </div>
      </div> */}
      <br />
    </div>
  );
};

const ProjectHoursSlider = props => {
  const useStyles2 = makeStyles({
    root: {
      width: 250,
      marginTop: 20,
    },
    input: {
      width: 42,
    },
  });
  const classes = useStyles2();
  return (
    <div style={{ width: '100%', margin: '10px 0px 10px 0px' }}>
      <div style={{ width: '53%', float: 'left' }}>
        <Typography color="primary" id="input-slider" gutterBottom>
          Indicate the project hours you will commit to
        </Typography>
      </div>
      <br />
      <div style={{ width: '43%', float: 'right' }}>
        <div style={{ width: '10%', float: 'left', marginTop: '14px' }}>
          {' '}
          <LaptopChromebookIcon />
        </div>
        <div style={{ width: '85%', float: 'right' }}>
          <TextField
            label="Project hours"
            onChange={e => {
              if (e.target.value) props.setMini_project_hours(e.target.value);
            }}
            value={props.mini_project_hours ? props.mini_project_hours : null}
            name="numberformat"
            id="formatted-numberformat-input"
            variant="outlined"
            InputProps={{
              inputComponent: NumberFormatCustom2,
            }}
          />
        </div>
      </div>
    </div>
  );
};

function GiveBackDialog(props) {
  const useStyles2 = makeStyles({
    iptext: {
      width: '45%',
      margin: '4px 10px 10px 10px',
      '@media (max-width: 767px)': {
        width: '100%',
      },
    },
    mainblock: {
      width: '100%',
      margin: '10px',
      '@media (max-width: 767px)': {
        width: '100%',
      },
    },
    block1: {
      margin: '4px 10px 10px 10px',
      float: 'left',
      width: '45%',
      '@media (max-width: 767px)': {
        width: '100%',
      },
    },
    block2: {
      margin: '4px 10px 10px 10px',
      float: 'right',
      width: '45%',
      '@media (max-width: 767px)': {
        width: '100%',
        marginRight: '-10px',
      },
    },
    block3: {
      width: '95%',
      margin: '4px 10px 10px 10px',
      padding: '10px 0px',
      '@media (max-width: 767px)': {
        width: '100%',
      },
    },
    block4: {
      margin: '4px 0px 10px',
      width: '91%',
      marginBottom: '-60px',
      '@media (max-width: 767px)': {
        width: '100%',
      },
    },
  });
  const classes = useStyles2();
  const [showPayItNowResults, setShowPayItNowResults] = React.useState(false);
  const onClickPayItNow = val => {
    setShowPayItNowResults(val);
    setShowPayItForwardResults(!val);
    props.setCheck(true);
  };

  const [showPayItForwardResults, setShowPayItForwardResults] =
    React.useState(false);
  const onClickPayItForward = val => {
    setShowPayItForwardResults(val);
    setShowPayItNowResults(!val);
    props.setCheck(true);
  };

  return (
    <div>
      <br />
      <Typography variant="h6" color="primary">
        I Pledge To
      </Typography>
      <Divider />
      {/* <Typography variant="subtitle1" color="textSecondary">
        I pledge to ...
      </Typography> */}
      <br />
      <div>
        <div className={classes.block1}>
          <Typography variant="subtitle1" color="black">
            Volunteer{' '}
            <Checkbox
              // value="remember"
              value={showPayItNowResults}
              checked={showPayItNowResults}
              onClick={e => {
                onClickPayItNow(e.target.checked);
              }}
              color="primary"
            />
          </Typography>
        </div>
        <div className={classes.block2}>
          <Typography variant="subtitle1" color="black">
            Sponsor Others{' '}
            <Checkbox
              // value="remember"
              color="primary"
              value={showPayItForwardResults}
              checked={showPayItForwardResults}
              onClick={e => {
                onClickPayItForward(e.target.checked);
              }}
            />
          </Typography>
        </div>
      </div>
      <br />
      <div className={classes.mainblock}>
        {showPayItNowResults ? (
          <div style={{ width: '100%' }}>
            <div className={classes.block3}>
              <VolunteerHoursSlider
                setVolunteer_hours={props.setVolunteer_hours}
                volunteer_hours={props.volunteer_hours}
                state={props.state}
              />
              <br />
            </div>
            <div style={{ width: '100%' }}>
              <Typography variant="subtitle1" color="primary">
                Skills
              </Typography>
              <TextField
                variant="outlined"
                id="standard-search"
                type="search"
                placeholder="For example - Microsoft, tutoring, coding, etc."
                multiline
                rows={4}
                rowsMax={10}
                className={classes.block4}
              />
            </div>
            <br />
          </div>
        ) : (
          ''
        )}

        {showPayItForwardResults ? (
          <div>
            {showPayItForwardResults && showPayItNowResults ? (
              <Typography variant="subtitle1" color="black">
                <br /> Pay it Forward
              </Typography>
            ) : (
              ''
            )}
            <DonationTimesSlider
              setRequests_willing_to_donate={
                props.setRequests_willing_to_donate
              }
              requests_willing_to_donate={props.requests_willing_to_donate}
              state={props.state}
            />
            <br />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

// pop out card for applying new request for students

const MealProviders = [
  { title: 'Halal' },
  { title: 'Non-Halal' },
  // { title: 'University Meal Plan' },
];
const MrtProvider = [{ title: 'SMRT' }];
const GlassProviders = [{ title: 'e-voucher' }];
const InsuranceProviders = [{ title: 'NTUC' }, { title: 'Great Eastern' }];

function NewApp(props) {
  const classes = useStyles();
  const [giveBackOpen, setGiveBackOpen] = React.useState(false);
  const [value, setValue] = React.useState('University');
  const [show, isShow] = React.useState(false);
  const [volunteer_hours, setVolunteer_hours] = React.useState(1);
  const [requests_willing_to_donate, setRequests_willing_to_donate] =
    React.useState(0);
  const [mini_project_hours, setMini_project_hours] = React.useState(0);
  const [future_sponsor_count, Setfuture_sponsor_count] = useState(0);
  // const applicationDetails = useSelector(state => state.user.application);

  const handleDateChange = date => {
    setState(prevState => {
      setStartDate(date);
      const need_dateline = moment(date);
      return { ...prevState, need_dateline };
    });
  };

  const handleRadioChange = event => {
    if (event.target.value === 'External')
      setError('an external page will pop up to sponsor a meal plan');
    setValue(event.target.value);
  };

  const useStyles2 = makeStyles({
    root: {
      width: '50%',
      height: '50px',
      marginTop: '30px',
      background: '#F5F5F5',
      borderRadius: '4px',
      border: 'none',
      padding: '15px 20px',
      fontSize: '16px',
      fontWeight: 400,
      fontFamily: 'Roboto',
      '& .MuiInputBase-root': {
        background: 'inherit',
        padding: '0',
        '& input': {
          padding: '0',
          fontSize: 'inherit',
          fontWeight: 'inherit',
          lineHeight: 'inherit',
          fontFamily: 'inherit',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiInputAdornment-root': {
        marginRight: '0',
      },
      '@media (max-width: 767px)': {
        width: '100%',
        margin: '30px 13px 0px 10px',
        float: 'none',
      },
    },
    iptextmrt: {
      width: '45%',
      float: 'right',
      marginTop: '-65px',
      margin: '4px 10px 10px 10px',
      '@media (max-width: 767px)': {
        width: '100%',
        marginTop: '0px',
        float: 'none',
      },
    },
    iptextuition: {
      width: '45%',
      margin: '4px 10px 10px 10px',
      '@media (max-width: 767px)': {
        width: '100%',
        float: 'none',
      },
    },
    iptext2: {
      width: '45%',
      margin: '4px 10px 10px 10px',
      '@media (max-width: 767px)': {
        width: '100%',
      },
    },
    coffeetext: {
      width: '45%',
      marginTop: 5,
      marginLeft: '10px',
      color: 'rgba(0, 0, 0, 1) !important',
      opacity: '1',
      '@media (max-width: 767px)': {
        width: '100%',
      },
    },
    i2text: {
      width: '45%',
      marginTop: 5,
      marginLeft: '10px',
      color: 'rgba(0, 0, 0, 1) !important',
      opacity: '1',
      '@media (max-width: 767px)': {
        width: '100%',
        marginLeft: '10px',
      },
    },
    reach: {
      width: '100%',
      paddingTop: '50px',
      '@media (max-width: 767px)': {
        width: '100%',
        marginTop: '-25px',
      },
    },
  });
  const classes2 = useStyles2();
  // const top100Films = [
  //   { title: 'SG Edu Bursary', year: 1994 },
  //   { title: 'MOE Bursary', year: 1972 },
  //   { title: 'President Scholarship', year: 1974 },
  // ];
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');
  const [invoice, setInvoice] = React.useState('');
  const [payment_url, setPayment] = React.useState('');
  const [file, setFile] = React.useState({});
  const [filename, SetFileName] = useState('');
  const [fileBus, setFileBus] = React.useState({});
  const [startDate, setStartDate] = React.useState(null);
  const [food_option, setFoodOption] = React.useState('');
  const [check, setCheck] = useState(false);
  const [selectedSkill, setselectedSkill] = useState('Microsoft office');
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedF: true,
    checkedG: true,
    need_amount: null,
    // need_dateline: moment(new Date()).add(15, 'days').toDate(),
    need_dateline: null,
    status: 'Active',
    amount_fulfilled: 0,
    account_no: '',
    account_name: '',
    bank_name: '',
    platform_provider: '',
    request_type: '',
  });
  const [Loading, SetLoading] = React.useState(false);

  const dispatch = useDispatch();
  const { isEligible, message } = useSelector(state => state.eligibility);

  function handleEligibleResponse(response) {
    let message = '';
    switch (response.type) {
      case 'limit_exceeded':
        message = 'You have exceeded the limit for this type.';
        break;
      case 'pending_fulfillment':
        message = "Your previous request hasn't been fulfilled yet.";
        break;
      case 'not_time_eligible':
        message = `You will be eligible to apply after ${formatDate(
          response.nextDate
        )}`;
        break;
      default:
        message = 'Something went wrong. Please try again later.';
    }
    dispatch(SetIsEligible(false, message));
  }

  useEffect(() => {
    const checkEligibility = async (needType, idToken) => {
      try {
        SetLoading(true);
        const response = await fetch(
          process.env.REACT_APP_PROXY +
            `/user/check-eligibility?need_type=${needType}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              auth: idToken,
            },
          }
        );

        const data = await response.json();

        if (data.success) {
          dispatch(SetIsEligible(true, ''));
          SetLoading(false);
        } else if (!data.success) {
          dispatch(SetIsEligible(false, ''));
          handleEligibleResponse(data);
          SetLoading(false);
        }
      } catch (error) {
        console.error('Request failed:', error);
        SetLoading(false);
      }
    };

    // Get the Firebase user's ID token and check eligibility
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(idToken => {
        // Call the checkEligibility function with a needType (example: 'tution')
        if (props.name !== 'Default') {
          checkEligibility(props.name, idToken);
        }
      })
      .catch(error => {
        console.error('Failed to get ID token:', error);
      });
  }, [props]);

  // Centralized reset function
  const resetFormState = useCallback(() => {
    setFile({});
    setFileBus({});
    setStartDate(null);
    setFoodOption('');
    setCheck(false);
    setselectedSkill('');
    setState({
      checkedA: false,
      checkedB: false,
      checkedF: true,
      checkedG: true,
      need_amount: null,
      need_dateline: null,
      status: 'Active',
      amount_fulfilled: 0,
      account_no: '',
      account_name: '',
      bank_name: '',
      platform_provider: '',
      request_type: '',
    });
  }, []);

  const handleClose = () => {
    resetFormState();
    props.setOpen(false);
    dispatch(SetIsEligible(false, ''));
  };
  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const hiddenFileInput = React.useRef(null);

  const hiddenFileInputBus = React.useRef(null);

  // const saveFile = e => {
  //   setFile(e.target.files[0]);
  // };

  const saveFile = e => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileType = selectedFile.type;

      // Only allow image file types
      if (!fileType.startsWith('image/')) {
        e.target.value = '';
        alert('Only image files are allowed!');
        return;
      }
      setFile(e.target.files[0]);
      console.log('Valid file selected:', file);
    }
  };

  const handleSkillChange = e => {
    setselectedSkill(e.target.value);
  };

  const deleteFile = e => {
    e.preventDefault();
    setFile({});
  };
  const deleteFileBus = e => {
    e.preventDefault();
    setFileBus({});
  };
  const saveFileBus = e => {
    setFileBus(e.target.files[0]);
  };

  const formData = new FormData();
  const formDataBus = new FormData();

  const submitApp = status => {
    if (!state.need_amount) {
      toast.error('Please add amount');
      return;
    } else if (!state.need_dateline) {
      toast.error('Please add a date', {
        autoClose: 2000,
      });
      return;
    } else if (!volunteer_hours) {
      toast.error('you have to give back at least one category', {
        autoClose: 2000,
      });
      setGiveBackOpen(true);
      return;
    }
    if (state.need_amount < 0) {
      toast.error('please enter correct amount', { autoClose: 2000 });
      return;
    }
    if (state.need_amount >= 1000 && state.need_type === 'Laptop') {
      toast.error('exceed donation amount of $1000!');
      return;
    } else if (state.need_type === 'Books' && state.need_amount >= 50) {
      toast.error('exceed donation amount of $50!');
      return;
    } else if (
      state.need_type === 'Food and Beverage' &&
      state.need_amount >= 50
    ) {
      toast.error('exceed donation amount of $50!');
      return;
    } else if (state.need_type === 'MRT' && state.need_amount >= 50) {
      toast.error('exceed donation amount of $50!');
      return;
    } else if (state.need_type === 'Others' && state.need_amount >= 50) {
      toast.error('exceed donation amount of $50!');
      return;
    } else if (state.need_amount >= 100 && state.need_type === 'Tuition') {
      toast.error('exceed donation amount of $100!');
      return;
    } else if (state.need_amount >= 100 && state.need_type === 'Air miles') {
      toast.error('exceed donation amount of $100!');
      return;
    } else if (!invoice) {
      toast.error('Please add invoice number');
      return;
    } else if (!payment_url) {
      toast.error('Please add Payment URL');
      return;
    } else if (!file.name) {
      toast.error('Please add File Or QR Code ');
      return;
    } else if (!check) {
      toast.error('Please mark at least one checkbox');
      return;
    }

    const allowedExtensions = ['image/jpeg', 'image/png'];
    const fileType = file.type;

    console.log('fileType:', fileType);

    if (!allowedExtensions.includes(fileType)) {
      toast.error('Only JPG and PNG files are allowed', { autoClose: 2000 });
      return;
    }

    SetLoading(true);
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async function (idToken) {
        const uniqueFileName = `${uuidv4()}${file.name}`;
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            auth: idToken,
          },
          body: JSON.stringify({
            need_type: props.name,
            need_amount: parseInt(state.need_amount),
            need_dateline: state.need_dateline,
            status: status === 'Active' ? 'Active' : 'Draft',
            amount_fulfilled: state.amount_fulfilled,
            application_no: state.application_no,
            volunteer_hours,
            food_option: food_option,
            invoice_number: invoice,
            payment_url: payment_url,
            requests_willing_to_donate,
            file: uniqueFileName,
            fileBus: formDataBus,
            mini_project_hours,
            future_sponsor_count,
            selectedSkill,
          }),
        };
        if (
          props.name === 'Tuition' ||
          props.name === 'Books' ||
          props.name === 'Pair Of Glasses'
        ) {
          const requestOptionsFile = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              auth: idToken,
            },
          };
          formData.append('myFile', file);
          formData.append('name', uniqueFileName);
          try {
            await axios
              .post(
                process.env.REACT_APP_PROXY + '/user/uploadQR',
                formData,
                requestOptionsFile,
                {}
              )
              .then(async res => {
                setState('');
                setFile({});
                toast.success('File Uploaded Successfully', {
                  autoClose: 2000,
                });
              });
          } catch (ex) {
            toast.error(ex, { autoClose: 2000 });
          }
          formDataBus.append('myFileBus', fileBus);
          try {
            await axios
              .post(
                process.env.REACT_APP_PROXY + '/user/uploadBus',
                formDataBus,
                requestOptionsFile,
                {}
              )
              .then(async res => {
                // console.log(res);
                // toast.success('File Uploaded Successfully 2', {
                //   autoClose: 2000,
                // });
              });
          } catch (ex) {
            // toast.error(ex, { autoClose: 2000 });
          }
        }

        fetch(`${process.env.REACT_APP_PROXY}/user/application`, requestOptions)
          .then(res => res.json())
          .then(async data => {
            if (data.success) {
              if (data.message.rowCount < 1) {
                SetLoading(false);
                toast.error(
                  'Limit is over, You can only apply once the previous sponsorship is fulfilled.',
                  { autoClose: 2000 }
                );
                handleClose();
                // toast.error('Please enter correct data!', { autoClose: 2000 });
              } else {
                if (status === 'Draft') {
                  toast.success('Application Saved to Draft', {
                    autoClose: 2000,
                  });
                  props.setOpen(false);
                } else {
                  toast.success('Application Submitted!', { autoClose: 2000 });
                  SetLoading(false);
                  handleClose();
                }
              }
            } else {
              toast.error('Missing or incorrect fields', { autoClose: 2000 });
              SetLoading(false);
              handleClose();
            }
          })
          .catch(function (error) {
            SetLoading(false);
            toast.error('Error in submitting applicaiton', { autoClose: 2000 });
          });
      });
  };

  const styles = {
    contentContainer: {
      background: 'none',
      boxShadow: 'none',
      maxWidth: 800,
      overflow: 'auto',
      // Hide scrollbar
      scrollbarWidth: 'none' /* Firefox */,
      msOverflowStyle: 'none' /* Internet Explorer 10+ */,
      '&::WebkitScrollbar': {
        display: 'none',
      },
    },
  };
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleClickBus = event => {
    hiddenFileInputBus.current.click();
  };

  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    SetLoading(true);
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(function (idToken) {
        const getRequestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            auth: idToken,
          },
        };
        if (props.name !== 'Default') {
          fetch(
            `${process.env.REACT_APP_PROXY}/user/application?need_type=${props.name}`,
            getRequestOptions
          )
            .then(res => res.json())
            .then(d => {
              if (d.success === false) {
              } else {
                setState(prevState => {
                  if (d.message[0]) {
                    const { need_dateline } = d.message[0];
                    const { need_amount } = d.message[0];
                    const { application_no } = d.message[0];
                    return {
                      ...prevState,
                      need_dateline,
                      need_amount,
                      application_no,
                    };
                  }
                  return { ...prevState };
                });
              }
            })
            .catch(err => {
              console.log('An error occurred while fetching data', err);
            });
        }
        fetch(
          `${process.env.REACT_APP_PROXY}/user/university`,
          getRequestOptions
        )
          .then(res => res.json())
          .then(d => {
            if (d.success === false) {
            } else {
              setState(prevState => {
                if (d[0]) {
                  const { account_name } = d[0];
                  const account_no = d[0].account_number;
                  const { bank_name } = d[0];
                  return {
                    ...prevState,
                    account_name,
                    account_no,
                    bank_name,
                  };
                }
                return { ...prevState };
              });
            }
            SetLoading(false);
          })
          .catch(err => {
            console.log('An error occurred while fetching data', err);
          });
      });
  }, [props.name]);

  const tool_tip = 'Limit of ' + props.name + ' request is ' + props.limit;

  const handleCheckboxChange = event => {
    const { name, checked } = event.target;
    if (name === 'checkedA' && checked) {
      setCheck(true);
      setState({ ...state, checkedA: checked, checkedB: false });
    } else if (name === 'checkedB' && checked) {
      setCheck(true);
      setState({ ...state, checkedB: checked, checkedA: false });
    } else {
      setCheck(true);
      setState({ ...state, [name]: checked });
    }
  };

  return (
    //     <div>
    //       <Dialog fullWidth modal="false" open={props.open} onClose={handleClose}>
    //         <DialogTitle id="simple-dialog-title" style={{ color: '#F4113D' }}>
    //           {props.detail}{' '}
    //           <Tooltip
    //             disableFocusListener
    //             disableTouchListener
    //             style={{ color: 'black', fontSize: '18px' }}
    //             title={tool_tip}
    //           >
    //             <IconButton>
    //               <i class="fa fa-info-circle" aria-hidden="true"></i>
    //             </IconButton>
    //           </Tooltip>
    //         </DialogTitle>
    //         {/* <i class="fa fa-info-circle" aria-hidden="true"></i> */}
    //         {/* <Tooltip disableFocusListener disableTouchListener title={props.detail}>
    //           <IconButton>
    //             <i class="fa fa-info-circle" aria-hidden="true"></i>
    //           </IconButton>
    //         </Tooltip> */}

    //         <DialogContent>
    //           <Backdrop className={classes.backdrop} open={loading}>
    //             <CircularProgress color="inherit" />
    //           </Backdrop>
    //           <DialogContentText />
    //           <form noValidate autoComplete="off">
    //             <Typography variant="h6" color="primary">
    //               Input the details of your request below...
    //             </Typography>
    //             <Divider />
    //             <br />
    //             <div>
    //               {props.name === 'Air miles' ? (
    //                 ''
    //               ) : (
    //                 <FundNeeded
    //                   name={props.name}
    //                   state={state}
    //                   setState={setState}
    //                 />
    //               )}
    //               {props.name === 'Air miles' ? (
    //                 <>
    //                   <MilesNeeded
    //                     name={`${props.name} gap`}
    //                     state={state}
    //                     setState={setState}
    //                   />
    //                 </>
    //               ) : (
    //                 ''
    //               )}
    //               <MuiPickersUtilsProvider
    //                 className={classes2.iptext}
    //                 utils={DateFnsUtils}
    //               >
    //                 <KeyboardDatePicker
    //                   className={classes2.iptext}
    //                   id="time-picker"
    //                   value={startDate}
    //                   label={
    //                     props.name !== 'Books' &&
    //                     props.name !== 'Health Insurance' &&
    //                     props.name !== 'Laptop'
    //                       ? 'By Date'
    //                       : props.name + 'By Date'
    //                   }
    //                   disablePast
    //                   inputVariant="outlined"
    //                   onChange={handleDateChange}
    //                   KeyboardButtonProps={{
    //                     'aria-label': 'change time',
    //                   }}
    //                 />
    //                 <br />
    //               </MuiPickersUtilsProvider>
    //             </div>

    //             {(props.name !== 'Books' &&
    //               props.name !== 'Air miles' &&
    //               props.name !== 'Others' &&
    //               props.name !== 'Online Certification Courses' &&
    //               props.name !== 'Laptop') ||
    //             1 ? (
    //               <>
    //                 {props.name === 'Food and Beverage' ? (
    //                   //|| props.name === 'Books'
    //                   ''
    //                 ) : (
    //                   <div>
    //                     <TextField
    //                       variant="outlined"
    //                       id="standard-search"
    //                       type="search"
    //                       label={
    //                         props.name !== 'Online Certification Courses'
    //                           ? props.name === 'Air miles'
    //                             ? 'Indicate why you need the Air miles'
    //                             : // : props.name === 'Books'
    //                             // ? 'Name of book & author'
    //                             props.name === 'Laptop'
    //                             ? 'Give details of latop usage'
    //                             : props.name === 'Tuition' ||
    //                               'Books' ||
    //                               'Pair Of Glasses'
    //                             ? 'Invoice number and other details'
    //                             : 'Notes'
    //                           : 'Details of the certification'
    //                       }
    //                       placeholder={
    //                         props.name !== 'Online Certification Courses' &&
    //                         props.name !== 'Air miles' &&
    //                         props.name !== 'MRT'
    //                           ? 'please share related details'
    //                           : props.name === 'MRT'
    //                           ? 'please share the value of the pass'
    //                           : ''
    //                       }
    //                       onChange={e => setInvoice(e.target.value)}
    //                       multiline
    //                       rows={4}
    //                       rowsMax={10}
    //                       className={classes2.iptext2}
    //                     />
    //                   </div>
    //                 )}
    //                 {props.name === 'Air miles' && (
    //                   <TextField
    //                     variant="outlined"
    //                     id="standard-search"
    //                     type="search"
    //                     label="Frequent Flyer No."
    //                     className={classes2.iptext}
    //                   />
    //                 )}
    //                 {props.name === 'Laptop' ? (
    //                   <div>
    //                     <br />

    //                     <FormControlLabel
    //                       control={<Checkbox name="checkedB" color="primary" />}
    //                       label="Willing to receive refurbished laptop"
    //                     />
    //                   </div>
    //                 ) : (
    //                   ''
    //                 )}
    //                 {value !== 'External' && false && (
    //                   <TextField
    //                     variant="outlined"
    //                     id="standard-search"
    //                     type="search"
    //                     label="Invoice No."
    //                     className={classes2.iptext}
    //                   />
    //                 )}

    //                 {(!show && props.name === 'Food and Beverage') ||
    //                 props.name === 'MRT' ||
    //                 props.name === 'Tuition' ||
    //                 props.name === 'Books' ||
    //                 props.name === 'Pair Of Glasses' ? (
    //                   ' '
    //                 ) : (
    //                   <TextField
    //                     variant="outlined"
    //                     id="standard-search"
    //                     type="search"
    //                     label={
    //                       props.name === 'Books'
    //                         ? 'Book Reference URL'
    //                         : 'Payment Portal URL aaa'
    //                     }
    //                     className={classes2.iptext}
    //                   />
    //                 )}
    //                 {props.name === 'Tuition' || 'Books' || 'Pair Of Glasses' ? (
    //                   <>
    //                     <TextField
    //                       variant="outlined"
    //                       id="standard-search"
    //                       type="search"
    //                       onChange={e => setPayment(e.target.value)}
    //                       label="Payment Portal URL ddd "
    //                       className={classes2.iptextuition}
    //                     />
    //                     <Button
    //                       style={{
    //                         marginLeft: '5%',
    //                       }}
    //                       class="btn btn-outline-primary w-full"
    //                       onClick={handleClick}
    //                     >
    //                       {' '}
    //                       {file.name
    //                         ? file.name.substring(0, 9)
    //                         : 'Upload Invoice Or QR Code'}
    //                       {/* Upload Invoice */}
    //                       <input
    //                         type="file"
    //                         ref={hiddenFileInput}
    //                         onChange={saveFile}
    //                         style={{ display: 'none' }}
    //                       />
    //                     </Button>
    //                     {file.name ? (
    //                       <>
    //                         {' '}
    //                         <input
    //                           type="button"
    //                           style={{
    //                             marginLeft: '5%',
    //                           }}
    //                           value="x"
    //                           onClick={deleteFile}
    //                           class="btn btn-danger"
    //                         />
    //                       </>
    //                     ) : (
    //                       ''
    //                     )}
    //                   </>
    //                 ) : (
    //                   ''
    //                 )}

    //                 {/* ///===============    Provider of Glasess  */}
    //                 {/* {props.name === 'Pair Of Glasses' ? (
    //                   <AutocompleteOverwrite
    //                     id="combo-box-demo"
    //                     options={GlassProviders}
    //                     getOptionLabel={option => option.title}
    //                     className={classes.i2text}
    //                     // style={{
    //                     //   width: '45%',
    //                     //   marginTop: 5,
    //                     //   marginRight: 24,
    //                     //   float: 'right',
    //                     //   color: 'rgba(0, 0, 0, 1) !important',
    //                     //   opacity: '1',
    //                     // }}
    //                     onInputChange={e => {
    //                       if (e && e.target.value !== '') {
    //                       }
    //                     }}
    //                     onChange={(e, v, r) => {}}
    //                     // value={state.platform_provider}
    //                     noOptionsText="Please select"
    //                     renderInput={params => (
    //                       <TextField
    //                         {...params}
    //                         label="Provider"
    //                         variant="outlined"
    //                         // className={classes.input}
    //                         style={{
    //                           marginBottom: '10px',
    //                           width: '45%',
    //                           marginLeft: '10px',
    //                         }}
    //                       />
    //                     )}
    //                   />
    //                 )

    //                 : (
    //                   ''
    //                 )}
    // */}
    //                 {props.name === 'MRT' ? (
    //                   <>
    //                     <AutocompleteOverwrite
    //                       id="combo-box-demo"
    //                       options={MrtProvider}
    //                       getOptionLabel={option => option.title}
    //                       className={classes.i2text}
    //                       // style={{
    //                       //   width: '45%',
    //                       //   marginTop: 5,
    //                       //   marginLeft: '10px',
    //                       //   // marginRight: 24,
    //                       //   // float: 'right',
    //                       //   color: 'rgba(0, 0, 0, 1) !important',
    //                       //   opacity: '1',
    //                       //   '@media (max-width: 767px)': {
    //                       //     width: '100%',
    //                       //   },
    //                       // }}
    //                       onInputChange={e => {
    //                         if (e && e.target.value !== '') {
    //                         }
    //                       }}
    //                       onChange={(e, v, r) => {}}
    //                       // value={state.platform_provider}
    //                       noOptionsText="Please select"
    //                       renderInput={params => (
    //                         <TextField
    //                           {...params}
    //                           label="Provider"
    //                           variant="outlined"
    //                           className={classes.input}
    //                           style={{
    //                             marginBottom: '10px',
    //                             width: '45%',
    //                             marginLeft: '10px',
    //                           }}
    //                         />
    //                       )}
    //                     />
    //                     <TextField
    //                       variant="outlined"
    //                       id="standard-search"
    //                       type="search"
    //                       label="Payment Portal URL"
    //                       value="Pay Now"
    //                       className={classes2.iptextmrt}
    //                     />
    //                   </>
    //                 ) : (
    //                   ''
    //                 )}

    //                 {props.name === 'Food and Beverage' ? (
    //                   <AutocompleteOverwrite
    //                     id="combo-box-demo"
    //                     options={MealProviders}
    //                     getOptionLabel={option => option.title}
    //                     className={classes.coffeetext}
    //                     style={{
    //                       width: '45%',
    //                       marginTop: 5,
    //                       marginLeft: '10px',
    //                       color: 'rgba(0, 0, 0, 1) !important',
    //                       opacity: '1',
    //                       '@media (max-width: 767px)': {
    //                         width: '100%',
    //                       },
    //                     }}
    //                     onInputChange={(e, v, r) => {
    //                       setFoodOption(v);
    //                       if (e && e.target.value !== '') {
    //                       }
    //                     }}
    //                     // onChange={(e, v, r) => {
    //                     //   console.log(v.title);
    //                     //   if (v.title === 'University Meal Plan') {
    //                     //     isShow(true);
    //                     //   } else {
    //                     //     isShow(false);
    //                     //   }
    //                     // }}
    //                     // value={state.platform_provider}
    //                     noOptionsText="Please select"
    //                     renderInput={params => (
    //                       <TextField
    //                         {...params}
    //                         label="Options"
    //                         variant="outlined"
    //                         // className={classes.input}
    //                         style={{ marginBottom: '10px' }}
    //                       />
    //                     )}
    //                   />
    //                 ) : (
    //                   ''
    //                 )}

    //                 {props.name === 'Housing' ||
    //                 props.name === 'Tuition' ||
    //                 props.name === 'Books' ||
    //                 props.name === 'Food and Beverage' ||
    //                 props.name === 'Pair Of Glasses' ||
    //                 props.name === 'MRT' ? (
    //                   ''
    //                 ) : (
    //                   <AutocompleteOverwrite
    //                     id="combo-box-demo"
    //                     options={providers}
    //                     getOptionLabel={option => option.name}
    //                     className={classes2.i2text}
    //                     onInputChange={e => {
    //                       if (e && e.target.value !== '') {
    //                       }
    //                     }}
    //                     onChange={(e, v, r) => {}}
    //                     // value={state.platform_provider}
    //                     noOptionsText="Please select"
    //                     renderInput={params => (
    //                       <TextField
    //                         {...params}
    //                         label="Provider"
    //                         variant="outlined"
    //                         // className={classes.input}
    //                       />
    //                     )}
    //                   />
    //                 )}
    //               </>
    //             ) : (
    //               ''
    //             )}
    //             {props.name === 'Tuition' ? (
    //               <div>
    //                 <br />
    //                 <Typography variant="h6" color="primary">
    //                   Scholarship Information
    //                 </Typography>
    //                 <Divider />
    //                 <br />

    //                 <FormControlLabel
    //                   control={
    //                     <Checkbox
    //                       checked={state.checkedB}
    //                       onChange={handleChange}
    //                       name="checkedB"
    //                       color="primary"
    //                     />
    //                   }
    //                   label="Are you receiving any bursary or scholarships this semester?"
    //                 />
    //               </div>
    //             ) : (
    //               ''
    //             )}
    //             {value === 'External' && props.name === 'Health Insurance' ? (
    //               <Autocomplete
    //                 id="combo-box-demo"
    //                 options={InsuranceProviders}
    //                 getOptionLabel={option => option.title}
    //                 style={{ width: 300, marginTop: 20 }}
    //                 renderInput={params => (
    //                   <TextField {...params} label="Providers" variant="outlined" />
    //                 )}
    //               />
    //             ) : (
    //               ''
    //             )}
    //             {state.checkedB ? (
    //               <div>
    //                 <TextField
    //                   variant="outlined"
    //                   id="standard-search"
    //                   type="search"
    //                   label="Bursaries/Scholarships received"
    //                   multiline
    //                   rows={4}
    //                   rowsMax={10}
    //                   style={{ marginTop: 10, width: 285 }}
    //                 />
    //                 <Button
    //                   style={{
    //                     marginLeft: '300px',
    //                     marginTop: '-150px',
    //                     maxWidth: '200px',
    //                   }}
    //                   class="btn btn-outline-primary"
    //                   onClick={handleClickBus}
    //                 >
    //                   {' '}
    //                   {fileBus.name
    //                     ? fileBus.name.substring(0, 9)
    //                     : 'Upload Grant Information'}
    //                   <input
    //                     type="file"
    //                     ref={hiddenFileInputBus}
    //                     onChange={saveFileBus}
    //                     style={{ display: 'none' }}
    //                   />
    //                 </Button>
    //                 {fileBus.name ? (
    //                   <>
    //                     {' '}
    //                     <input
    //                       type="button"
    //                       style={{ marginTop: '-150px' }}
    //                       value="x"
    //                       onClick={deleteFileBus}
    //                       class="btn btn-danger"
    //                     />
    //                   </>
    //                 ) : (
    //                   ''
    //                 )}
    //               </div>
    //             ) : (
    //               ''
    //             )}
    //             {props.name !== 'Books' &&
    //             props.name !== 'Air miles' &&
    //             props.name !== 'Others' &&
    //             props.name !== 'Online Certification Courses' &&
    //             props.name !== 'Laptop' &&
    //             props.name !== 'Books' &&
    //             props.name === 'Laptop' ? (
    //               <div />
    //             ) : props.name === 'Online Certification Courses' ? (
    //               <TextField
    //                 variant="outlined"
    //                 className={(classes.input, classes2.iptext)}
    //                 id="standard-search"
    //                 type="search"
    //                 label={
    //                   props.name === 'Books' ? 'Amazon Book URL' : 'Link to payment'
    //                 }
    //               />
    //             ) : (
    //               ''
    //             )}
    //             <GiveBackDialog
    //               open={giveBackOpen}
    //               setOpen={setGiveBackOpen}
    //               volunteer_hours={volunteer_hours}
    //               requests_willing_to_donate={requests_willing_to_donate}
    //               mini_project_hours={mini_project_hours}
    //               setVolunteer_hours={setVolunteer_hours}
    //               setRequests_willing_to_donate={setRequests_willing_to_donate}
    //               setMini_project_hours={setMini_project_hours}
    //               state={state}
    //               setCheck={setCheck}
    //             />
    //             {/* <div style={{ width: '100%', paddingTop: 50 }}>
    //               <div>
    //                 <div>
    //                   <Typography variant="h6" color="primary">
    //                     Reach Out
    //                   </Typography>
    //                 </div>
    //               </div>
    //               <Divider />
    //             </div> */}
    //             {/* <ShareWith /> */}
    //           </form>
    //         </DialogContent>
    //         <DialogActions>
    //           <FlatButton
    //             style={{
    //               marginRight: '10px',
    //               border: '1px solid #232323',
    //             }}
    //             onClick={e => {
    //               props.setOpen(false);
    //               e.preventDefault();
    //               state.need_amount = null;
    //               state.checkedB = false;
    //               setFileBus({});
    //               setFile({});
    //             }}
    //           >
    //             Cancel
    //           </FlatButton>
    //           {/* <FlatButton
    //             style={{
    //               backgroundColor: '#232323',
    //               marginRight: '10px',
    //               color: '#f2f2f2',
    //               '&:hover': {
    //                 background: '#232323',
    //               },
    //             }}
    //             onClick={() => {
    //               submitApp('Draft');
    //             }}
    //           >
    //             Save Draft
    //           </FlatButton> */}
    //           <FlatButton
    //             style={{
    //               backgroundColor: '#232323',
    //               marginRight: '20px',
    //               color: '#f2f2f2',
    //               '&:hover': {
    //                 background: '#232323',
    //               },
    //             }}
    //             onClick={e => {
    //               submitApp('Active');
    //             }}
    //           >
    //             Submit
    //           </FlatButton>
    //         </DialogActions>
    //       </Dialog>
    //     </div>

    <>
      <Dialog
        modal="false"
        open={props.open}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        PaperProps={{
          style: styles.contentContainer,
        }}
      >
        {Loading ? (
          <Spinner />
        ) : isEligible && !message ? (
          <div className="admin-modal preferences-modal">
            <div className="modal-head text-center">
              <h4>Input the details of your request </h4>
            </div>
            <div className="form-bottom">
              <form>
                {/* Fund Amount and Date */}
                <div className="from-two-group">
                  <div className="form-group">
                    {createTooltip(
                      'amount',
                      'left',
                      <input
                        type="text"
                        placeholder="Amount"
                        onChange={e =>
                          setState({ ...state, need_amount: e.target.value })
                        }
                      />
                    )}
                  </div>

                  <MuiPickersUtilsProvider
                    className={classes2.iptext}
                    utils={DateFnsUtils}
                  >
                    {createTooltip(
                      'date',
                      'bottom',
                      <KeyboardDatePicker
                        className={classes2.root}
                        id="time-picker"
                        value={startDate}
                        placeholder="Pick a date"
                        disablePast
                        inputVariant="outlined"
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                      />
                    )}
                  </MuiPickersUtilsProvider>
                </div>
                {/* Invoice Details */}
                <div className="form-group mb-0">
                  {createTooltip(
                    'invoiceNumber',
                    'left',
                    <textarea
                      placeholder="Invoice number and other details"
                      onChange={e => setInvoice(e.target.value)}
                    ></textarea>
                  )}
                </div>
                {/* Payment Portal URL and Invoice Upload */}
                {/* <div className="from-two-group">
                  <div className="form-group">
                    {createTooltip(
                      'paymentLink',
                      'left',
                      <input
                        type="text"
                        placeholder="Payment Portal URL"
                        onChange={e => setPayment(e.target.value)}
                      />
                    )}
                  </div>
                  {createTooltip(
                    'uploadQR',
                    'right',
                    <div className="form-group">
                      <input
                        className="input"
                        name="file"
                        id="file"
                        type="file"
                        accept="image/jpeg, image/png"
                        onChange={saveFile}
                        style={{ display: 'none' }}
                      />
                      <label htmlFor="file" className="custom-file-upload">
                        {' '}
                        {file.name
                          ? file.name.substring(0, 9)
                          : 'Upload Invoice/QR Code'}
                      </label>
                      <style jsx>{`
                        .custom-file-upload {
                          display: inline-block;
                          padding: 14px 12px;
                          cursor: pointer;
                          background-color: #f5f5f5;
                          border-radius: 4px;
                        }
                      `}</style>
                    </div>
                      
                  )}
                </div> */}

                <div className="from-two-group">
                  <div className="form-group">
                    {/* Payment Portal URL Input */}
                    {createTooltip(
                      'paymentLink',
                      'left',
                      <input
                        type="text"
                        placeholder="Payment Portal URL"
                        onChange={e => setPayment(e.target.value)}
                      />
                    )}
                  </div>

                  {/* QR Code/Invoice Upload Input */}
                  {createTooltip(
                    'uploadQR',
                    'right',
                    <div className="form-group">
                      <input
                        className="input"
                        name="file"
                        id="file"
                        type="file"
                        onChange={saveFile}
                        style={{ display: 'none' }}
                        accept="image/jpg, image/jpeg, image/png"
                      />
                      <label htmlFor="file" className="custom-file-upload">
                        {file?.name ? file.name : 'Upload Invoice/QR Code'}
                      </label>

                      {/* Show cross icon if a file is uploaded */}
                      {file?.name && (
                        <i
                          class="fa-solid fa-xmark"
                          onClick={deleteFile}
                          style={{
                            color: 'red',
                            cursor: 'pointer',
                            fontSize: '20px',
                            marginLeft: '10px',
                          }}
                        ></i>
                      )}

                      {/* Styling for the upload button */}
                      <style jsx>{`
                        .custom-file-upload {
                          display: inline-block;
                          padding: 14px 12px;
                          cursor: pointer;
                          background-color: #f5f5f5;
                          border-radius: 4px;
                        }
                        .form-group {
                          display: flex;
                          align-items: center;
                        }
                        .remove-icon {
                          margin-left: 10px;
                        }
                      `}</style>
                    </div>
                  )}
                </div>
                {/* I Pledge To */}
                <div className="modal-head my-3">
                  <h5>I Pledge To</h5>
                  <hr />
                </div>
                <div className="from-two-group m0">
                  {createTooltip(
                    'volunteer',
                    'left',
                    <div className="form-group input-group mt-10">
                      <label className="checkbox-box">
                        Volunteer
                        <input
                          type="checkbox"
                          name="checkedA"
                          checked={state.checkedA}
                          onChange={handleCheckboxChange}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  )}
                  {createTooltip(
                    'sponsorStudent',
                    'right',
                    <div className="form-group input-group mt-10">
                      <label className="checkbox-box">
                        Sponsor a student in future
                        <input
                          type="checkbox"
                          name="checkedB"
                          checked={state.checkedB}
                          onChange={handleCheckboxChange}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  )}
                </div>
                {/* Skills and Hours (Shown when Volunteer is checked) */}
                {state.checkedA && (
                  <div>
                    <div className="modal-head my-3">
                      <h5>Skills</h5>
                      <hr />
                    </div>
                    <div className="form-group custom-select-wrapper">
                      <select
                        className="custom-select"
                        onChange={handleSkillChange}
                      >
                        <option value="Microsoft office">
                          Microsoft office
                        </option>
                        <option value="Working in a old age home">
                          Working in a old age home
                        </option>
                        <option value="Working in an event">
                          Working in an event
                        </option>
                        <option value="Coding">Coding</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Number of hours</label>
                      <input
                        type="number"
                        name="hours"
                        value={state.hours}
                        onChange={e => setVolunteer_hours(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                {/* Number of Students (Shown when Sponsor a student is checked) */}
                {state.checkedB && (
                  <div className="form-group">
                    <label>Number of students (1 to 10)</label>
                    <input
                      type="number"
                      name="students"
                      value={state.students}
                      min="1"
                      max="10"
                      onChange={e => Setfuture_sponsor_count(e.target.value)}
                    />
                  </div>
                )}
                {/* Submit and Cancel Buttons */}
                <div className="form-group btn-listing">
                  <button
                    className="btn btn-border"
                    onClick={e => {
                      handleClose();
                      e.preventDefault();
                    }}
                  >
                    Cancel
                  </button>
                  <div
                    className="btn btn-fill"
                    onClick={e => {
                      submitApp('Active');
                    }}
                  >
                    Submit
                  </div>
                </div>
              </form>
            </div>
          </div>
        ) : message ? (
          <AlertMessage
            setOpen={props.setOpen}
            SetIsEligible={SetIsEligible}
            message={message}
          />
        ) : (
          ''
        )}
      </Dialog>
    </>
  );
}

const cards = [
  {
    no: 7,
    calName: 'tuition',
    header: 'Tuition',
    detail: ' Is there a small gap towards tuition ?',
    limit: '1',
    img: school,
    isShow: false,
  },
  // {
  //   no: 6,
  //   calName: 'food_voucher',
  //   header: 'Food and Beverage',
  //   detail: 'For Sugar, Spice and Everything Nice! ',
  //   limit: '10',
  //   img: mealPlan,
  //   isShow: false,
  // },
  {
    no: 12,
    calName: 'mrt',
    header: 'MRT',
    detail: 'Need to get to college! apply here.',
    limit: '3',
    img: mrt,
    isShow: false,
  },
  {
    no: 1,
    calName: 'books',
    header: 'Books',
    detail: 'If you are a book worm raise  a request here.',
    limit: '1',
    img: books,
    isShow: false,
  },
  {
    no: 13,
    calName: 'pair_of_glasses',
    header: 'Pair Of Glasses',
    detail: 'Raise a request.',
    limit: '1',
    img: glasses,
    isShow: false,
  },
  {
    no: 2,
    calName: 'laptop',
    header: 'Laptop',
    detail: 'If you need support for buying a laptop, apply here.',
    limit: '1',
    img: laptop,
    isShow: false,
  },
  {
    no: 3,
    calName: 'certification',
    header: 'Online Certification Courses',
    detail:
      'If you need support in paying for online certification courses, apply here',
    limit: '3',
    img: certification,
    isShow: false,
  },
  {
    no: 4,
    calName: 'insurance',
    header: 'Health Insurance',
    detail: 'If you need support for buying health insurance, apply here.',
    limit: '1',
    img: insurance,
    isShow: false,
  },
  {
    no: 5,
    calName: 'housing',
    header: 'Housing',
    detail: 'If you need support to pay for accomodation, apply here',
    limit: '1',
    img: housing,
    isShow: false,
  },
  {
    no: 8,
    calName: 'miles',
    header: 'Air miles',
    detail:
      'If you need support in travelling home from overseas university, apply here.',
    limit: '1',
    img: airmiles,
    isShow: false,
  },
  {
    no: 9,
    calName: 'accessibility',
    header: 'Accessibility Needs',
    detail: 'Need support in other areas for education, apply here.',
    limit: '10',
    img: accessibility,
    isShow: false,
  },
  {
    no: 10,
    calName: 'counseling',
    header: 'Counseling Services',
    detail: 'If you need support in other areas for education, apply here.',
    limit: '10',
    img: counsel,
    isShow: false,
  },
  {
    no: 11,
    calName: 'others',
    header: 'Others',
    detail: 'If you need support in other areas, apply here.',
    limit: '3',
    img: others,
    isShow: false,
  },
];

const providers = [
  { name: 'E-Commerce platform one' },
  { name: 'E-Commerce platform two' },
];
const requestTypes = [{ name: 'Type One' }, { name: 'Type Two' }];

// Application landing page with all the different need type cards for students
export default function Application() {
  const classes = useStyles();
  const [openName, setOpenName] = React.useState('Default');
  const [openHeader, setOpenHeader] = React.useState('');
  const [openLimit, setLimit] = React.useState('');
  const [newOpen, setNewOpen] = React.useState(false);
  const [_cards, setCards] = React.useState([]);
  const dispatch = useDispatch();

  firebase
    .auth()
    .currentUser.getIdToken()
    .then(function (idToken) {
      const getRequestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          auth: idToken,
        },
      };
      fetch(
        `${process.env.REACT_APP_PROXY}/user/instanceconfig`,
        getRequestOptions
      )
        .then(res => res.json())
        .then(data => {
          if (data.success) {
            cards.forEach(function (card, index) {
              if (card.calName === 'books' && data.data[0].books === 'Yes') {
                card.isShow = true;
              } else if (
                card.calName === 'laptop' &&
                data.data[0].laptop === 'Yes'
              ) {
                card.isShow = false;
              } else if (
                card.calName === 'food_voucher' &&
                data.data[0].food_voucher === 'Yes'
              ) {
                card.isShow = true;
              } else if (
                card.calName === 'certification' &&
                data.data[0].certification === 'Yes'
              ) {
                card.isShow = false;
              } else if (
                card.calName === 'insurance' &&
                data.data[0].insurance === 'Yes'
              ) {
                card.isShow = false;
              } else if (
                card.calName === 'housing' &&
                data.data[0].housing === 'Yes'
              ) {
                card.isShow = false;
              } else if (
                card.calName === 'tuition' &&
                data.data[0].tuition === 'Yes'
              ) {
                card.isShow = true;
              } else if (
                card.calName === 'miles' &&
                data.data[0].miles === 'Yes'
              ) {
                card.isShow = false;
              } else if (
                card.calName === 'accessibility' &&
                data.data[0].accessibility === 'Yes'
              ) {
                card.isShow = false;
              } else if (
                card.calName === 'counseling' &&
                data.data[0].counseling === 'Yes'
              ) {
                card.isShow = false;
              } else if (
                card.calName === 'others' &&
                data.data[0].others === 'Yes'
              ) {
                card.isShow = false;
              } else if (card.calName === 'mrt' && data.data[0].mrt === 'Yes') {
                card.isShow = false;
              } else if (
                card.calName === 'pair_of_glasses' &&
                data.data[0].pair_of_glasses === 'Yes'
              ) {
                card.isShow = true;
              } else {
                card.isShow = false;
              }
            }, cards);
            setCards(cards);
          }
        })
        .catch(function (error) {
          console.log('An error occurred while fetching data', error);
        });
    });
  return (
    <>
      {/* <CssBaseline /> */}
      <div className="row">
        <div className="col-md-12">
          <div className="submit-pledge">
            <ul>
              {_cards.map(card =>
                card.isShow ? (
                  <li key={card.no}>
                    <div className="submit-box text-center">
                      <div className="image-box">
                        <img src={card.img} alt="icon1" />
                      </div>
                      <div className="content-box">
                        <h2>{card.header}</h2>
                        <p> {card.detail}</p>
                        <a
                          onClick={() => {
                            setOpenName(card.header);
                            setLimit(card.limit);
                            setNewOpen(true);
                            setOpenHeader(card.detail);
                            dispatch(SetIsEligible(false, ''));
                          }}
                          className="apply-now"
                          style={{ color: 'white', fontWeight: 'bold' }}
                        >
                          Apply
                        </a>
                      </div>
                    </div>
                  </li>
                ) : (
                  ''
                )
              )}
            </ul>
          </div>
        </div>
      </div>

      {/* <Container maxWidth="lg">
          <Grid container spacing={4}>
            {_cards.map(card =>
              card.isShow ? (
                <Grid item key={card.no} xs={12} sm={6} md={4} lg={4}>
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={card.img}
                      title="Image title"
                      component="img"
                    />
                    <CardContent
                      className={classes.cardContent}
                      style={{ marginTop: '-35px' }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        className={classes.cardContenth1}
                        component="h2"
                      >
                        {card.header === 'Pair Of Glasses'
                          ? 'Glasses'
                          : card.header}
                      </Typography>
                      <Typography className={classes.cardContentDetail}>
                        {card.detail}
                      </Typography>
                      <CardActions>
                        <Button
                          style={
                            card.header === 'MRT' ||
                            // card.header === 'Pair Of Glasses' ||
                            card.header === 'Others'
                              ? {
                                  backgroundColor: '#808080',
                                  color: '#f2f2f2',
                                  marginTop: '-30px',
                                  marginBottom: 8,
                                  '&:hover': {
                                    background: '#232323',
                                  },
                                }
                              : {
                                  backgroundColor: '#232323',
                                  color: '#f2f2f2',
                                  marginTop: '-30px',
                                  marginBottom: 8,
                                  '&:hover': {
                                    background: '#232323',
                                  },
                                }
                          }
                          disabled={
                            card.header === 'MRT' ||
                            // card.header === 'Pair Of Glasses' ||
                            card.header === 'Others'
                              ? true
                              : false
                          }
                          className={card.headLoginButton}
                          onClick={() => {
                            setOpenName(card.header);
                            setLimit(card.limit);
                            setNewOpen(true);
                            setOpenHeader(card.detail);
                          }}
                        >
                          Apply
                        </Button>
                      </CardActions>
                    </CardContent>
                  </Card>
                </Grid>
              ) : (
                ''
              )
            )}
            <br />
          </Grid>
        </Container> */}
      <NewApp
        open={newOpen}
        setOpen={setNewOpen}
        name={openName}
        limit={openLimit}
        detail={openHeader}
      />
    </>
  );
}
