import React from 'react';
import './style.css';

const Switchs = ({ checkedStudent, checkedSponsor, checkedOrganisation, handleChange }) => {
  return (
    <div>
      <h2>How sponsor a student works</h2>
      <div className="top-tabs">
        <ul>
          <li>
            <label className="switch">
              <input
                type="checkbox"
                name="checkedStudent"
                checked={checkedStudent}
                onChange={handleChange}
                id="label-01"
              />
              <span className="slider round" htmlFor="label-01" />
              <span className="text-box">For a Student</span>
            </label>
          </li>
          <li>
            <label className="switch">
              <input
                type="checkbox"
                name="checkedSponsor"
                checked={checkedSponsor}
                onChange={handleChange}
                id="label-02"
              />
              <span className="slider round" htmlFor="label-02" />
              <span className="text-box">For a Sponsor</span>
            </label>
          </li>
          <li>
            <label className="switch">
              <input
                type="checkbox"
                name="checkedOrganisation"
                checked={checkedOrganisation}
                onChange={handleChange}
                id="label-03"
              />
              <span className="slider round" htmlFor="label-03" />
              <span className="text-box">For a Organisation</span>
            </label>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Switchs;
