// Functionality of this code : This code has the admin login process
// Input : None
// Output : Admin Login
// Written by: CIS

import React from 'react';
import * as firebase from 'firebase';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import poster2 from '../assets/poster-new.jpg';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import User from '@material-ui/icons/Person';

toast.configure();

// Defining the page style sheet
const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    padding: '50px 20px 50px 50px',
    '@media (max-width: 991px)': {
      padding: '30px 0px 30px 30px',
    },
    '@media (max-width: 767px)': {
      padding: '0px',
    },
  },

  cardDetails: {
    flex: 1,
  },

  cardMedia: {
    width: 800,
  },

  image: {
    backgroundImage: `url(${poster2})`,
    height: '400px',
    marginTop: '60px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'left',
  },

  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 767px)': {
      margin: '30px 15px',
    },
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 1),
  },

  button: {
    margin: theme.spacing(1),
  },

  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },

  customcontainer: {
    maxWidth: '1365px',
  },

  container: {
    maxWidth: '1365px',
    marginTop: '90px',
    '@media (max-width: 767px)': {
      marginTop: '60px',
    },
  },

  spacing: {
    padding: '95px 0',
    '@media (max-width: 900px)': {
      padding: '50px 0',
    },
    '@media (max-width: 767px)': {
      padding: '30px 0',
    },
  },

  spacing1: {
    padding: '95px 0 0px',
    '@media (max-width: 900px)': {
      padding: '50px 0 0px',
    },
    '@media (max-width: 767px)': {
      padding: '30px 0 0px',
    },
  },
}));

//Login/Register/SSO Page
export default function AdminLogin(props) {
  const classes = useStyles();

  // initiliz state
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [role, setRole] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordShown, setPasswordShown] = React.useState(false);
  const [error, setError] = React.useState('');

  const navigate = useNavigate();

  //show hide password
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  //switch login and registration tab
  const LoginOrRegister = () => {
    onLogIn();
    setRole('admin');
  };

  const onLogIn = () => {
    //validation
    if (!email) {
      setError('Please enter an email address.');
      return;
    }
    if (!password) {
      setError('Please enter a password.');
      return;
    }

    if (password.length < 4) {
      setError('password (min length 5)');
      return;
    }
    if (email == 'admin@sammantrana.com') {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(user => {
          const idToken = user.user._lat;
          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              auth: idToken,
            },
            body: JSON.stringify({
              role: 'admin',
              email: email,
              password: password,
            }),
          };
          if (!user.user.emailVerified) {
          }

          //fetch data for role
          fetch(process.env.REACT_APP_PROXY + '/admin/auth', requestOptions)
            .then(res => res.json())
            .then(data => {
              if (data.success === false) {
                toast.error(data.message, { autoClose: 1000 });
              } else {
                let resp = [];
                let tempArr = [];
                resp.push(data);
                resp.forEach(e => {
                  tempArr.push(e.data[0]);
                });
                if (
                  tempArr[0].email == email &&
                  tempArr[0].password == password
                ) {
                  toast.success('Admin Login Success', { autoClose: 1000 });
                  props.setPage('Dashboard');
                  navigate('/Dashboard');
                } else {
                  toast.error('Login Failed', { autoClose: 1000 });
                }
              }
            });
        })
        .catch(function (error) {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage =
            'No user found corresponding to this identifier. Please signup for start.';
          // [START_EXCLUDE]
          if (errorCode === 'auth/wrong-password') {
            //setError('Wrong password.');
            toast.error('Wrong password', { autoClose: 2000 });
          } else {
            setError(errorMessage);
            toast.error('Wrong email or password', { autoClose: 2000 });
          }
          // [END_EXCLUDE]
        });
    } else {
      toast.error('No user found corresponding to this identifier', {
        autoClose: 2000,
      });
    }
  };

  return (
    <>
      <CssBaseline />
      <Container className={classes.container} maxWidth="lg">
        <Grid item xs={12} md={12}>
          <Card className={classes.card}>
            <Grid container component="main" className={classes.root}>
              <Grid item xs={false} sm={6} md={7} className={classes.image} />
              <Grid item xs={12} sm={6} md={5} elevation={6} square>
                <div className={classes.paper}>
                  {/* <User/> */}
                  <h4 style={{ color: '#2998D1' }}>Admin Login</h4>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    value={email}
                    autoComplete="off"
                    autoFocus
                    onChange={e => {
                      e.preventDefault();
                      e.persist();
                      setEmail(e.target.value);
                    }}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    value={password}
                    name="password"
                    label="Password"
                    type={passwordShown ? 'text' : 'password'}
                    id="password"
                    autoComplete="off"
                    onChange={e => {
                      e.preventDefault();
                      e.persist();
                      setPassword(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePasswordVisiblity}
                          >
                            {passwordShown ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div style={{ width: '100%', padding: '8px 4px 4px 4px' }}>
                    <label style={{ color: 'red' }}>{error}</label>
                  </div>
                  <Grid
                    container
                    component="main"
                    style={{ justifyContent: 'space-between' }}
                    spacing={4}
                  >
                    <Grid item xs={12} sm={12} md={6} elevation={4}>
                      <Button
                        style={{
                          width: '150px',
                          margin: 0,
                          backgroundColor: '#232323',
                          color: '#ffffff',
                          padding: '8px',
                          border: '1px solid #232323',
                        }}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={LoginOrRegister}
                      >
                        Sign In
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>{' '}
          </Card>
        </Grid>

        <br />
        <br />
      </Container>
    </>
  );
}
