// Functionality of this code : This code has the Unique detail's table on admin pannel
// Input : None
// Output : Unique details admin page
// Written by: CIS

import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import tableIcons from './MaterialTableIcons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Delete } from '@material-ui/icons';
import axios from 'axios';
import { Button } from '@material-ui/core';

toast.configure();

export default function Uniquegift() {
  const [error, setError] = React.useState('');
  const [codes, setVoucher] = React.useState([]);
  const [data, setData] = React.useState([]);

  const columns = [
    {
      title: 'Product Id',
      field: 'id',
    },
    {
      title: 'Product Name',
      field: 'product_name',
    },
    {
      title: 'Amount',
      field: 'amount',
      align: 'center',
    },
    {
      title: 'Category',
      field: 'category',
    },
    {
      title: 'Active Date ',
      field: 'active_date',
      type: 'date',
      dateSetting: {
        format: 'dd/MM/yyyy',
      },
    },
    {
      title: 'Expiry Date',
      field: 'expiry_date',
      type: 'date',
      dateSetting: {
        format: 'dd/MM/yyyy',
      },
    },
    // {
    //   title: 'Sequence Number',
    //   field: 'sequence_number',
    // },
    // {
    //   title: 'Voucher GUID',
    //   field: 'voucher_guid',
    // },
    {
      title: 'Short URL',
      field: 'short_url',
    },
    {
      title: 'Is Purchased',
      field: 'is_used',
      align: 'center',
      lookup: { 0: 0, 1: 1 },
    },
    // {
    //   title: 'Auth Code',
    //   field: 'auth_code',
    // },
    // {
    //   title: 'SMS Content',
    //   field: 'sms_content',
    // },
    // {
    //   title: 'Client Order Number',
    //   field: 'client_order_number',
    // },
    // {
    //   title: 'Check Code',
    //   field: 'check_code',
    // },
    // {
    //   title: 'Pin Code',
    //   field: 'pin_code',
    // },
  ];
  useEffect(() => {
    getVouchers();
  }, []);

  const getVouchers = () => {
    fetch(process.env.REACT_APP_PROXY + '/admin/uniqueGift')
      .then(res => res.json())
      .then(data => {
        let resp = data.data;
        if (data.success === false) {
          setError(data.message);
        } else {
          const unique = [...new Set(resp)];
          setVoucher(unique);
        }
      });
  };

  const addVouchers = value => {
    fetch(process.env.REACT_APP_PROXY + '/admin/addGifts', value)
      .then(res => res.json())
      .then(data => {
        if (data.success === true) {
          toast.success(data.message, { autoClose: 1000 });
        } else {
          toast.error(data.message, { autoClose: 1000 });
        }
      });
  };

  const editVouchers = value => {
    fetch(process.env.REACT_APP_PROXY + '/admin/updategifts', value)
      .then(res => res.json())
      .then(data => {
        if (data.success === true) {
          toast.success(data.message, { autoClose: 1000 });
        } else {
          toast.error(data.message, { autoClose: 1000 });
        }
      });
  };

  const deleteVouchers = value => {
    fetch(process.env.REACT_APP_PROXY + '/admin/deletegifts', value)
      .then(res => res.json())
      .then(data => {
        if (data.success === true) {
          toast.success(data.message, { autoClose: 1000 });
        } else {
          toast.error(data.message, { autoClose: 1000 });
        }
      });
  };

  const deleteVouchersAll = value => {
    fetch(process.env.REACT_APP_PROXY + '/admin/deletegiftsAll', value)
      .then(res => res.json())
      .then(data => {
        if (data.success === true) {
          toast.success(data.message, { autoClose: 1000 });
        } else {
          toast.error(data.message, { autoClose: 1000 });
        }
      });
  };

  const [file, setFile] = useState({});

  const saveFile = e => {
    setFile(e.target.files[0]);
  };

  const uploadFile = async e => {
    const formData = new FormData();
    formData.append('dataFile', file);
    try {
      axios
        .post(process.env.REACT_APP_PROXY + '/admin/upload', formData, {})
        .then(async res => {
          toast.success('File Uploaded Successfully', { autoClose: 2000 });
          setTimeout(() => {
            getVouchers();
          }, 3000);
        });
    } catch (ex) {
      toast.error(ex, { autoClose: 2000 });
    }
  };
  const handleDeleteRows = (event, rowData) => {
    let _data = [...data];
    rowData.forEach(rd => {
      _data = _data.filter(t => t.tableData.id !== rd.tableData.id);
    });
    setData(_data);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        rowData,
      }),
    };
    deleteVouchersAll(requestOptions);
    setTimeout(() => {
      const dataDelete = [...data];
      // const index = rowData.tableData.voucher_number;
      // dataDelete.splice(index, 1);
      setVoucher([...dataDelete]);
    }, 1000);
  };

  return (
    <div
      className="content-wrapper"
      style={{ marginTop: '6%', overflow: 'hidden' }}
    >
      <MaterialTable
        title={<h3 style={{ color: '#F4113D' }}>Unique Gifts</h3>}
        icons={tableIcons}
        columns={columns}
        data={codes}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  newData,
                }),
              };
              addVouchers(requestOptions);
              setVoucher([...codes, newData]);
              resolve();
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  newData,
                }),
              };
              editVouchers(requestOptions);
              setTimeout(() => {
                const dataUpdate = [...codes];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                setVoucher([...dataUpdate]);
                resolve();
              }, 1000);
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  oldData,
                }),
              };
              deleteVouchers(requestOptions);
              setTimeout(() => {
                const dataDelete = [...codes];
                const index = oldData.tableData.voucher_number;
                dataDelete.splice(index, 1);
                setVoucher([...dataDelete]);
                resolve();
              }, 1000);
              resolve();
            }),
        }}
        options={{
          sorting: true,
          exportButton: true,
          selection: true,
          actionsColumnIndex: -1,
          headerStyle: { color: '#2998D1' },
        }}
        actions={[
          {
            icon: () => <Delete />,
            tooltip: 'Delete All Rows',
            onClick: handleDeleteRows,
          },
        ]}
      />
      <Button>
        Upload Excel File : &nbsp; <input type="file" onChange={saveFile} />
      </Button>
      <Button
        color="secondary"
        variant="contained"
        component="span"
        onClick={uploadFile}
      >
        Upload
      </Button>
    </div>
  );
}
