// Functionality of this code : This is where the user to lead to fill preferences on first time logging in
// Input : None
// Output : Profile/Preference Settings
// Written by: Initial team VK, KR

import React, { useRef } from 'react';
import {} from '@material-ui/pickers';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FlatButton from '@material-ui/core/Button';
import StudentStatusTable from './StudentStatusTable';

/* this is the settings page pop out when profile/pref is clicked */
export default function PopCheckStatus(props) {
  const childRef = useRef();
  const role = props.role;
  const handleClose = () => {
    props.setOpen(false);
  };
  return (
    <div>
      <Dialog
        maxWidth="xl"
        open={props.open}
        onClose={handleClose}
        // color="primary"
        PaperProps={{
          style: {
            boxShadow: 'none',
            background: 'none',
            padding: '0',
          },
        }}
      >
        {/* <DialogTitle styles={{ color: '2998d1' }}>REQUEST STATUS</DialogTitle> */}
        {/* <DialogContent> */}
        <StudentStatusTable />
        {/* </DialogContent> */}
        {/* <DialogActions>
          <FlatButton
            color="primary"
            onClick={() => {
              props.setOpen(false);
            }}
          >
            {' '}
            Cancel
          </FlatButton>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
