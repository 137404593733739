export const tooltipMessages = {
  amount:
    'Hey there! Please enter the amount of funding you need for your educational needs.',
  date: 'By what date do you need the sponsorship? Please select a date that works best for you.',
  invoiceNumber:
    'Got an invoice number? Enter it here to keep things organized.',
  uploadQR:
    'If you have a QR code related to your request or payment, you can upload it here.',
  paymentLink:
    'Add the URL to the payment provider or vendor so we can process your request.',
  sponsorStudent:
    "Thinking about sponsoring a student in the future? Check this box and let us know how many students you'd like to support.",
  volunteer:
    "Want to volunteer? Check this box, choose your skills, and let us know how many hours you're committing.",
};
