// Functionality of this code : This code has the fullfilled request's table on admin pannel
// Input : None
// Output : Fullfilled Requests admin page
// Written by: CIS

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import tableIcons from './MaterialTableIcons';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

export default function FullRequests() {
  const theme = useTheme();
  function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const [error, setError] = React.useState('');
  const [fReq, setStudent] = React.useState([]);
  const columns = [
    {
      title: 'Application no.',
      field: 'application_no',
    },
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Need Type',
      field: 'need_type',
    },
    {
      title: 'Need Amount',
      field: 'need_amount',
    },
    {
      title: 'Fulfilled Amount',
      field: 'amount_fulfilled',
    },
    {
      title: 'Status',
      field: 'status',
    },
    {
      field: 'url',
      title: 'Fulfilled',
      render: rowData => (
        <LinearProgressWithLabel
          value={
            ((rowData.amount_fulfilled + 0.0001) /
              (rowData.need_amount + 0.0001)) *
            100
          }
        />
      ),
    },
  ];

  useEffect(() => {
    getCode();
  }, []);

  const getCode = () => {
    fetch(process.env.REACT_APP_PROXY + '/admin/fullrequests')
      .then(res => res.json())
      .then(data => {
        let resp = data.data;
        if (data.success === false) {
          setError(data.message);
        } else {
          const unique = [...new Set(resp)];
          setStudent(unique);
        }
      });
  };

  const editReq = value => {
    fetch(process.env.REACT_APP_PROXY + '/admin/updateRequest', value)
      .then(res => res.json())
      .then(data => {
        if (data.success === true) {
          toast.success(data.message, { autoClose: 1000 });
        } else {
          toast.error(data.message, { autoClose: 1000 });
        }
      });
  };

  const deleteReq = value => {
    fetch(process.env.REACT_APP_PROXY + '/admin/deleteRequest', value)
      .then(res => res.json())
      .then(data => {
        if (data.success === true) {
          toast.success(data.message, { autoClose: 1000 });
        } else {
          toast.error(data.message, { autoClose: 1000 });
        }
      });
  };

  return (
    <div
      className="content-wrapper"
      style={{ marginTop: '6%', overflow: 'hidden' }}
    >
      <MaterialTable
        title={<h3 style={{ color: '#F4113D' }}>Fullfilled Requests</h3>}
        icons={tableIcons}
        columns={columns}
        data={fReq}
        editable={{
          // onRowUpdate: (newData, oldData) =>
          //   new Promise((resolve, reject) => {
          //     console.log(newData, oldData);
          //     const requestOptions = {
          //       method: 'POST',
          //       headers: {
          //         'Content-Type': 'application/json',
          //       },
          //       body: JSON.stringify({
          //         newData,
          //       }),
          //     };
          //     editReq(requestOptions);
          //     setTimeout(() => {
          //       const dataUpdate = [...fReq];
          //       const index = oldData.tableData.id;
          //       dataUpdate[index] = newData;
          //       setStudent([...dataUpdate]);
          //       resolve();
          //     }, 1000);
          //   }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  oldData,
                }),
              };
              deleteReq(requestOptions);
              setTimeout(() => {
                const dataDelete = [...fReq];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setStudent([...dataDelete]);
                resolve();
              }, 1000);
            }),
        }}
        options={{
          sorting: true,
          exportButton: true,
          actionsColumnIndex: -1,
          headerStyle: { color: '#2998D1' },
        }}
      />
    </div>
  );
}
