// Functionality of this code : This code conatins Invoice page
// Input : None
// Output : Invoice Details -> Payment functionality
// Written by: CIS

import React, { useEffect } from 'react';
import * as firebase from 'firebase';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';

import axios from 'axios';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Typography } from '@material-ui/core';

toast.configure();

const useStyles = makeStyles(theme => ({
  cardMedia: {
    width: 120,
  },
  card: { wordWrap: 'break-word' },
  cardContenth1: {
    color: '#ed0a3f',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '30px',
  },
  cardGrid: { width: 1012 },
  cardContentDetail: {
    letterSpacing: '0.20px',
    wordWrap: 'break-word',
    marginLeft: '12px',
  },
  rectangleBox: {
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #00000029',
    height: '400px',
    left: '278px',
    position: 'absolute',
    top: '589px',
    width: '414px',
  },
  headLoginButton: {
    backgroundColor: '#232323',
    color: '#f2f2f2',
    '&:hover': {
      background: '#232323',
    },
  },
  text: {
    fontSize: '20px',
    fontWeight: 600,
  },
}));

export default function Invoice(props) {
  const [total, setTotal] = React.useState('');
  const [dataD, setData] = React.useState('');
  const [user, setUser] = React.useState('');
  const classes = useStyles();
  let t = 0;
  useEffect(() => {
    props.data.map(e => {
      t += parseInt(e.amount);
      setTotal(t);
    });
    setData(props.data);
    setUser(props.user.claims);
  }, [props && props.data]);

  const onBack = () => {
    props.setPage('dashboard');
  };

  const onSubmit = async () => {
    let data = {
      email: props.user.claims.email,
      amount: total,
      currency: 'SGD',
      redirect_url: process.env.REACT_APP_HITPAY_REDIRECT_URL,
      // redirect_url: 'http://localhost:3000/',
    };
    await axios
      .post('https://api.sandbox.hit-pay.com/v1/payment-requests', data, {
        headers: {
          'X-BUSINESS-API-KEY': process.env.REACT_APP_HITPAY_KEY,
        },
      })
      .then(async res => {
        let test = res.data;
        firebase
          .auth()
          .currentUser.getIdToken()
          .then(async function (idToken) {
            const requestOptionsTrans = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                auth: idToken,
              },
              body: JSON.stringify({ user, test }),
            };
            await fetch(
              process.env.REACT_APP_PROXY + '/payment/trans',
              requestOptionsTrans
            )
              .then(res => res.json())
              .then(data => {
                if (data) {
                  const requestOptions = {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      auth: idToken,
                    },
                    body: JSON.stringify({ dataD, user }),
                  };
                  fetch(
                    process.env.REACT_APP_PROXY + '/payment/setData',
                    requestOptions
                  )
                    .then(res => res.json())
                    .then(data => {})
                    .catch(error => {});
                }
              })
              .catch(error => {});
          });
        window.location.href = res.data.url;
      })
      .catch(e => {
        toast.error('Invalid API Key', { autoClose: 2000 });
      });
  };

  return (
    <>
      <div
        class="container px-4 bg-light"
        style={{ marginBottom: '40px', marginTop: '150px' }}
      >
        <Typography
          variant="body1"
          paragraph
          style={{
            marginTop: '90px',
            marginBottom: '40px',
            textAlign: 'center',
            color: 'black',
            fontSize: '22px',
          }}
        >
          Invoice
        </Typography>
        <table class="table table-borderless">
          <thead>
            <tr>
              <th scope="col">Product</th>
              <th scope="col">Amount</th>
              {/* <th scope="col">Active Date</th> */}
              <th scope="col">Expiry Date</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map(gift => (
              <>
                {gift.voucher_name ? (
                  <tr>
                    <td>{gift.voucher_name}</td>
                    <td>{gift.amount}</td>
                    {/* <td>{format(new Date(gift.active_date), 'yyyy-MM-dd')}</td> */}
                    <td>
                      {format(new Date(gift.expiration_date), 'yyyy-MM-dd')}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>
                      {gift.category}{' '}
                      {gift.food_option != null
                        ? '(' + gift.food_option + ')'
                        : ''}
                    </td>
                    <td>{gift.amount}</td>
                    {/* <td>{format(new Date(gift.active_date), 'yyyy-MM-dd')}</td> */}
                    <td>{format(new Date(gift.expiry_date), 'yyyy-MM-dd')}</td>
                  </tr>
                )}
              </>
            ))}
            <tr>
              <td className={classes.text}>Total</td>
              <td style={{ fontSize: '18px' }}>
                {' '}
                <b>{total}</b>
              </td>
              <td colSpan={2}></td>
            </tr>
          </tbody>
        </table>
        <div style={{ margin: '10% 0 14% 10%' }}>
          <button
            className="btn btn-dark"
            style={{
              width: '20%',
              height: '50px',
              backgroundColor: '#232323',
              color: '#f2f2f2',
              fontSize: '19px',
              fontWeight: '700',
              padding: '6px 27px',
              marginLeft: '25%',
              '&:hover': {
                background: '#232323',
              },
            }}
            onClick={onBack}
          >
            Cancel
          </button>
          <button
            disabled={total.length == 0}
            className="btn btn-dark"
            style={{
              marginLeft: '25px',
              width: '20%',
              height: '50px',
              backgroundColor: '#232323',
              color: '#f2f2f2',
              fontSize: '19px',
              fontWeight: '700',
              padding: '6px 27px',
            }}
            onClick={onSubmit}
          >
            Proceed
          </button>
        </div>
      </div>
    </>
  );
}
