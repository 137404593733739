import React from 'react';
// import videobg from  '../assets/video.jpg'
import videobg from '../assets/newui/2.png';

const heading = 'We support United Nation Sustainable Development Goal 4 ';
const para =
  'Aligns with goal number 4 of the United Nations 2030 Sustainable Development Agenda to  “Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all”!';

const VideoSection = props => {
  return (
    <>
      <section className="w3l-ab-section py-5">
        <div className="container ">
          <div className="row ">
            <div className="col-lg-6 section-width align-self">
              <h3 className="title-style pr-xl-5 text-center">{heading}</h3>
              <p
                className="mt-lg-4 mt-3 pb-3 "
                style={{
                  fontSize: '18px',
                  fontWeight: '400',
                  textAlign: 'center',
                }}
              >
                {para}
              </p>
              <div
                style={{ background: '#77000D' }}
                onClick={() => {
                  props.setPage('auth');
                }}
                className="btn btn-style mt-4 d-flex justify-content-center align-items-center btn-230 m-auto"
              >
                Get Started
              </div>
            </div>
            <div className="col-lg-6 history-info mt-5 pt-lg-0 pt-5">
              <div className="position-relative img-border">
                <img
                  src={videobg}
                  className="img-fluid video-popup-image"
                  alt="video-popup"
                />
                {/* <a
            href="#small-dialog"
            className="popup-with-zoom-anim play-view text-center position-absolute"
          >
            <span className="video-play-icon">
              <span className="fa fa-play" />
            </span>
          </a> */}
                {/* dialog itself, mfp-hide class is required to make dialog hidden */}
                {/* <div id="small-dialog" className="zoom-anim-dialog mfp-hide">
            <iframe
              src="https://player.vimeo.com/video/80590590"
              allowFullScreen=""
            />
          </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VideoSection;
