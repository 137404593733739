// Functionality of this code : This page contains the Student status table section
// Input : None
// Output : Student status table
// Written by: Initial team VK, KR
import '../css/table.css';
import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import * as firebase from 'firebase';
import FavoriteSharpIcon from '@material-ui/icons/FavoriteSharp';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import books from '../assets/icon2.png';
import housing from '../assets/housing.jpg';
import laptop from '../assets/laptop.jpg';
import school from '../assets/icon1.png';
import insurance from '../assets/insurance.jpg';
import mealPlan from '../assets/mealPlan.jpg';
import airmiles from '../assets/airmiles.jpg';
import certification from '../assets/certification.jpg';
import others from '../assets/others.jpg';
import LaptopChromebookIcon from '@material-ui/icons/LaptopChromebook';
import PaymentIcon from '@material-ui/icons/Payment';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import Container from '@material-ui/core/Container';
import glasses from '../assets/icon3.png';
import mrt from '../assets/mrt.jpg';
import toast from 'react-hot-toast';
import { TableFooter, TablePagination, TableRow } from '@material-ui/core';
import Spinner from '../utils/spinner';
import tableIcons from '../admin/MaterialTableIcons';
import Table from '../common/Table';
import { formatDate } from '../utils/dateUtils';
import { useDispatch, useSelector } from 'react-redux';
import { SetUserApplication } from '../store/actions/userActions';
import ViewDetailsButton from '../utils/Button';
import CommonModal from '../common/CommonModal';
import ApplicationDetails from './ApplcationDetails/ApplicationDetails';
import '../css/button.css';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
}));

const VolunteerHoursSlider = props => {
  const useStyles2 = makeStyles({
    root: {
      width: 250,
    },
    input: {
      width: 42,
    },
  });
  const classes = useStyles2();
  return (
    <div className={classes.root}>
      <Typography color="primary" id="input-slider" gutterBottom>
        Volunteer hours
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <WatchLaterIcon />
        </Grid>
        <Grid item xs>
          <Slider
            min={0}
            step={5}
            max={1000}
            disabled
            valueLabelDisplay="auto"
            value={props.row.volunteer_hours}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <Typography id="input-slider" gutterBottom>
            {props.row.volunteer_hours}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const DonationTimesSlider = props => {
  const useStyles2 = makeStyles({
    root: {
      width: 250,
      marginTop: 20,
    },
    input: {
      width: 42,
    },
  });
  const classes = useStyles2();
  return (
    <div className={classes.root}>
      <Typography color="primary" id="input-slider" gutterBottom>
        Requests willing to sponsor
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <PaymentIcon />
        </Grid>
        <Grid item xs>
          <Slider
            disabled
            min={0}
            step={1}
            max={10}
            marks
            valueLabelDisplay="auto"
            value={props.row.requests_willing_to_sponsor}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <Typography id="input-slider" gutterBottom>
            {props.row.requests_willing_to_sponsor}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const ProjectHoursSlider = props => {
  const useStyles2 = makeStyles({
    root: {
      width: 250,
      marginTop: 20,
    },
    input: {
      width: 42,
    },
  });
  const classes = useStyles2();
  return (
    <div className={classes.root}>
      <Typography color="primary" id="input-slider" gutterBottom>
        Hours for mini project
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <LaptopChromebookIcon />
        </Grid>
        <Grid item xs>
          <Slider
            disabled
            min={0}
            step={5}
            max={1000}
            valueLabelDisplay="auto"
            value={props.row.mini_project_hours}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <Typography id="input-slider" gutterBottom>
            {props.row.mini_project_hours}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

function GiveBackDialog(props) {
  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Pledge</DialogTitle>
        <DialogContent />

        <div style={{ marginLeft: 40, marginRight: 40 }}>
          <VolunteerHoursSlider row={props.row} />
          <DonationTimesSlider row={props.row} />
          <ProjectHoursSlider row={props.row} />
        </div>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default function StudentRequestTable() {
  const [newOpen, setNewOpen] = React.useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const notificaiton = useSelector(state => state.notification.notification);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const theme = useTheme();
  function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [row, setRow] = React.useState({});
  const [open, setopen] = useState(false);
  const [state, setState] = React.useState({
    columns: [
      {
        field: '',
        title: '',
        headerStyle: {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
        },
        render: rowData => (
          <>
            {rowData.need_type === 'Tuition' ||
            rowData.need_type === 'Default' ? (
              <Avatar variant="square" src={school} className={classes.large} />
            ) : (
              ''
            )}
            {rowData.need_type === 'Housing' ? (
              <Avatar
                variant="square"
                src={housing}
                className={classes.large}
              />
            ) : (
              ''
            )}
            {rowData.need_type === 'MRT' ? (
              <Avatar variant="square" src={mrt} className={classes.large} />
            ) : (
              ''
            )}
            {rowData.need_type === 'Pair Of Glasses' ? (
              <Avatar
                variant="square"
                src={glasses}
                className={classes.large}
              />
            ) : (
              ''
            )}
            {rowData.need_type === 'Books' ? (
              <Avatar variant="square" src={books} className={classes.large} />
            ) : (
              ''
            )}
            {rowData.need_type === 'Health Insurance' ? (
              <Avatar
                variant="square"
                src={insurance}
                className={classes.large}
              />
            ) : (
              ''
            )}
            {rowData.need_type === 'Air miles' ? (
              <Avatar
                variant="square"
                src={airmiles}
                className={classes.large}
              />
            ) : (
              ''
            )}
            {rowData.need_type === 'Laptop' ? (
              <Avatar variant="square" src={laptop} className={classes.large} />
            ) : (
              ''
            )}
            {rowData.need_type === 'Food and Beverage' ? (
              <Avatar
                variant="square"
                src={mealPlan}
                className={classes.large}
              />
            ) : (
              ''
            )}
            {rowData.need_type === 'Online Certification Courses' ? (
              <Avatar
                variant="square"
                src={certification}
                className={classes.large}
              />
            ) : (
              ''
            )}

            {rowData.need_type === 'Others' ? (
              <Avatar variant="square" src={others} className={classes.large} />
            ) : (
              ''
            )}
          </>
        ),
      },
      {
        title: 'Date',
        field: 'request_date',
        type: 'date',
        headerStyle: {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
        },
      },
      {
        title: 'Application Number',
        field: 'application_no',
        headerStyle: {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
        },
      },
      {
        title: 'Status',
        field: 'status',
        headerStyle: {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
        },
      },
      {
        title: 'Requested Amount',
        field: 'need_amount',
        type: 'currency',
        render: rowData => (
          <div>
            {' '}
            {rowData.need_type != 'Air miles' ? '$' : ''}
            {rowData.need_amount}
            {rowData.need_type === 'Air miles' ? 'mi' : '.00'}
          </div>
        ),
        align: 'left',
        headerStyle: {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
        },
      },
      {
        field: 'url',
        title: 'Fulfilled',
        align: 'left',
        headerStyle: {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
        },
        render: rowData => (
          <LinearProgressWithLabel
            value={
              ((rowData.amount_fulfilled + 0.001) /
                (rowData.need_amount + 0.001)) *
              100
            }
          />
        ),
      },
    ],
    data: [],
    // actions: [
    //   rowData => ({
    //     width: 100,
    //     headerStyle: {
    //       backgroundColor: theme.palette.primary.main,
    //       color: 'white',
    //     },
    //     icon: () => <FavoriteSharpIcon style={{ fill: 'green' }} />,
    //     tooltip: 'View',
    //     onClick: () => {
    //       setNewOpen(true);
    //       setRow(rowData);
    //     },
    //   }),
    // ],
  });
  const dispatch = useDispatch();

  useEffect(() => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(function (idToken) {
        const getRequestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            auth: idToken,
          },
        };
        fetch(
          process.env.REACT_APP_PROXY + '/user/application',
          getRequestOptions
        )
          .then(res => res.json())
          .then(d => {
            if (d.success === false) {
              // toast.error('Error in getting data', {
              //   duration: 2000,
              //   style: {
              //     width: '250px',
              //   },
              // });
              console.log(
                'Error in getting data | Please fill your preference properly'
              );
              // alert('failed');
            } else {
              setLoading(false);

              setState(prevState => {
                const data = d.message;
                return { ...prevState, data };
              });
              dispatch(SetUserApplication(d.message));
              // toast.success('Data fetch Successfully', {
              //   duration: 2000,
              //   style: {
              //     width: '250px',
              //   },
              // });
            }
          });
      });
  }, [notificaiton]);

  const handleClose = () => setopen(false);
  const [application, SetApplication] = useState([]);
  const OpenApplicationDetails = details => {
    SetApplication(details);
    setopen(true);
  };

  //   -----------***** Table data for Requests Status ****** ----------------------
  const requestStatusColumns = [
    'Request Info',
    'Application Number',
    'Fulfill By Date',
    'Status',
    'Requested Amount',
    'Fulfilled Amount',
    'Details',
  ];
  const renderRequestStatusRow = item => (
    <>
      <td>
        <div className="text-img-table">
          <div className="img-table">
            {item.need_type === 'Books' && (
              <img src={books} alt="Books" width="60" />
            )}
            {item.need_type === 'Tuition' && (
              <img src={school} alt="School" width="60" />
            )}
            {item.need_type === 'Pair Of Glasses' && (
              <img src={glasses} alt="Pair Of Glasses" width="60" />
            )}
          </div>
          <div className="text-table"></div>
        </div>
      </td>
      <td>
        <center>{item.application_no}</center>
      </td>
      <td>
        <span>{formatDate(item.need_dateline)}</span>
      </td>
      <td>
        <span className="active-dot" />
        {item.status}
      </td>
      <td>${item.need_amount}.00</td>
      <td>
        <LinearProgressWithLabel
          value={
            ((item.amount_fulfilled + 0.001) / (item.need_amount + 0.001)) * 100
          }
        />
      </td>
      <td>
        <center>
          <button
            href="#"
            className="ViewDetailsbutton"
            style={{ '--clr': '#7808d0' }}
            onClick={() => OpenApplicationDetails(item)}
          >
            <span className="ViewDetailsbutton__icon-wrapper">
              <svg
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ViewDetailsbutton__icon-svg"
                width={10}
              >
                <path
                  d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                  fill="currentColor"
                />
              </svg>
              <svg
                viewBox="0 0 14 15"
                fill="none"
                width={10}
                xmlns="http://www.w3.org/2000/svg"
                className="ViewDetailsbutton__icon-svg ViewDetailsbutton__icon-svg--copy"
              >
                <path
                  d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                  fill="currentColor"
                />
              </svg>
            </span>
            View Details
          </button>
        </center>
      </td>
    </>
  );

  return (
    // <div className="App">
    //   <Container className={classes.cardGrid} maxWidth="md">
    //     <MaterialTable
    //       isLoading={loading}
    //       icons={tableIcons}
    //       title=""
    //       localization={{
    //         header: {
    //           actions: 'Pledge',
    //         },
    //       }}
    //       options={{
    //         actionsColumnIndex: -1,
    //         search: false,
    //         pageSize: 5,
    //         pageSizeOptions: [3, 5, 10],
    //         toolbar: false,
    //         paging: true,
    //         headerStyle: {
    //           backgroundColor: theme.palette.primary.main,
    //           color: 'white',
    //         },
    //         actionsCellStyle: {
    //           minWidth: 80,
    //         },
    //       }}
    //       columns={state.columns}
    //       data={state.data}
    //       actions={state.actions}
    //     />
    //   </Container>
    //   <GiveBackDialog row={row} open={newOpen} setOpen={setNewOpen} />
    // </div>
    <>
      <>
        {loading && <Spinner />}
        <section className="sponsorship-requests-section">
          <div className="">
            <div className="mb-2">
              <b className="fs-4">Requests Status</b>
            </div>
            <div className="shadow-box">
              <div className="table-top table-sec">
                <Table
                  columns={requestStatusColumns}
                  rows={state.data}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  renderRow={renderRequestStatusRow}
                  loading={loading}
                  role="student"
                />
              </div>
            </div>
          </div>
        </section>
      </>

      {/* ------ Modal -------- */}
      {open && (
        <CommonModal open={open} handleClose={handleClose}>
          <ApplicationDetails
            application={application}
            handleClose={handleClose}
          />
        </CommonModal>
      )}
    </>
  );
}
