// Functionality of this code : This code conatins dashboard page
// Input : None
// Output : Dashboard page
// Written by: CIS
import './css/studentRequest.css';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import StarIcon from '@material-ui/icons/StarBorder';
import * as firebase from 'firebase';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import cardImg1 from './assets/card-img1.png';
import cardImg2 from './assets/card-img2.png';
import cardImg3 from './assets/card-img3.png';
import CommonDashboard from './common/CommonDashboard';
import { SetUserTokenResult } from './store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';

// this is the requests/pledges dashboard
// const useStyles = makeStyles(theme => ({
//   mainGrid: {
//     marginTop: theme.spacing(3),
//   },

//   '@global': {
//     ul: {
//       margin: 0,
//       padding: 0,
//       listStyle: 'none',
//     },
//   },

//   appBar: {
//     borderBottom: `1px solid ${theme.palette.divider}`,
//   },

//   toolbar: {
//     flexWrap: 'wrap',
//   },

//   toolbarTitle: {
//     flexGrow: 1,
//   },

//   link: {
//     margin: theme.spacing(1, 1.5),
//   },

//   heroContent: {
//     padding: theme.spacing(8, 0, 6),
//   },

//   cardHeader: {
//     color: '#2998D1 !important',
//     padding: '30px 30px !important',
//     // fontWeight: '600 !important',
//     fontSize: '18px !important',
//   },

//   cardPricing: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'baseline',
//     marginBottom: theme.spacing(2),
//   },

//   threebox: {
//     '@media (max-width: 767px)': {
//       padding: '15px!important',
//     },
//   },

//   container: {
//     padding: '0px 32px',
//     '@media (max-width: 767px)': {
//       marginTop: '-105px',
//     },
//   },

//   cardValue: {
//     color: '#ed0a3f',
//   },

//   cardImgblock: {
//     width: 66,
//     height: 66,
//     borderRadius: '50%',
//     background: '#F4113D',
//     padding: '15px',
//     textAlign: 'center',
//     margin: '40px 40px 0px 40px',
//   },

//   cardContent: {
//     padding: '0px 40px',
//     marginBottom: '80px',
//   },

//   cardMedia: {
//     marginTop: '-3px',
//   },

//   bgcolor: {
//     background: '#f2f2f2',
//   },

//   customcontainer: {
//     paddingLeft: 0,
//     paddingRight: 0,
//   },
// }));

//All stats of donation/pledges/requests in one page
export default function Dashboard(props) {
  const [volunteer_hours, setVolunteer_hours] = React.useState(0);
  const [project_hours, setProject_hours] = React.useState(0);
  const [requests_pledged, setRequests_pledged] = React.useState(0);
  const [requests_fullfill, setRequests_fullfill] = React.useState(0);
  const [requests_active, setRequests_active] = React.useState(0);
  const [available_voucher, setAvailable_voucher] = React.useState(0);
  const [requests_fullfill_donor, setRequestsDonor_fullfill] =
    React.useState(0);
  const [requests_active_donor, setRequestsDonor_active] = React.useState(0);
  const [used_voucher, setUsed_voucher] = React.useState(0);
  const [avail_voucher, setAvail_voucher] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [dashboard_table, setdashboard_table] = React.useState([]);
  const [balance, setBalance] = useState(0);
  const [categoryBalances, setcategoryBalances] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeReq, setactiveReq] = useState([]);
  const dispatch = useDispatch();
  const notificaiton = useSelector(state => state.notification.notification);

  var count = 0;
  var countStu = 0;
  var activeCount = 0;
  var countFull = 0;
  var countvou = 0;
  var available = 0;
  var used = 0;
  var tiers;

  // useEffect(() => {
  //   //fetch login user token
  //   firebase
  //     .auth()
  //     .currentUser.getIdToken()
  //     .then(function (idToken) {
  //       const requestOptions = {
  //         method: 'GET',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           auth: idToken,
  //         },
  //       };
  //       //fetch pledge

  //       fetch(process.env.REACT_APP_PROXY + '/user/vouchers', requestOptions)
  //         .then(res => res.json())
  //         .then(data => {
  //           if (data.profile === true) {
  //             for (var value of data.message) {
  //               if (value.is_used == 1) {
  //                 used++;
  //               }
  //               if (value.is_used == 0) {
  //                 available++;
  //               }
  //               setUsed_voucher(used);
  //               setAvailable_voucher(available);
  //             }
  //           }
  //         });

  //       fetch(process.env.REACT_APP_PROXY + '/user/donation', requestOptions)
  //         .then(res => res.json())
  //         .then(data => {
  //           if (data.profile === true) {
  //             setLoading(true)
  //             // Format the data to match the rows structure
  //             const formattedData = data.message.map(item => ({
  //               id: item.id,
  //               name: item.name, // Name of the student
  //               applicationnumber: item.application_no, // Application number
  //               donateamount: item.amount_fulfilled, // Donated amount
  //               needtype: item.need_type, // Need Type
  //               needdateline: item.need_dateline, // Need Date
  //               requestdate: item.request_date, // Request raised on
  //               fulfilledon: item.gift_date, // Fulfilled date
  //             }));
  //             setdashboard_table(formattedData);
  //             setBalance(data.total_remaining_balance);
  //             setcategoryBalances(data.remaining_balances)

  //             let uniqueObjArray = [
  //               ...new Map(
  //                 data.message.map(item => [item['application_no'], item])
  //               ).values(),
  //             ];
  //             for (var value of uniqueObjArray) {
  //               if (value.status === 'Fullfilled' || 'Inactive') {
  //                 countFull++;
  //               }
  //               setRequestsDonor_fullfill(countFull);
  //             }
  //           }
  //         });
  //       fetch(process.env.REACT_APP_PROXY + '/user/activeReq', requestOptions)
  //         .then(res => res.json())
  //         .then(data => {
  //           if (data.profile === true) {
  //             for (var value of data.message) {
  //               if (value.status == 'Active') {
  //                 activeCount++;
  //               }
  //               setRequestsDonor_active(activeCount);
  //             }
  //           }
  //         });
  //       fetch(process.env.REACT_APP_PROXY + '/user/pledge', requestOptions)
  //         .then(res => res.json())
  //         .then(data => {
  //           if (data.profile === true) {
  //             for (var value of data.message) {
  //               if (value.status !== 'Active') {
  //                 count++;
  //               }
  //               if (value.status === 'Active') {
  //                 countStu++;
  //               }
  //               if (value.voucher_id && value.voucher_link) {
  //                 countvou++;
  //               }
  //               setVolunteer_hours(
  //                 prevHours => prevHours + value.volunteer_hours
  //               );
  //               setProject_hours(
  //                 prevHours => prevHours + value.mini_project_hours
  //               );
  //               setRequests_pledged(
  //                 prevHours => prevHours + value.requests_willing_to_sponsor
  //               );
  //               setRequests_fullfill(count);
  //               setRequests_active(countStu);
  //               setAvail_voucher(countvou);
  //             }
  //             setTotal(data.message.length);
  //           } else {
  //             setVolunteer_hours(0);
  //             setProject_hours(0);
  //             setRequests_pledged(0);
  //             setRequests_fullfill(0);
  //             setRequests_active(0);
  //             setAvail_voucher(0);
  //             setRequestsDonor_active(0);
  //             setRequestsDonor_fullfill(0);
  //             setRequests_active(0);
  //             setTotal(0);
  //           }
  //         });
  //     });
  // }, []);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setLoading(true); // Start loading

      try {
        // Fetch login user token
        const user = firebase.auth().currentUser;
        const decodedToken = await user.getIdTokenResult();
        const idToken = await user.getIdToken();
        dispatch(SetUserTokenResult(decodedToken));
        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            auth: idToken,
          },
        };

        // Fetch vouchers
        const vouchersRes = await fetch(
          process.env.REACT_APP_PROXY + '/user/vouchers',
          requestOptions
        );
        const vouchersData = await vouchersRes.json();
        if (vouchersData.profile === true && isMounted) {
          let used = 0;
          let available = 0;
          for (const value of vouchersData.message) {
            if (value.is_used === 1) {
              used++;
            }
            if (value.is_used === 0) {
              available++;
            }
          }
          setUsed_voucher(used);
          setAvailable_voucher(available);
        }

        // Fetch donations
        const donationsRes = await fetch(
          process.env.REACT_APP_PROXY + '/user/donation',
          requestOptions
        );
        const donationsData = await donationsRes.json();
        if (isMounted) {
          setBalance(donationsData.total_remaining_balance);
          setcategoryBalances(donationsData.remaining_balances);

          if (donationsData.profile === true) {
            const formattedData = donationsData.message.map(item => ({
              id: item.id,
              name: item.name,
              applicationnumber: item.application_no,
              donateamount: item.amount_fulfilled,
              needtype: item.need_type,
              needdateline: item.need_dateline,
              requestdate: item.request_date,
              fulfilledon: item.gift_date,
            }));
            setdashboard_table(formattedData);

            const uniqueObjArray = [
              ...new Map(
                donationsData.message.map(item => [
                  item['application_no'],
                  item,
                ])
              ).values(),
            ];
            let countFull = 0;
            for (const value of uniqueObjArray) {
              if (
                value.status === 'Fullfilled' ||
                value.status === 'Inactive'
              ) {
                countFull++;
              }
            }
            setRequestsDonor_fullfill(countFull);
          }
        }
        // Fetch active requests
        const activeReqRes = await fetch(
          process.env.REACT_APP_PROXY + '/user/activeReq',
          requestOptions
        );
        const activeReqData = await activeReqRes.json();
        if (isMounted) {
          setactiveReq(activeReqData);

          if (activeReqData.profile === true) {
            let activeCount = 0;
            for (const value of activeReqData.message) {
              if (value.status === 'Active') {
                activeCount++;
              }
            }
            setRequestsDonor_active(activeCount);
          }
        }
        // Fetch pledges
        const pledgeRes = await fetch(
          process.env.REACT_APP_PROXY + '/user/pledge',
          requestOptions
        );
        const pledgeData = await pledgeRes.json();
        if (pledgeData.profile === true && isMounted) {
          let count = 0;
          let countStu = 0;
          let countvou = 0;
          for (const value of pledgeData.message) {
            if (value.status !== 'Active') {
              count++;
            }
            if (value.status === 'Active') {
              countStu++;
            }
            if (value.voucher_id && value.voucher_link) {
              countvou++;
            }
            setVolunteer_hours(prevHours => prevHours + value.volunteer_hours);
            setProject_hours(prevHours => prevHours + value.mini_project_hours);
            setRequests_pledged(
              prevHours => prevHours + value.requests_willing_to_sponsor
            );
            setRequests_fullfill(count);
            setRequests_active(countStu);
            setAvail_voucher(countvou);
          }
          setTotal(pledgeData.message.length);
        } else if (isMounted) {
          // Reset state if no profile data is found
          setVolunteer_hours(0);
          setProject_hours(0);
          setRequests_pledged(0);
          setRequests_fullfill(0);
          setRequests_active(0);
          setAvail_voucher(0);
          setRequestsDonor_active(0);
          setRequestsDonor_fullfill(0);
          setTotal(0);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching data:', error);
        }
      } finally {
        if (isMounted) {
          setLoading(false); // End loading
        }
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, [notificaiton]);

  // const tiers = [
  //   {
  //     title: 'Requests',
  //     description: [
  //       total + ' Requests made',
  //       requests_fullfill + ' Requests fullfilled',
  //     ],
  //     cardImg: cardImg1,
  //   },
  //   {
  //     title: 'Volunteers hours',
  //     description: [
  //       volunteer_hours + ' Hours pledged',
  //       project_hours + ' Hours volunteered',
  //     ],
  //     cardImg: cardImg2,
  //   },
  //   {
  //     title: 'Sponsorship pledges',
  //     description: [
  //       requests_pledged + ' Request pledged',
  //       requests_fullfill + ' Supported requests',
  //     ],
  //     cardImg: cardImg3,
  //   },
  // ];

  if (props.role === 'sponsor') {
    tiers = [
      {
        title: 'Number of requests received',
        description: [requests_active_donor + ' New requests'],
        cardImg: cardImg1,
      },
      // {
      //   title: 'Vouchers',
      //   description: [
      //     available_voucher + ' Vouchers available',
      //     used_voucher + ' Vouchers gifted',
      //   ],
      //   cardImg: cardImg2,
      // },
      {
        title: 'Number of requests sponsored',
        description: [requests_fullfill_donor + ' Fulfilled requests'],
        cardImg: cardImg3,
      },
    ];
  } else if (props.role === 'student') {
    tiers = [
      {
        title: 'My Requests',
        description: [
          requests_active + ' Active Requests',
          requests_fullfill + ' Requests fullfilled',
        ],
        cardImg: cardImg1,
      },
      // {
      //   title: 'Available Vouchers ',
      //   description: [used_voucher + ' Vouchers'],
      //   cardImg: cardImg2,
      // },
      {
        title: 'My Sponsorships',
        description: [
          requests_pledged + ' Request pledged',
          requests_fullfill + ' Supported requests',
        ],
        cardImg: cardImg3,
      },
    ];
  } else {
    tiers = [
      {
        title: 'Requests',
        description: [
          total + ' Requests made',
          requests_fullfill + ' Requests fullfilled',
        ],
        cardImg: cardImg1,
      },
      {
        title: 'Volunteers hours',
        description: [
          volunteer_hours + ' Hours pledged',
          project_hours + ' Hours volunteered',
        ],
        cardImg: cardImg2,
      },
      {
        title: 'Sponsorship pledges',
        description: [
          requests_pledged + ' Request pledged',
          requests_fullfill + ' Supported requests',
        ],
        cardImg: cardImg3,
      },
    ];
  }

  // const classes = useStyles();
  return (
    <>
      <CommonDashboard
        donationData={dashboard_table}
        RequestReceived={requests_active_donor}
        RequestSponsored={requests_fullfill_donor}
        RequestFullfill={requests_fullfill}
        RequestPledged={requests_pledged}
        RequestActiveStudent={requests_active}
        TotalReqMade={total}
        VolunteerHours={volunteer_hours}
        ProjectHours={project_hours}
        balance={balance}
        CategoryBalances={categoryBalances}
        loading={loading}
        role={props.role}
        setpage={props.setPage}
      />
    </>
  );
}

{
  /* <CssBaseline /> */
}

{
  /* <Container className={classes.customcontainer}>
          <div className={classes.bgcolor}>
            <Container className={classes.container}>
              <Grid
                container
                spacing={8}
                alignItems="flex-end"
                className={classes.spacing}
              >
                {tiers.map(tier => (
                  <Grid
                    item
                    key={tier.title}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    className={classes.threebox}
                  >
                    <Card style={{ maxHeight: '325px' }}>
                      <div className={classes.cardImgblock}>
                        <CardMedia
                          className={classes.cardMedia}
                          image={tier.cardImg}
                          title="Image title"
                          component="img"
                        />
                      </div>
                      <CardActions>
                        <Button
                          className={classes.cardHeader}
                          onClick={e => {
                            if (e.target.innerHTML == 'Micro Need Requests') {
                              props.setPage('page');
                            } else if (e.target.innerHTML == 'Vouchers') {
                              props.setPage('vouchers');
                            } else if (
                              e.target.innerHTML == 'Micro Needs Met'
                            ) {
                              props.setPage('page');
                            } else if (
                              e.target.innerHTML == 'Available Vouchers'
                            ) {
                              props.setPage('stuVoucher');
                            } else {
                            }
                          }}
                          disableRipple
                        >
                          {tier.title}
                        </Button>
                      </CardActions>
                      <CardContent className={classes.cardContent}>
                        <ul>
                          {tier.description.map(line => (
                            <Typography
                              component="li"
                              variant="subtitle1"
                              align="left"
                              key={line}
                            >
                              {line}
                            </Typography>
                          ))}
                        </ul>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </div>
  </Container> */
}
