const initialState = {
  isEligible: false,
  message: '',
};

const eligibilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_IS_ELIGIBLE':
      return {
        ...state,
        isEligible: action.payload.isEligible,
        message: action.payload.message,
      };
    default:
      return state;
  }
};

export default eligibilityReducer;
