// Functionality of this code : This code contains buy voucher online
// Input : None
// Output : Buy Online voucher -> sponsor page
// Written by: CIS

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as firebase from 'firebase';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { Button, Checkbox } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import axios from 'axios';
import qs from 'qs';

const useStyles = makeStyles(theme => ({
  cardMedia: {
    width: 120,
  },
  card: { wordWrap: 'break-word' },
  cardContenth1: {
    color: '#ed0a3f',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '30px',
  },
  cardGrid: { width: 1012 },
  cardContentDetail: {
    letterSpacing: '0.20px',
    wordWrap: 'break-word',
    marginLeft: '12px',
  },
  rectangleBox: {
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #00000029',
    height: '400px',
    left: '278px',
    position: 'absolute',
    top: '589px',
    width: '414px',
  },
  headLoginButton: {
    backgroundColor: '#232323',
    color: '#f2f2f2',
    '&:hover': {
      background: '#232323',
    },
  },
}));

export default function OnlineVoucher(props) {
  const [datas, setData] = React.useState([]);
  const [need_type, setNeed_type] = React.useState([]);
  const [msg, setMsg] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();
  useEffect(() => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async function (idToken) {
        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            auth: idToken,
          },
        };
        fetch(
          process.env.REACT_APP_PROXY + '/user/uniquegiftOnline',
          requestOptions
        )
          .then(res => res.json())
          .then(data => {
            if (data.success === true) {
              if (data.data) {
                setLoading(true);
                let temp = [];
                temp = data.data;
                setData([...temp]);
              } else {
                setLoading(false);
              }
            } else {
              setMsg(false);
            }
          })
          .catch(error => {});
      });
  }, []);

  let data3 = false;
  const handleOnTap = e => {
    var tempdata = [];
    const { checked, value, id } = e.target;
    if (checked) {
      if (!data3) {
        tempdata.push({ id: id, voucher_ref: value });
      }
      var x = need_type.concat(tempdata);
      setNeed_type(x);
    } else {
      need_type.map(item => {
        if (item.voucher_ref == value) {
          var index = need_type.indexOf(item);
          need_type.splice(index, 1);
          setNeed_type([...need_type]);
        }
      });
    }
  };
  const onBack = () => {
    props.setPage('dashboard');
  };
  const onSubmit = () => {
    let result = datas.filter(o1 =>
      need_type.some(o2 => o1.voucher_ref === o2.voucher_ref)
    );
    props.setPage('invoice');
    props.setData(result);
  };

  return (
    <>
      {msg === true ? (
        <Typography
          variant="body1"
          paragraph
          style={{
            marginTop: '90px',
            marginBottom: '40px',
            textAlign: 'center',
            color: 'black',
            fontSize: '22px',
          }}
        >
          Choose from below
        </Typography>
      ) : (
        ''
      )}

      <Container maxWidth="lg" style={{ marginBottom: '10px' }}>
        {!datas.length && msg === true ? (
          <Box sx={{ display: 'flex', marginLeft: '50%' }}>
            <CircularProgress color="success" />
          </Box>
        ) : (
          <Grid container spacing={4}>
            {msg === false ? (
              <h1 style={{ margin: '26% 0 25% 40%' }}>No Voucher Available</h1>
            ) : (
              <>
                {datas.map(gift => (
                  <Grid
                    item
                    key={gift.voucher_ref}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                  >
                    <Card className={classes.card}>
                      <Checkbox
                        checked={
                          need_type.some(
                            e => gift.voucher_ref === e.voucher_ref
                          )
                            ? true
                            : false
                        }
                        id={gift.id}
                        value={gift.voucher_ref}
                        onChange={handleOnTap}
                      />
                      <CardContent className={classes.cardContent}>
                        <Typography
                          gutterBottom
                          variant="h3"
                          className={classes.cardContenth1}
                        >
                          {gift.voucher_name}
                        </Typography>
                        <Typography className={classes.cardContentDetail}>
                          <span style={{ fontWeight: 600 }}>Amount :</span>{' '}
                          {gift.amount}
                        </Typography>
                        {/* <Typography className={classes.cardContentDetail}>
                      <span style={{ fontWeight: 600 }}>Auth Code :</span>{' '}
                      {gift.auth_code}
                    </Typography> */}
                        {/* <Typography className={classes.cardContentDetail}>
                        <span style={{ fontWeight: 600 }}> Active Date : </span>{' '}
                        {format(new Date(gift.active_date), 'yyyy-MM-dd')}
                      </Typography> */}
                        <Typography className={classes.cardContentDetail}>
                          <span style={{ fontWeight: 600 }}>
                            {' '}
                            Expiry Date :{' '}
                          </span>{' '}
                          {format(new Date(gift.expiration_date), 'yyyy-MM-dd')}
                        </Typography>
                        {/* <Typography className={classes.cardContentDetail}>
                      <span style={{ fontWeight: 600 }}>Voucher GUID :</span>{' '}
                      {gift.voucher_guid}
                    </Typography> */}
                        {/* <Typography className={classes.cardContentDetail}>
                          <span style={{ fontWeight: 600 }}>URL :</span>{' '}
                          {gift.edenred_url}
                        </Typography> */}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        )}
        {msg === true ? (
          <div style={{ margin: '25px 0px 25px 35%' }}>
            <button
              className="btn btn-dark"
              style={{
                width: '20%',
                height: '50px',
                margin: '20px',
                backgroundColor: '#232323',
                color: '#f2f2f2',
                fontSize: '19px',
                fontWeight: '700',
                padding: '6px 27px',
                '&:hover': {
                  background: '#232323',
                },
              }}
              onClick={onBack}
            >
              Back
            </button>
            <button
              disabled={need_type.length === 0}
              className="btn btn-dark"
              style={{
                // margin: '20px 40% 10px',
                width: '20%',
                height: '50px',
                backgroundColor: '#232323',
                color: '#f2f2f2',
                fontSize: '19px',
                fontWeight: '700',
                padding: '6px 27px',
                '&:hover': {
                  background: '#232323',
                },
              }}
              onClick={onSubmit}
            >
              Proceed
            </button>
          </div>
        ) : (
          <button
            className="btn btn-dark"
            style={{
              width: '20%',
              height: '50px',
              backgroundColor: '#232323',
              color: '#f2f2f2',
              fontSize: '19px',
              fontWeight: '700',
              margin: '20px 43%',
              padding: '6px 27px',
              '&:hover': {
                background: '#232323',
              },
            }}
            onClick={onBack}
          >
            Back
          </button>
        )}
      </Container>
    </>
  );
}
