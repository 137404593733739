import React from 'react';
import './activity.css';

const ActivityCards = ({ title, subheader, des, icon }) => {
  // Function to extract numbers and texts from the des array
  const extractValues = (desArray) => {
    const numbers = [];
    const texts = [];
    desArray.forEach((item) => {
      const match = item.match(/(\d+)\s(.+)/); // Adjusted regex to match a number followed by any text
      // console.log(match);
      if (match) {
        numbers.push(match[1]); // First capturing group is the number
        texts.push(match[2]); // Second capturing group is the text
      }
    });
    return { numbers, texts };
  };

  // Extract numbers and texts from the des prop
  const { numbers, texts } = extractValues(des);

  // Render numbers and texts
  const renderStats = () => {
    return numbers.map((number, index) => (
      <p key={index} className="flex">
        {texts[index]}
        <span className="state-value">
          {number}
        </span>
      </p>
    ));
  };

  return (
    <div className="card shadow">
      <div className="infos">
        <div className="image">
            <img src={icon} alt='icon'/>
        </div>
        <div className="info" >
          <div>
            <p className="name">
              {title}
            </p>
            <p className="function" style={{ marginTop: '10px'}}>
              {subheader}
            </p>
          </div>
          <div className="stats">
            {renderStats()}
          </div>
        </div>
      </div>
      <button className="request" type="button" style={{ marginTop: '50px'}}>
        Read more
      </button>
    </div>
  );
};

export default ActivityCards;
