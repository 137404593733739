// Functionality of this code : This code has the organization's table on admin pannel
// Input : None
// Output : Organizations admin page
// Written by: CIS

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import tableIcons from './MaterialTableIcons';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();
export default function OrganizationsTable() {
  const [error, setError] = React.useState('');
  const [org, setStudent] = React.useState([]);
  const columns = [
    {
      title: 'Name',
      field: 'org_name',
    },
    {
      title: 'Country',
      field: 'country',
    },
  ];

  useEffect(() => {
    getCode();
  }, []);

  const getCode = () => {
    fetch(process.env.REACT_APP_PROXY + '/admin/organization')
      .then(res => res.json())
      .then(data => {
        let resp = data.data;
        if (data.success === false) {
          setError(data.message);
        } else {
          const unique = [...new Set(resp)];
          setStudent(unique);
        }
      });
  };

  const addOrg = value => {
    fetch(process.env.REACT_APP_PROXY + '/admin/addOrg', value)
      .then(res => res.json())
      .then(data => {
        if (data.success === true) {
          toast.success(data.message, { autoClose: 1000 });
        } else {
          toast.error(data.message, { autoClose: 1000 });
        }
      });
  };

  const editOrg = value => {
    fetch(process.env.REACT_APP_PROXY + '/admin/updateOrg', value)
      .then(res => res.json())
      .then(data => {
        if (data.success === true) {
          toast.success(data.message, { autoClose: 1000 });
        } else {
          toast.error(data.message, { autoClose: 1000 });
        }
      });
  };

  const deleteOrg = value => {
    fetch(process.env.REACT_APP_PROXY + '/admin/deleteOrg', value)
      .then(res => res.json())
      .then(data => {
        if (data.success === true) {
          toast.success(data.message, { autoClose: 1000 });
        } else {
          toast.error(data.message, { autoClose: 1000 });
        }
      });
  };

  return (
    <div
      className="content-wrapper"
      style={{ marginTop: '6%', overflow: 'hidden' }}
    >
      <MaterialTable
        title={<h3 style={{ color: '#F4113D' }}>Organizations</h3>}
        icons={tableIcons}
        columns={columns}
        data={org}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  newData,
                }),
              };
              addOrg(requestOptions);
              setStudent([...org, newData]);
              resolve();
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  newData,
                }),
              };
              editOrg(requestOptions);
              setTimeout(() => {
                const dataUpdate = [...org];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                setStudent([...dataUpdate]);
                resolve();
              }, 1000);
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  oldData,
                }),
              };
              deleteOrg(requestOptions);
              setTimeout(() => {
                const dataDelete = [...org];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setStudent([...dataDelete]);
                resolve();
              }, 1000);
            }),
        }}
        options={{
          sorting: true,
          exportButton: true,
          actionsColumnIndex: -1,
          headerStyle: { color: '#2998D1' },
        }}
      />
    </div>
  );
}
