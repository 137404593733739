// // Functionality of this code : This page contains the code for "How It works -> Student"
// // Input : None
// // Output : Home Page -> How It Works -> Menu ( Student, Donor, Institute) -> Student Page
// // Written by: Initial team VK, KR

// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import Container from '@material-ui/core/Container';
// import CardMedia from '@material-ui/core/CardMedia';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import Switch from '@material-ui/core/Switch';
// import { withStyles } from '@material-ui/core/styles';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
// import maskgroup1 from '../assets/new/mask-group-1.png';
// import maskgroup2 from '../assets/new/mask-group-2.png';
// import clogo from '../assets/new/clogo.png';
// import org from '../assets/new/org.svg';
// import spon from '../assets/new/spon.png';
// import Student from './HowItWorks/Student';
// import Sponser from './HowItWorks/Sponser';
// import Organisation from './HowItWorks/Organisation';

// const useStyles = makeStyles(theme => ({
//   icon: {
//     marginRight: theme.spacing(2),
//   },

//   heroContent: {
//     backgroundColor: theme.palette.background.paper,
//     padding: theme.spacing(8, 0, 6),
//   },

//   heroButtons: {
//     marginTop: theme.spacing(4),
//   },

//   cardGrid: {
//     paddingTop: theme.spacing(4),
//     paddingBottom: theme.spacing(8),
//   },

//   card: {
//     height: '100%',
//     display: 'flex',
//     flexDirection: 'column',
//   },

//   cardMedia: {
//     paddingTop: '80.25%',
//   },

//   cardContent: {
//     flexGrow: 1,
//   },

//   footer: {
//     backgroundColor: theme.palette.background.paper,
//     padding: theme.spacing(6),
//   },

//   cBanner: {
//     width: '100%',
//     padding: '95px 0',
//     marginTop: '-90px',
//     backgroundColor: '#f2f2f2;',
//     '@media (max-width: 767px)': {
//       padding: '20px 0 30px 0',
//       marginTop: '0px',
//     },
//   },

//   cBannerLeft: {
//     width: '50%',
//     float: 'left',
//     backgroundImage: { maskgroup1 },
//   },

//   cBannerRight: {
//     width: '50%',
//     float: 'left',
//     backgroundImage: { maskgroup2 },
//   },

//   cBannerImg: {
//     position: 'absolute',
//     top: '0',
//   },

//   cBannerH1: {
//     backgroundColor: 'transparent',
//     color: '#ed0a3f',
//     fontStyle: 'normal',
//     fontWeight: '700',
//     height: 'auto',
//     lineHeight: 'normal',
//     width: 'auto',
//     textAlign: 'center',
//     fontSize: '26px',
//     '@media (max-width: 767px)': {
//       fontSize: '22px',
//     },
//   },

//   cBannerDetail: {
//     backgroundColor: 'transparent',
//     left: '0',
//     right: '0',
//     margin: '0 auto',
//     textAlign: 'center',
//     zIndex: '999',
//     width: '250px',
//   },

//   cHeader: {
//     backgroundColor: 'transparent',
//     color: '#5fcaff',
//     fontStyle: 'normal',
//     fontWeight: 700,
//     height: 'auto',
//     lineHeight: '48px',
//     position: 'absolute',
//     textAlign: 'left',
//     whiteSpace: 'nowrap',
//     width: 'auto',
//   },

//   cStatus: {
//     margin: 'auto',
//     width: '9%',
//     paddingBottom: '20px',
//   },

//   maskGroup1: {
//     right: '0',
//     objectFit: 'cover',
//     position: 'absolute',
//     width: '90px',
//     '@media (max-width: 767px)': {
//       width: '50px',
//     },
//   },

//   maskGroup2: {
//     left: '0',
//     bottom: '0',
//     objectFit: 'cover',
//     position: 'absolute',
//     width: '150px',
//     '@media (max-width: 767px)': {
//       width: '100px',
//     },
//   },

//   groupTopHead: {
//     backgroundColor: 'transparent',
//     color: '#5fcaff',
//     fontStyle: 'normal',
//     fontWeight: 700,
//     height: 'auto',
//     lineHeight: 'normal',
//     textAlign: 'center',
//     width: 'auto',
//     margin: '0 0 50px 0',
//     fontSize: '26px',
//     '@media (max-width: 767px)': {
//       fontSize: '22px',
//     },
//     span: {
//       background: 'red',
//     },
//   },

//   groupHead: {
//     color: '#5fcaff',
//     fontStyle: 'normal',
//     fontWeight: 600,
//     fontSize: '20px',
//     display: 'block',
//     marginLeft: '20px',
//     marginBottom: '15px',
//     lineHeight: 'normal',
//     position: 'relative',
//     '@media (max-width: 991px)': {
//       marginBottom: '0',
//       fontSize: '25px',
//     },
//     '@media (max-width: 767px)': {
//       fontSize: '20px',
//       lineHeight: 'normal',
//     },
//   },

//   groupDetail: {
//     color: '#232323',
//     fontSize: '16px',
//     fontStyle: 'normal',
//     fontWeight: 400,
//     '@media (max-width: 767px)': {
//       fontSize: '16px',
//       lineHeight: '22px',
//     },
//   },

//   groupTopHeadSe: {
//     backgroundColor: 'transparent',
//     color: '#f4113d',
//     fontStyle: 'normal',
//     fontWeight: 700,
//     height: 'auto',
//     lineHeight: 'normal',
//     fontSize: '26px',
//     textAlign: 'center',
//     width: 'auto',
//     margin: '0 0 50px 0',
//     '@media (max-width: 767px)': {
//       fontSize: '22px',
//     },
//   },

//   maincontent: {
//     position: 'relative',
//     marginTop: '110px',
//     '@media (max-width: 900px)': {
//       marginTop: '60px',
//     },
//   },

//   groupHeadSe: {
//     color: '#f4113d',
//     fontStyle: 'normal',
//     fontWeight: 600,
//     fontSize: '20px',
//     display: 'block',
//     marginBottom: '15px',
//     marginLeft: '20px',
//     lineHeight: 'normal',
//     position: 'relative',
//     '@media (max-width: 991px)': {
//       marginBottom: '0',
//       fontSize: '25px',
//     },
//     '@media (max-width: 767px)': {
//       fontSize: '20px',
//       lineHeight: 'normal',
//     },
//   },

//   groupDetailSe: {
//     color: '#232323',
//     fontSize: '16px',
//     fontStyle: 'normal',
//     fontWeight: 400,
//     '@media (max-width: 767px)': {
//       fontSize: '16px',
//       lineHeight: '22px',
//     },
//   },

//   groupTopHeadOr: {
//     backgroundColor: 'transparent',
//     color: '#232323',
//     fontStyle: 'normal',
//     fontWeight: 700,
//     height: 'auto',
//     lineHeight: 'normal',
//     textAlign: 'center',
//     width: 'auto',
//     fontSize: '26px',
//     margin: '0 0 50px 0',
//     '@media (max-width: 767px)': {
//       fontSize: '22px',
//     },
//   },

//   centerlogo: {
//     width: '312px',
//     margin: '0 auto',
//     '@media (max-width: 959px)': {
//       width: '350px',
//       margin: '0 auto',
//     },
//     '@media (max-width: 767px)': {
//       width: '250px',
//       margin: '0 auto',
//     },
//   },
//   centerlogostu: {
//     width: '600px',
//     margin: '0 auto',
//     '@media (max-width: 959px)': {
//       width: '350px',
//       margin: '0 auto',
//     },
//     '@media (max-width: 767px)': {
//       width: '250px',
//       margin: '0 auto',
//     },
//   },

//   blockbox: {
//     '@media (max-width: 959px)': {
//       paddingTop: '0 !important',
//       paddingBottom: '0 !important',
//     },
//   },

//   blockboxDesk: {
//     '@media (max-width: 959px)': {
//       display: 'none',
//     },
//   },

//   blockboxMb: {
//     display: 'none',
//     '@media (max-width: 959px)': {
//       display: 'block',
//       width: '100% !important',
//       float: 'left !important',
//       marginTop: '20px !important',
//     },
//   },

//   blockboximageDesk: {
//     '@media (max-width: 959px)': {
//       display: 'none',
//     },
//   },

//   blockboximageMb: {
//     display: 'none',
//     '@media (max-width: 959px)': {
//       display: 'block',
//     },
//   },

//   blockboxinfo: {
//     '@media (max-width: 959px)': {
//       width: '100% !important',
//       float: 'left !important',
//       marginTop: '20px !important',
//     },
//   },

//   spacing: {
//     padding: '80px 0',
//     '@media (max-width: 900px)': {
//       padding: '50px 0',
//     },
//     '@media (max-width: 767px)': {
//       padding: '30px 0',
//     },
//   },

//   groupHeadOr: {
//     color: '#232323',
//     fontStyle: 'normal',
//     fontWeight: 600,
//     fontSize: '20px',
//     display: 'block',
//     marginBottom: '15px',
//     marginLeft: '20px',
//     lineHeight: 'normal',
//     position: 'relative',
//     '@media (max-width: 991px)': {
//       marginBottom: '0',
//       fontSize: '25px',
//     },
//     '@media (max-width: 767px)': {
//       fontSize: '20px',
//       lineHeight: 'normal',
//     },
//   },

//   groupDetailOr: {
//     color: '#232323',
//     fontSize: '16px',
//     fontStyle: 'normal',
//     fontWeight: 400,
//     '@media (max-width: 767px)': {
//       fontSize: '16px',
//       lineHeight: '22px',
//     },
//   },

//   spacing: {
//     padding: '40px 0',
//     '@media (max-width: 900px)': {
//       padding: '50px 0',
//     },
//     '@media (max-width: 767px)': {
//       padding: '30px 0',
//     },
//   },

//   customcontainer: {
//     maxWidth: '1365px',
//   },

//   numb: {
//     position: 'absolute',
//     right: '100%',
//     fontSize: '20px',
//     fontWeight: '700',
//     marginRight: '10px',
//     '@media (max-width: 991px)': {
//       position: 'static',
//       fontSize: '25px',
//     },
//   },

//   linepattern: {
//     background: 'linear-gradient(90deg, #232323 23.43%, #ffffff 80.81%)',
//     backgroundColor: 'transparent',
//     height: '4px',
//     marginTop: '80px',
//     width: 'calc(100%)',
//     '@media (max-width: 900px)': {
//       marginTop: '50px',
//     },
//     '@media (max-width: 767px)': {
//       marginTop: '20px',
//     },
//   },
// }));

// const IOSSwitch = withStyles(theme => ({
//   root: {
//     width: 42,
//     height: 26,
//     padding: 0,
//     margin: theme.spacing(1),
//     marginRight: '15px',
//   },

//   switchBase: {
//     padding: 1,
//     top: '3px',
//     left: '3px',
//     '&$checked': {
//       transform: 'translateX(20px)',
//       left: '0',
//       color: theme.palette.common.white,
//       '& + $track': {
//         backgroundColor: '#ffffff',
//         opacity: 1,
//         border: '1px solid #000',
//       },
//     },
//     '&$focusVisible $thumb': {
//       color: '#ed0a3f',
//       border: '6px solid #ed0a3f',
//     },
//   },

//   thumb: {
//     width: 18,
//     height: 18,
//     background: '#000',
//   },

//   track: {
//     borderRadius: 26 / 2,
//     border: '1px solid #000',
//     backgroundColor: theme.palette.grey[50],
//     opacity: 1,
//     transition: theme.transitions.create(['background-color', 'border']),
//   },
//   checked: {},
//   focusVisible: {},
// }))(({ classes, ...props }) => {
//   return (
//     <Switch
//       focusVisibleClassName={classes.focusVisible}
//       disableRipple
//       classes={{
//         root: classes.root,
//         switchBase: classes.switchBase,
//         thumb: classes.thumb,
//         track: classes.track,
//         checked: classes.checked,
//       }}
//       {...props}
//     />
//   );
// });

// //Student Instruction page at how it works
// export default function StudentInstruction(props) {
//   // const Student = () => {
//   //   const classes = useStyles();
//   //   return (
//   //     <Grid xs={12}>
//   //       <Container className={classes.customcontainer}>
//   //         <Grid
//   //           container
//   //           item
//   //           xs={12}
//   //           justifyContent="center"
//   //           alignItems="center"
//   //           spacing={3}
//   //         >
//   //           <React.Fragment>
//   //             <Grid item xs={12} style={{ textAlign: 'center' }}>
//   //               <h1 className={classes.groupTopHead}> For Students</h1>
//   //             </Grid>
//   //           </React.Fragment>
//   //         </Grid>
//   //       </Container>
//   //       <Container className={classes.customcontainer}>
//   //         <Grid container item xs={12} spacing={3}>
//   //           <React.Fragment>
//   //             <Grid
//   //               item
//   //               xs={12}
//   //               sm={12}
//   //               md={4}
//   //               className={classes.blockboximageMb}
//   //             >
//   //               <CardMedia
//   //                 component="img"
//   //                 className={classes.centerlogostu}
//   //                 image={clogo}
//   //                 title="Live from space album cover"
//   //               />
//   //             </Grid>
//   //             <Grid item sm={12} md={4} className={classes.blockbox}>
//   //               <div
//   //                 style={{ marginTop: '10%' }}
//   //                 className={classes.blockboxDesk}
//   //               >
//   //                 <span className={classes.groupHead}>
//   //                   <small className={classes.numb}>4</small> Receive
//   //                   Sponsorship And Fulfill Your Commitment
//   //                 </span>
//   //                 <span className={classes.groupDetail}>
//   //                   Receive educational support from your sponsor. After that,
//   //                   it’s time to fulfill your pledge!
//   //                 </span>
//   //                 <br />
//   //               </div>
//   //               <div
//   //                 style={{ width: '80%', marginTop: '30%' }}
//   //                 className={classes.blockboxDesk}
//   //               >
//   //                 <span className={classes.groupHead}>
//   //                   <small className={classes.numb}>3</small> Make A Pledge
//   //                 </span>
//   //                 <span className={classes.groupDetail}>
//   //                   You must make a pledge to volunteer your time or to become a
//   //                   future sponsor.
//   //                 </span>
//   //                 <br />
//   //               </div>
//   //             </Grid>
//   //             <Grid item sm={12} md={4} className={classes.blockboximageDesk}>
//   //               <CardMedia
//   //                 component="img"
//   //                 className={classes.centerlogostu}
//   //                 image={clogo}
//   //                 title="Live from space album cover"
//   //               />
//   //             </Grid>
//   //             <Grid item sm={12} md={4} className={classes.blockbox}>
//   //               <div
//   //                 style={{ width: '80%', marginTop: '40px' }}
//   //                 className={classes.blockboxinfo}
//   //               >
//   //                 <span className={classes.groupHead}>
//   //                   <small className={classes.numb}>1</small> Create Your
//   //                   Profile
//   //                 </span>
//   //                 <span className={classes.groupDetail}>
//   //                   Register on the site and create your profile.
//   //                 </span>
//   //               </div>

//   //               <div
//   //                 style={{ width: '80%', marginTop: '150px' }}
//   //                 className={classes.blockboxinfo}
//   //               >
//   //                 <span className={classes.groupHead}>
//   //                   <small className={classes.numb}>2</small> Submit A Request
//   //                 </span>
//   //                 <span className={classes.groupDetail}>
//   //                   Fill out the educational support request form for your
//   //                   specific needs.
//   //                 </span>

//   //                 <br />
//   //               </div>

//   //               <div
//   //                 style={{ width: '80%', marginTop: '34%' }}
//   //                 className={classes.blockboxMb}
//   //               >
//   //                 <span className={classes.groupHead}>
//   //                   <small className={classes.numb}>3</small> Make A Pledge
//   //                 </span>
//   //                 <span className={classes.groupDetail}>
//   //                   You must make a pledge to volunteer your time or to become a
//   //                   future sponsor.
//   //                 </span>
//   //                 <br />
//   //               </div>
//   //               <div
//   //                 style={{ width: '80%', marginTop: '34%' }}
//   //                 className={classes.blockboxMb}
//   //               >
//   //                 <span className={classes.groupHead}>
//   //                   <small className={classes.numb}>4</small> Receive
//   //                   Sponsorship And Fulfill Your Commitment
//   //                 </span>
//   //                 <span className={classes.groupDetail}>
//   //                   Receive educational support from your sponsor. After that,
//   //                   it’s time to fulfill your pledge!
//   //                 </span>
//   //                 <br />
//   //               </div>
//   //             </Grid>
//   //           </React.Fragment>
//   //         </Grid>
//   //         <React.Fragment>
//   //           <Grid item xs={12} style={{ textAlign: 'center' }}>
//   //             <Button
//   //               style={{
//   //                 backgroundColor: '#232323',
//   //                 color: '#f2f2f2',
//   //                 marginTop: '30px',
//   //                 padding: '8px 25px',
//   //                 fontSize: '16px',
//   //                 '&:hover': {
//   //                   background: '#232323',
//   //                 },
//   //               }}
//   //               onClick={() => {
//   //                 props.setPage('auth');
//   //               }}
//   //             >
//   //               Get Started
//   //             </Button>
//   //           </Grid>
//   //         </React.Fragment>
//   //       </Container>
//   //       {/* <div className={classes.linepattern}></div> */}
//   //     </Grid>
//   //   );
//   // };

// <Student/>

//   const [state, setState] = React.useState({
//     checkedStudent: true,
//     checkedSponsor: false,
//     checkedOrganisation: false,
//   });

//   const handleChange = event => {
//     if (event.target.name === 'checkedStudent')
//       setState({
//         ...state,
//         checkedStudent: event.target.checked,
//         checkedSponsor: !event.target.checked,
//         checkedOrganisation: !event.target.checked,
//       });

//     if (event.target.name === 'checkedSponsor')
//       setState({
//         ...state,
//         checkedStudent: !event.target.checked,
//         checkedSponsor: event.target.checked,
//         checkedOrganisation: !event.target.checked,
//       });

//     if (event.target.name === 'checkedOrganisation')
//       setState({
//         ...state,
//         checkedStudent: !event.target.checked,
//         checkedSponsor: !event.target.checked,
//         checkedOrganisation: event.target.checked,
//       });
//   };

//   const classes = useStyles();
//   return (
//     <>
//       <CssBaseline />
//       <main>
//         <Grid container className={classes.maincontent}>
//           <CardMedia
//             component="img"
//             className={classes.maskGroup1}
//             image={maskgroup2}
//             title="Live from space album cover"
//           />
//           <div className={classes.cBanner}>
//             <Container className={classes.customcontainer}>
//               <div
//                 className={classes.cBannerIndex}
//                 style={{ position: 'relative', zIndex: '1' }}
//               >
//                 <h1 className={classes.cBannerH1}>
//                   How Sponsor A Student Works{' '}
//                 </h1>

//                 <div className={classes.cBannerDetail}>
//                   <FormGroup>
//                     <FormControlLabel
//                       control={
//                         <IOSSwitch
//                           checked={state.checkedStudent}
//                           onChange={handleChange}
//                           name="checkedStudent"
//                         />
//                       }
//                       label="I am a Student"
//                     />
//                     <FormControlLabel
//                       control={
//                         <IOSSwitch
//                           checked={state.checkedSponsor}
//                           onChange={handleChange}
//                           name="checkedSponsor"
//                         />
//                       }
//                       label="I am a Sponsor"
//                     />
//                     <FormControlLabel
//                       control={
//                         <IOSSwitch
//                           checked={state.checkedOrganisation}
//                           onChange={handleChange}
//                           name="checkedOrganisation"
//                         />
//                       }
//                       label="I am an Organisation"
//                     />
//                   </FormGroup>
//                 </div>
//               </div>
//             </Container>
//           </div>
//           <CardMedia
//             component="img"
//             className={classes.maskGroup2}
//             image={maskgroup1}
//             title="Live from space album cover"
//           />
//         </Grid>
//         <div
//           className={classes.linepattern}
//           style={{
//             transform: 'scaleX(-1)',
//             marginTop: '0px',
//           }}
//         ></div>
//         <div className={classes.cApplication}>
//           <div className={classes.spacing}>
//             {}
//             {state.checkedStudent ? <Student setPage={props.setPage} /> : null}
//             {state.checkedSponsor ? <Sponser setPage={props.setPage} /> : null}
//             {state.checkedOrganisation ? (
//               <Organisation setPage={props.setPage} />
//             ) : null}
//           </div>
//         </div>
//       </main>
//     </>
//   );
// }

// // const Organisation = props => {
// //   const classes = useStyles();

// //   return (
// //     // <Grid xs={12}>
// //     //   <Container className={classes.customcontainer}>
// //     //     <Grid
// //     //       container
// //     //       item
// //     //       xs={12}
// //     //       justifyContent="center"
// //     //       alignItems="center"
// //     //       spacing={3}
// //     //     >
// //     //       <React.Fragment>
// //     //         <Grid item xs={12} style={{ textAlign: 'center' }}>
// //     //           <h1 className={classes.groupTopHeadOr}> For Organisation</h1>
// //     //         </Grid>
// //     //       </React.Fragment>
// //     //     </Grid>
// //     //   </Container>
// //     //   <Container className={classes.customcontainer}>
// //     //     <Grid container item xs={12} spacing={3}>
// //     //       <React.Fragment>
// //     //         <Grid
// //     //           item
// //     //           xs={12}
// //     //           sm={12}
// //     //           md={4}
// //     //           className={classes.blockboximageMb}
// //     //         >
// //     //           <CardMedia
// //     //             component="img"
// //     //             className={classes.centerlogo}
// //     //             image={org}
// //     //             title="Live from space album cover"
// //     //           />
// //     //         </Grid>
// //     //         <Grid item sm={12} md={4} className={classes.blockbox}>
// //     //           <div
// //     //             style={{ width: '80%', marginTop: '147px' }}
// //     //             className={classes.blockboxinfo}
// //     //           >
// //     //             <span className={classes.groupHeadOr}>
// //     //               <small className={classes.numb}>1</small> Create Your
// //     //               Organisation Profile
// //     //             </span>
// //     //             <span className={classes.groupDetailOr}>
// //     //               Register on the site.
// //     //             </span>
// //     //           </div>
// //     //         </Grid>
// //     //         <Grid
// //     //           item
// //     //           xs={12}
// //     //           sm={12}
// //     //           md={4}
// //     //           className={classes.blockboximageDesk}
// //     //         >
// //     //           <CardMedia
// //     //             component="img"
// //     //             className={classes.centerlogo}
// //     //             image={org}
// //     //             title="Live from space album cover"
// //     //           />
// //     //         </Grid>
// //     //         <Grid item sm={12} md={4} className={classes.blockbox}>
// //     //           <div
// //     //             style={{ width: '80%', float: 'right', marginTop: '40px' }}
// //     //             className={classes.blockboxinfo}
// //     //           >
// //     //             <span className={classes.groupHeadOr}>
// //     //               <small className={classes.numb}>2</small> Refer Students
// //     //             </span>
// //     //             <span className={classes.groupDetailSe}>
// //     //               Provide the reference access code for students who apply
// //     //               through your organisation.
// //     //             </span>
// //     //           </div>
// //     //           <div
// //     //             style={{ width: '80%', float: 'right', marginTop: '34%' }}
// //     //             className={classes.blockboxinfo}
// //     //           >
// //     //             <span className={classes.groupHeadOr}>
// //     //               <small className={classes.numb}>3</small> View The Reports
// //     //             </span>
// //     //             <span className={classes.groupDetailSe}>
// //     //               View the reports on the number of requests raised by students
// //     //               referred by your organisation and and how many requests were
// //     //               supported.
// //     //             </span>
// //     //           </div>
// //     //         </Grid>
// //     //       </React.Fragment>
// //     //     </Grid>
// //     //     <React.Fragment>
// //     //       <Grid item xs={12} style={{ textAlign: 'center' }}>
// //     //         <Button
// //     //           style={{
// //     //             backgroundColor: '#232323',
// //     //             color: '#f2f2f2',
// //     //             marginTop: '30px',
// //     //             padding: '8px 25px',
// //     //             fontSize: '16px',
// //     //             '&:hover': {
// //     //               background: '#232323',
// //     //             },
// //     //           }}
// //     //           onClick={() => {
// //     //             props.setPage('auth');
// //     //           }}
// //     //         >
// //     //           Get Started
// //     //         </Button>
// //     //       </Grid>
// //     //     </React.Fragment>
// //     //   </Container>
// //     //   {/* <div className={classes.linepattern}></div> */}
// //     // </Grid>
// //     <Organisation/>
// //   );
// // };

// // const Sponsor = props => {
// //   const classes = useStyles();

// //   return (
// //     // <Grid xs={12}>
// //     //   <Container className={classes.customcontainer}>
// //     //     <Grid
// //     //       container
// //     //       item
// //     //       xs={12}
// //     //       justifyContent="center"
// //     //       alignItems="center"
// //     //       spacing={3}
// //     //     >
// //     //       <React.Fragment>
// //     //         <Grid item xs={12} style={{ textAlign: 'center' }}>
// //     //           <h1 className={classes.groupTopHeadSe}> For Sponsors</h1>
// //     //         </Grid>
// //     //       </React.Fragment>
// //     //     </Grid>
// //     //   </Container>
// //     //   <Container className={classes.customcontainer}>
// //     //     <Grid container item xs={12} spacing={3}>
// //     //       <React.Fragment>
// //     //         <Grid
// //     //           item
// //     //           xs={12}
// //     //           sm={12}
// //     //           md={4}
// //     //           className={classes.blockboximageMb}
// //     //         >
// //     //           <CardMedia
// //     //             component="img"
// //     //             className={classes.centerlogostu}
// //     //             image={spon}
// //     //             title="Live from space album cover"
// //     //           />
// //     //         </Grid>
// //     //         <Grid item sm={12} md={4} className={classes.blockbox}>
// //     //           <div
// //     //             style={{ width: '80%', marginTop: '147px' }}
// //     //             className={classes.blockboxinfo}
// //     //           >
// //     //             <span className={classes.groupHeadSe}>
// //     //               <small className={classes.numb}>3</small> Sponsor The Student
// //     //             </span>
// //     //             <span className={classes.groupDetailSe}>
// //     //               With our unique direct-to- payment model, you can pay to the
// //     //               educational or commercial institute to fulfil the student
// //     //               need.
// //     //             </span>
// //     //             <br />
// //     //           </div>
// //     //         </Grid>
// //     //         <Grid
// //     //           item
// //     //           xs={12}
// //     //           sm={12}
// //     //           md={4}
// //     //           className={classes.blockboximageDesk}
// //     //         >
// //     //           <CardMedia
// //     //             component="img"
// //     //             className={classes.centerlogostu}
// //     //             image={spon}
// //     //             title="Live from space album cover"
// //     //           />
// //     //         </Grid>
// //     //         <Grid item sm={12} md={4} className={classes.blockbox}>
// //     //           <div
// //     //             style={{ width: '80%', float: 'right', marginTop: '40px' }}
// //     //             className={classes.blockboxinfo}
// //     //           >
// //     //             <span className={classes.groupHeadSe}>
// //     //               <small className={classes.numb}>1</small> Create Your Profile
// //     //             </span>
// //     //             <span className={classes.groupDetailSe}>
// //     //               Register on the site and set your sponsorship preferences..
// //     //             </span>
// //     //           </div>
// //     //           <div
// //     //             style={{ width: '80%', float: 'right', marginTop: '34%' }}
// //     //             className={classes.blockboxinfo}
// //     //           >
// //     //             <span className={classes.groupHeadSe}>
// //     //               <small className={classes.numb}>2</small> Receive A
// //     //               Notification
// //     //             </span>
// //     //             <span className={classes.groupDetailSe}>
// //     //               Based on your preference a student request will be sent to
// //     //               you.
// //     //             </span>

// //     //             <br />
// //     //           </div>
// //     //         </Grid>
// //     //       </React.Fragment>
// //     //     </Grid>
// //     //     <React.Fragment>
// //     //       <Grid item xs={12} style={{ textAlign: 'center' }}>
// //     //         <Button
// //     //           style={{
// //     //             backgroundColor: '#232323',
// //     //             color: '#f2f2f2',
// //     //             marginTop: '30px',
// //     //             padding: '8px 25px',
// //     //             fontSize: '16px',
// //     //             '&:hover': {
// //     //               background: '#232323',
// //     //             },
// //     //           }}
// //     //           onClick={() => {
// //     //             props.setPage('auth');
// //     //           }}
// //     //         >
// //     //           Get Started
// //     //         </Button>
// //     //       </Grid>
// //     //     </React.Fragment>
// //     //   </Container>
// //     //   {/* <div className={classes.linepattern}></div> */}
// //     // </Grid>
// //     <Sponser/>
// //   );
// // };


import React from 'react'
import Instruction from './HowItWorks/Instruction'

const StudentInstruction = (props) => {
  return (
    <div className='bannner-image'>
      <Instruction  setPage={props.setPage}/>
    </div>
  )
}

export default StudentInstruction