import firebase from 'firebase/app';
import 'firebase/auth';

const checkAuth = async () => {
  const user = firebase.auth().currentUser;
  if (user) {
    const idToken = await user.getIdToken();
    const decodedToken = await user.getIdTokenResult();
    return { idToken, uid: decodedToken.claims.sub };
  }
  return null;
};

export default checkAuth;
