import React from 'react';
import { CardsTooltip } from '../utils/MyTooltip';
import donate from '../assets/donatenow.gif';

const Cards = props => {
  return (
    <>
      {CardsTooltip(
        props.message,
        props.position,
        <div
          className={`main_card`}
          onClick={props.onClick}
          style={{ position: 'relative' }}
        >
          <img
            src={
              props.number === 'Donate Now' && props.newRequest
                ? donate
                : props.img
            }
            alt="imgIcon"
            width={60}
          />
          <div>
            <h4>
              <b>{props.number}</b>
            </h4>
            <p>{props.title}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Cards;
