// Functionality of this code : This page contains the Header section
// Input : None
// Output : Home Page -> Header
// Written by: Initial team VK, KR

import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Link,
  MenuItem,
  Menu,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState, useEffect, useRef } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUserPreferences,
  resetUser,
} from '../../store/actions/userActions';
import { setActiveTab } from '../../store/actions/tabaction';
import '../../css/button.css';
import { getInitials } from '../../utils/GetNameInitials';
import Notifications from '../Notifications';

import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import io from 'socket.io-client';
import toast from 'react-hot-toast';
import { setUserNotification } from '../../store/actions/notificationAction';
import { timeAgo } from '../../utils/timeago';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  header: {
    backgroundColor: '#fff',
    height: '90px',
  },
  logo: {
    fontFamily: 'Calibri',
    fontWeight: 600,
    color: '#FFFEFE',
    textAlign: 'left',
  },
  menuButton: {
    fontFamily: 'Calibri',
    fontWeight: 700,
    size: '18px',
    marginLeft: '38px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    maxWidth: '1365px',
    margin: '0px auto',
    width: '100%',
  },
  drawerContainer: {
    padding: '20px 30px',
  },
  logoHead: {
    width: '100px',
    height: 'auto',
    left: '0px',
    top: '5px',
    position: 'absolute',
    '@media (max-width: 767px)': {
      width: '70px',
      left: '5px',
    },
  },
  iconView: {
    left: '60px',
    marginTop: '25px',
    color: '#ed0a3f',
    backgroundColor: '#ffdcd3',
    borderRadius: 10,
    padding: 4,
    '@media (max-width: 991px)': {
      fontSize: '35px',
    },
  },
  toolbarMob: {
    '@media (max-width: 991px)': {
      justifyContent: 'flex-end',
      paddingLeft: '68%',
    },
  },
  headButton: {
    color: '#4b4b4b',
    fontFamily: 'Railey,sans-serif',
    fontSize: '15px',
    margin: '33px 30px',
    '&:hover': {
      // backgroundColor: '#fff !important',
    },
  },

  headButtonMobo: {
    color: '#4b4b4b',
    fontFamily: 'Railey,sans-serif',
    fontSize: '15px',
    padding: '8px 0px',
    borderRadius: '5px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '10px',
    fontSize: '15px',
  },
  headLoginButton: {
    backgroundColor: '#232323',
    color: 'white',
    fontSize: '16px',
    fontWeight: '700',
    padding: '6px 27px',
    marginLeft: '30px',
  },
  // Define your activeButton style here
  activeButton: {
    color: 'white',
    background: '#af3636 !important',
  },
  activeMoboButton: {
    color: '#af3636',
  },
  profileWrapper: {
    marginLeft: '20px',
    '@media (max-width: 991px)': {
      marginLeft: '10px',
    },
  },
  avatar: {
    marginRight: '10px',
  },
  profileName: {
    fontSize: '16px',
    fontWeight: '600',
  },
  
}));

//Header responsive
export default function Header({
  isAuthenticated,
  logOut,
  logoSrc,
  setPage,
  firebase,
  setNewOpen,
  setContact,
  role,
  setRole,
  page,
}) {
  const {
    header,
    toolbar,
    drawerContainer,
    logoHead,
    iconView,
    headButton,
    headButtonMobo,
    headLoginButton,
    toolbarMob,
    activeButton,
    activeMoboButton,
    profileWrapper,
    avatar,
    profileName,
  } = useStyles();

  const dispatch = useDispatch();
  const username = useSelector(state => state.user.name);
  const activeTab = useSelector(state => state.tab.activeTab);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const socket = useRef(null);
  //set state for mobile & desktop
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  // State for dropdown menu
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const [showDropdown, setShowDropdown] = useState(false);
  const [newNotificationCount, setNewNotificationCount] = useState(0);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const { REACT_APP_PROXY } = process.env;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState(prevState => ({ ...prevState, mobileView: true }))
        : setState(prevState => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);

  // ------------- Mark all notification read ----------------
  const markAllAsRead = async () => {
    try {
      const token = await firebase.auth().currentUser.getIdToken();
      const userId = firebase.auth().currentUser.uid;

      const response = await fetch(
        `${process.env.REACT_APP_PROXY}/user/notifications/read`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            auth: token,
          },
          body: JSON.stringify({ uid: userId }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to mark all notifications as read');
      }

      // After marking all as read, update the local notifications state
      setNotifications(prevNotifications =>
        prevNotifications.map(notif => ({
          ...notif,
          is_read: true,
        }))
      );
      setNewNotificationCount(0);
    } catch (err) {
      console.error(err);
      toast.error('Error marking all notifications as read');
    }
  };

  // ------------ Mark a single notification read --------------
  const markAsRead = async notificationId => {
    try {
      const token = await firebase.auth().currentUser.getIdToken();

      const response = await fetch(
        `${process.env.REACT_APP_PROXY}/user/notifications/${notificationId}/read`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            auth: token,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to mark notification as read');
      }

      // Update the state to mark this notification as read
      setNotifications(prevNotifications =>
        prevNotifications.map(notif =>
          notif.id === notificationId ? { ...notif, is_read: true } : notif
        )
      );
      setNewNotificationCount(count => count - 1);
    } catch (err) {
      console.error(err);
      toast.error('Error marking notification as read');
    }
  };

  // Fetch existing notifications when component loads
  const fetchNotifications = async (idToken, uid) => {
    try {
      const response = await fetch(
        `${REACT_APP_PROXY}/user/notifications/${uid}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            auth: idToken,
          },
        }
      );

      // Check if the response is not ok before parsing the JSON
      if (!response.ok) {
        throw new Error('Failed to fetch notifications');
        
      }

      // Parse the JSON data
      const data = await response.json();
      // Transform notifications by applying timeAgo to created_at
      const transformedNotifications = data.notification.map(notif => ({
        ...notif,
        created_at: timeAgo(notif.created_at), 
      }));
      // Set notifications after parsing the response
      setNotifications(transformedNotifications);
      setNewNotificationCount(data.count);
    } catch (err) {
      setError(err.message);
      // toast.error('Error fetching notifications');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log('Updated Notifications state:', notifications);
    dispatch(setUserNotification(notifications));
  }, [notifications]);

  useEffect(() => {
    const handleAuthStateChanged = async user => {
      if (user) {
        try {
          const tokenResult = await firebase
            .auth()
            .currentUser.getIdTokenResult();
          const token = tokenResult.token;
          const userId = tokenResult.claims.user_id;

          // Fetch user preferences
          const response = await fetch(
            `${process.env.REACT_APP_PROXY}/user/pref`,
            {
              method: 'GET',
              headers: { 'Content-Type': 'application/json', auth: token },
            }
          );

          const data = await response.json();

          if (data.success) {
            dispatch(fetchUserPreferences(data.message[0]));
          }

          // Initialize socket connection only if not already initialized
          if (!socket.current) {
            socket.current = io(REACT_APP_PROXY);
          }

          // Register user for socket notifications
          socket.current.emit('register', userId);

          // Listen for new notifications from the Socket.IO server
          socket.current.on('notification', notification => {
            setNewNotificationCount(prevCount => prevCount + 1);
            setNotifications(prevNotifications => [
              ...prevNotifications,
              notification,
            ]);
            // Fetch the latest notifications from the server
            fetchNotifications(token, userId);
          });

          // Initial fetch of notifications
          fetchNotifications(token, userId);
        } catch (error) {
          console.error('Error fetching user preferences:', error);
        }
      }
    };

    // Assume handleAuthStateChanged is triggered when auth state changes
    firebase.auth().onAuthStateChanged(handleAuthStateChanged);

    return () => {
      // Cleanup socket when component is unmounted
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    // Update activeTab whenever isAuthenticated changes
    dispatch(setActiveTab(isAuthenticated ? 'dashboard' : 'home'));
  }, [isAuthenticated]);

  //display desktop
  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {femmecubatorLogo}
        <div>{getMenuButtons()}</div>
      </Toolbar>
    );
  };

  //menu popup for small device
  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState(prevState => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState(prevState => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={toolbarMob}>
        {isAuthenticated && username && (
          <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className='avatarmobile'
          >
            <Avatar
              alt="Profile Picture"
              style={{
                backgroundColor: '#2998D1',
                color: '#fff',
                width: 40,
                height: 40,
                fontSize: '1.2rem',
                marginBottom: '5px',
              }}
            >
              {getInitials(username)}
            </Avatar>

            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem
                        style={{ textTransform: 'none' }}
                      >
                        <div>
                          <span
                            style={{
                              fontSize: '1rem',
                              fontWeight: 'bold',
                              textTransform: 'none',
                            }}
                            className="px-2"
                          >
                            {username}
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: '0.8rem',
                              fontStyle: 'italic',
                              color: 'gray',
                            }}
                          >
                            ({role})
                          </span>
                        </div>
                      </MenuItem>
                      <MenuItem
                        style={{ textTransform: 'none' }}
                        onClick={() => {
                          setNewOpen(true);
                          handleMenuClose();
                        }}
                      >
                        Profile
                      </MenuItem>
                      <MenuItem
                        style={{ textTransform: 'none' }}
                        onClick={() => {
                          logOut();
                          dispatch(resetUser());
                          handleMenuClose();
                          setRole('');
                          setPage('home');
                        }}
                      >
                        Logout
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Button>
        )}

        {isAuthenticated && username && (
          <>
            <Button onClick={toggleDropdown} style={{ position: 'relative' }}>
              {/* <i className="fa-solid fa-bell fs-5"></i> */}
              <img
                src="https://cdn-icons-png.flaticon.com/128/10502/10502974.png"
                alt="imgIcon"
                width={25}
                className='notif-Icon'
              />
              {newNotificationCount > 0 && (
                <span className="notification-badge">
                  {newNotificationCount}
                </span>
              )}
            </Button>
            <span>
              {showDropdown && (
                <Notifications
                  notifications={notifications}
                  setShowDropdown={setShowDropdown}
                  role={role}
                  markAllAsRead={markAllAsRead}
                  newNotificationCount={newNotificationCount}
                  markAsRead={markAsRead}
                  setPage={setPage}
                  setActiveTab={setActiveTab}
                />
              )}
            </span>
          </>
        )}
        <IconButton
          {...{
            edge: 'start',
            color: 'inherit',
            'aria-label': 'menu',
            'aria-haspopup': 'true',
            onClick: handleDrawerOpen,
          }}
          className={iconView}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: 'left',
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>
        <div>{femmecubatorLogo}</div>
      </Toolbar>
    );
  };

  const handleSetPage = page => {
    setPage(page);
    dispatch(setActiveTab(page));
  };

  //menu popup for desktop
  const getDrawerChoices = () => {
    return (
      <>
        {!isAuthenticated && (
          <div
            size="large"
            onClick={() => handleSetPage('home')}
            className={`${headButtonMobo} ${
              activeTab === 'home' ? activeButton : ''
            }`}
          >
            HOME
          </div>
        )}
        <div
          className={`${headButtonMobo} ${
            activeTab === 'page' || activeTab === 'about' ? activeButton : ''
          }`}
          onClick={() => handleSetPage(isAuthenticated ? 'page' : 'about')}
          size="large"
        >
          {role !== 'admin' && isAuthenticated && username
            ? role === 'student'
              ? 'APPLICATION'
              : 'REQUESTS'
            : !isAuthenticated && 'ABOUT US'}
        </div>

        {role !== 'admin' && isAuthenticated && username && (
          <div
            size="large"
            className={`${headButtonMobo} ${
              activeTab === 'dashboard' ? activeButton : ''
            }`}
            onClick={() => handleSetPage('dashboard')}
          >
            {`${role.toUpperCase()} DASHBOARD`}
          </div>
        )}
        {!isAuthenticated && (
          <div
            className={`${headButtonMobo} ${
              activeTab === 'howitworks' ? activeButton : ''
            }`}
            size="large"
            onClick={() => handleSetPage('howitworks')}
          >
            HOW IT WORKS
          </div>
        )}
        {!isAuthenticated && (
          <div
            className={headButtonMobo}
            size="large"
            onClick={() => setContact(true)}
          >
            CONTACT
          </div>
        )}

        {/* <Link
          style={{ color: '#2998D1', minHeight: '18px' }}
          onClick={() => {
            if (isAuthenticated) {
              logOut();
              setPage('home');
            } else setPage('auth');
          }}
          href="#"
        >
          <MenuItem>{isAuthenticated ? 'SIGN OUT' : 'SIGN IN'} </MenuItem>
        </Link> */}

        {role !== 'admin' && !isAuthenticated && (
          <div
            onClick={() => handleSetPage('auth')}
            href="#"
            className={`${headButtonMobo} ${
              activeTab === 'auth' ? activeButton : ''
            }`}
          >
            SIGN IN
          </div>
        )}

        {role !== 'admin' && isAuthenticated && !username && (
          <Button
            onClick={() => {
              logOut();
              dispatch(resetUser());
              handleMenuClose();
              setRole('');
              setPage('home');
            }}
            href="#"
            className={headLoginButton}
          >
            SIGN OUT
          </Button>
        )}

        {role && role.toUpperCase && isAuthenticated && !username && (
          <button className="button mb-2">{`${role.toUpperCase()} PREFERENCE PAGE`}</button>
        )}
      </>
    );
  };

  const femmecubatorLogo = (
    <Avatar alt="Logo" variant="square" src={logoSrc} className={logoHead} />
  );

  const getMenuButtons = () => {
    return (
      <div>
        {!isAuthenticated && (
          <Button
            className={`${headButton} ${
              activeTab === 'home' ? activeButton : ''
            }`}
            color="inherit"
            size="large"
            onClick={() => handleSetPage('home')}
          >
            HOME
          </Button>
        )}

        {role !== 'admin' && isAuthenticated && username && (
          <Button
            className={`${headButton} ${
              activeTab === 'dashboard' ? activeButton : ''
            }`}
            color="inherit"
            size="large"
            onClick={() => handleSetPage('dashboard')}
          >
            {`${role} DASHBOARD`}
          </Button>
        )}

        {role !== 'admin' ? (
          <Button
            color="inherit"
            className={`${headButton} ${
              activeTab === 'page' || activeTab === 'about' ? activeButton : ''
            }`}
            onClick={() => handleSetPage(isAuthenticated ? 'page' : 'about')}
            size="large"
          >
            {role !== 'admin' && isAuthenticated && username
              ? role === 'student'
                ? 'APPLICATION'
                : 'REQUESTS'
              : !isAuthenticated && 'ABOUT US'}
          </Button>
        ) : (
          ''
        )}

        {!isAuthenticated && (
          <Button
            className={`${headButton} ${
              activeTab === 'howitworks' ? activeButton : ''
            }`}
            color="inherit"
            size="large"
            onClick={() => handleSetPage('howitworks')}
          >
            HOW IT WORKS
          </Button>
        )}
        {!isAuthenticated && (
          <Button
            className={headButton}
            color="inherit"
            size="large"
            onClick={() => setContact(true)}
          >
            CONTACT
          </Button>
        )}

        {isAuthenticated && username && <span></span>}

        {isAuthenticated && username && (
          <span>
            <Button
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <Avatar
                alt="Profile Picture"
                style={{
                  backgroundColor: '#2998D1',
                  color: '#fff',
                  width: 40,
                  height: 40,
                  fontSize: '1.2rem',
                  marginBottom: '5px',
                }}
              >
                {getInitials(username)}
              </Avatar>

              <div>
                <span
                  style={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    textTransform: 'none',
                  }}
                  className="px-2"
                >
                  {username}
                </span>
                <br />
                <span
                  style={{
                    fontSize: '0.8rem',
                    fontStyle: 'italic',
                    color: 'gray',
                  }}
                >
                  ({role})
                </span>
              </div>

              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem
                          style={{ textTransform: 'none' }}
                          onClick={() => {
                            setNewOpen(true);
                            handleMenuClose();
                          }}
                        >
                          Profile
                        </MenuItem>
                        <MenuItem
                          style={{ textTransform: 'none' }}
                          onClick={() => {
                            logOut();
                            dispatch(resetUser());
                            handleMenuClose();
                            setRole('');
                            setPage('home');
                          }}
                        >
                          Logout
                        </MenuItem>
                      </MenuList>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Button>
          </span>
        )}

        {isAuthenticated && username && (
          <>
            <Button onClick={toggleDropdown} style={{ position: 'relative' }}>
              {/* <i className="fa-solid fa-bell fs-5"></i> */}
              <img
                src="https://cdn-icons-png.flaticon.com/128/10502/10502974.png"
                alt="imgIcon"
                width={25}
              />
              {newNotificationCount > 0 && (
                <span className="notification-badge">
                  {newNotificationCount}
                </span>
              )}
            </Button>
            <span>
              {showDropdown && (
                <Notifications
                  notifications={notifications}
                  setShowDropdown={setShowDropdown}
                  role={role}
                  markAllAsRead={markAllAsRead}
                  newNotificationCount={newNotificationCount}
                  markAsRead={markAsRead}
                  setPage={setPage}
                  setActiveTab={setActiveTab}
                />
              )}
            </span>
          </>
        )}

        {role !== 'admin' && !isAuthenticated && (
          <Button
            onClick={() => handleSetPage('auth')}
            href="#"
            className={`${headLoginButton} ${
              activeTab === 'auth' ? activeButton : ''
            }`}
          >
            SIGN IN
          </Button>
        )}
        {role !== 'admin' && isAuthenticated && !username && (
          <Button
            onClick={() => {
              logOut();
              dispatch(resetUser());
              handleMenuClose();
              setRole('');
              setPage('home');
            }}
            href="#"
            className={headLoginButton}
          >
            SIGN OUT
          </Button>
        )}

        {role && role.toUpperCase && isAuthenticated && !username && (
          <button className="button mb-2">{`${role.toUpperCase()} PREFERENCE PAGE`}</button>
        )}
      </div>
    );
  };

  //render the heared as per device size
  return (
    <header>
      <AppBar className={header}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>
  );
}
