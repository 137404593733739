import React from 'react';
import './style.css';
import studentImg from '../../assets/newui/student.png';

const Student = props => {
  return (
    <div>
      <div className="tabs-content">
        <h3>For Students</h3>
        <div className="text-img-box">
          <div className="left-box student-details-box">
            <div className="student-position-box">
              <div className="com-student-details">
                <div className="text-box">
                  <h4>Receive sponsorship and fulfill your commitment</h4>
                  <p>Receive educational support from your sponsor.</p>
                </div>
                <div className="counter-box">
                  <span>4</span>
                </div>
              </div>
              <div className="com-student-details">
                <div className="text-box">
                  <h4>Make a pledge</h4>
                  <p>
                    You must pledge to volunteer your time or become a future
                    sponsor.
                  </p>
                </div>
                <div className="counter-box">
                  <span>3</span>
                </div>
              </div>
            </div>
          </div>
          <div className="center-box student-details-box">
            <div className="img-box">
              <img src={studentImg} alt="" />
            </div>
          </div>
          <div className="right-box student-details-box">
            <div className="student-position-box">
              <div className="com-student-details">
                <div className="counter-box">
                  <span>1</span>
                </div>
                <div className="text-box">
                  <h4>Create your profile</h4>
                  <p>Register on the site and create your profile.</p>
                </div>
              </div>
              <div className="com-student-details">
                <div className="counter-box">
                  <span>2</span>
                </div>
                <div className="text-box">
                  <h4>Submit a request</h4>
                  <h4>
                    <p>
                      Support request for your education and specific needs.
                    </p>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-box">
          <a
            style={{ background: '#77000D' }}
            onClick={() => {
              props.setPage('auth');
            }}
            className="btn btn-primary"
          >
            Get Started
          </a>
        </div>
      </div>
    </div>
  );
};

export default Student;
