

// Functionality of this code : This page contains the widgets on the AboutUs Page
// Input : None
// Output : Home Page -> About Us Page
// Written by: Initial team VK, KR

import React,{useEffect} from 'react';
// import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
// import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
// import CardMedia from '@material-ui/core/CardMedia';
// import poster51 from './assets/poster51.png';
// import bars from './assets/new/bars.png';
import sujatha from './assets/sujatha1.jpeg';
// import home2 from './assets/home2.jpg';
import home2 from './assets/newui/3.png';
// import layer from './assets/banner.png';
// import { yellow } from '@material-ui/core/colors';
import VideoSection from './components/VideoSection';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
  },

  cardDetails: {
    flex: 1,
  },

  cardMedia: {
    width: '350px',
    margin: '0 auto',
    '@media (max-width: 767px)': {
      width: '280px',
    },
  },

  cardMediaTeam: {
    width: '170px',
    borderRadius: '50%',
    margin: '0 auto',
    '@media (max-width: 767px)': {
      width: '280px',
    },
  },

  groupTopHeadOr: {
    backgroundColor: 'transparent',
    color: '#ed0a3f',
    fontStyle: 'normal',
    fontWeight: 700,
    height: 'auto',
    lineHeight: 'normal',
    textAlign: 'center',
    width: 'auto',
    fontSize: '26px',
    marginTop: '0',
    marginBottom: '45px',
    '@media (max-width: 767px)': {
      fontSize: '22px',
      marginBottom: '20px',
    },
  },

  media: {
    height: 0,
    paddingTop: '56.25%',
  },

  card: {
    position: 'relative',
  },

  overlay: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    color: 'black',
    backgroundColor: 'white',
  },

  spacing: {
    padding: '60px 0',
    marginTop: '-50px',
    '@media (max-width: 900px)': {
      padding: '50px 0',
    },
    '@media (max-width: 767px)': {
      padding: '30px 0',
      marginTop: ' 0px',
    },
  },

  spacing1: {
    padding: '60px 0',
    '@media (max-width: 900px)': {
      padding: '50px 0',
    },
    '@media (max-width: 767px)': {
      padding: '30px 0',
    },
  },

  para: {
    fontSize: '16px',
    lineHeight: '24px',
    '@media (max-width: 767px)': {
      fontSize: '16px',
      lineHeight: '22px',
    },
  },

  bgcolor: {
    background: '#f2f2f2',
  },

  linepattern: {
    background: 'linear-gradient(90deg, #232323 23.43%, #ffffff 80.81%)',
    backgroundColor: 'transparent',
    height: '4px',
    right: '0px',
    position: 'absolute',
    width: 'calc(100%)',
    marginTop: '50px',
    '@media (max-width: 900px)': {
      marginTop: '50px',
    },
    '@media (max-width: 767px)': {
      marginTop: '20px',
    },
  },

  customcontainer: {
    maxWidth: '1365px',
  },

  container: {
    maxWidth: '1365px',
    marginTop: '110px',
    '@media (max-width: 767px)': {
      marginTop: '60px',
    },
  },

  maskGroup: {
    margin: '0px auto',
  },

  aboutinfo: {
    marginTop: '50px',
    padding: '50px 30px',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  },

  barimage: {
    width: 'auto',
    margin: '0 auto',
  },

  aboutblockinfo: {
    padding: '0 20px',
  },

  aboutnum: {
    width: '80px',
    height: '80px',
    display: 'block',
    borderRadius: '50%',
    background: '#F2F2F2',
    textAlign: 'center',
    lineHeight: '76px',
    color: '#F4113D',
    fontSize: '35px',
    fontWeight: 'bold',
    '@media (max-width: 900px)': {
      width: '60px',
      height: '60px',
      lineHeight: '56px',
      fontSize: '25px',
    },
  },

  aboutpara: {
    fontSize: '16px',
    lineHeight: '24px',
  },
}));

const teamMembers = [
  {
    name: 'Sujatha Prakash',
    role: 'Founder',
    image: sujatha,
    // description:
    //   'A career technologist for 25+ years, Sujatha Prakash has extensive work experience in Banking Technology. She has held global and regional technology leadership roles across in Asia Pacific, Europe and Middle East Asia, within Investment and Transaction Banking, Wealth and Retail areas. She has been an advisor to a variety of startups in the past 2.5 years. She considers this initiative a Calling through which she aspires to create significant social impact.',
    description:
    'Sujatha Prakash has a robust background in Banking Technology and held global and regional technology leadership positions. She has also acted as an advisor to multiple startups. Inspired by close family and the impact their kindness has made to numerous students, Sujatha views this initiative as her calling and aspiring to make a substantial social impact.',
  },
  // {
  //   name: 'Supriya',
  //   role: 'Co-founder',
  //   image: supriya,
  //   description:
  //     'Supriya has been in the Technology sector for close to 30 years, in the Communications and Financial industries and worked in the US, India and Singapore. She’s passionate about bringing about change and social justice. In India she founded a charitable organisation for furthering education and healthcare. She truly believes in the mission of Sponsor-a-student as being an important step in the right direction and volunteers time with them.',
  // },
  // {
  //   name: 'Prashant',
  //   role: 'Developer',
  //   image: prash,
  //   description:
  //     'Prashant has been in the Technology of full stack developer with an experience of 8+ years in web development. Knowledge in end-to-end development of software products from requirement analysis to system study, designing, testing, debugging and carried out performance tuning and maintenance.',
  // },
];

const heading  = 'The Story And Our Purpose';
const para = `Over the years, numerous requests to support education came
              the founder’s way, mostly through personal networks. A kind
              teacher reaching out for students from low-income background;
              a compassionate doctor requesting support for children of low
              income staff in her hospital. And many, many more.
               

              Every little bit of support extended seemed to make
              a difference. Success came in the form of the academic
              accomplishments and transformed lives.
              

              Can there be more of these transformations, she often
              questioned? Could the generosity she had witnessed among
              personal friends and colleagues at work be harnessed to
              support education? Can these students who benefitted give back
              to the community, by pledging to support others like
              themselves?
              

              The thought lingered on, became a calling and
              COVID-19 lent a sense of greater urgency, the result is
              sponsor-a-student!`;



//about us Page
export default function AboutUs(props) {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      {/*  ------------     =========== Mission ============Vision ============== */}
      <section className="w3l-servicesblock py-5">
        <div className="container py-lg-5 py-4">
          <div className="row pt-xl-5 pt-lg-0 pt-5 pb-xl-4 align-items-center">
            <div className="col-lg-6 position-relative home-block-3-left">
              <div className="position-relative">
                <img src={home2} alt="" className="img-fluid radius-image" width={'65%'} />
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 offset-xl-1 mt-lg-0 mt-5">
              <h3 className="title-style">Welcome to Sponsor-a-Student</h3>
              <div className="two-grids mt-md-0 mt-md-5 mt-4">
                <div className="grids_info">
                  <i className="fas fa-user-clock primary-clr-bg"></i>
                  <div className="detail">
                    <h4>Our Mission</h4>
                    <p>
                      We are on a mission to enable education sponsorship and
                      create a cycle where the beneficiary becomes a future
                      sponsor. In this unique model, students can seek support
                      and pledge to volunteer in the community or support other
                      students in future. Therefore, sponsors can support
                      students and also help build a sustainable sponsorship
                      model!
                    </p>
                  </div>
                </div>
                <div className="grids_info mt-sm-5 mt-4">
                  <i className="fas fa-laptop-house green-clr-bg"></i>
                  <div className="detail">
                    <h4>Our Vision</h4>
                    <p>
                      To be the go-to place for students to seek support for
                      small gaps in ‘Total Cost of Education’ where they can
                      also give back. To promote a feeling of dignity in seeking
                      support and pride in giving back.
                    </p>
                  </div>
                </div>
                {/* <div className="grids_info mt-sm-5 mt-4">
                  <i className="fas fa-laptop-house blue-clr-bg"></i>
                  <div className="detail">
                    <h4>Our Values</h4>
                    <p>
                      Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui.
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ================== Team ================================ */}
      {/* <Container className={classes.customcontainer}>
        <Grid container xs={12} md={12}>
          <Grid item md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
            <h1 className={classes.groupTopHeadOr}>Our Team</h1>
          </Grid>
          <Grid xs={12} container spacing={3} alignItems="center">
            <Grid item md={6} sm={12} xs={12}>
              <CardMedia
                className={classes.cardMediaTeam}
                image={sujatha}
                style={{ height: '208px', width: '190px' }}
                title="Image title"
                component="img"
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <p className={classes.para}>
                About the founder <br /> A career technologist for 25+ years,
                Sujatha Prakash has extensive work experience in Banking
                Technology. She has held global and regional technology
                leadership roles across in Asia Pacific, Europe and Middle East
                Asia, within Investment and Transaction Banking, Wealth and
                Retail areas. She has been an advisor to a variety of startups
                in the past 2.5 years. She considers this initiative a Calling
                through which she aspires to create significant social impact.
              </p>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            container
            spacing={3}
            alignItems="center"
            style={{ marginTop: '18px' }}
          >
            <Grid item md={6} sm={12}>
              <p className={classes.para}>
                Supriya has been in the Technology sector for close to 30 years,
                in the Communications and Financial industries and worked in the
                US, India and Singapore. She’s passionate about bringing about
                change and social justice. In India she founded a charitable
                organisation for furthering education and healthcare. She truly
                believes in the mission of Sponsor-a-student as an being
                important step in the right direction and volunteers time with
                them.
              </p>
            </Grid>
            <Grid item md={6} sm={12} xs={12} style={{ marginBottom: '20px' }}>
              <CardMedia
                className={classes.cardMediaTeam}
                image={supriya}
                style={{ height: '227px', width: '205px' }}
                title="Image title"
                component="img"
              />
            </Grid>
          </Grid>
          <Grid xs={12} container spacing={3} alignItems="center">
            <Grid item md={6} sm={12} xs={12}>
              <CardMedia
                className={classes.cardMediaTeam}
                image={prash}
                style={{
                  height: '227px',
                  width: '201px',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                }}
                title="Image title"
                component="img"
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <p className={classes.para}>
                Prashant has been in the Technology of full stack developer with
                an experience of 8+ years in web development. Knowledge in
                end-to-end development of software products from requirement
                analysis to system study, designing, testing, debugging and
                carried out performance tuning and maintenance.
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Container> */}

      <section className="w3l-team py-5 bannner-image mb-5" id="team" >
        <div className="container py-md-5 py-4">
          <div
            className="title-heading-w3 mx-auto text-center mb-sm-5 mb-4 pb-xl-4"
            style={{ maxWidth: 600 }}
          >
            <h1 className="title-style mb-2">About Our Founder</h1>
            {/* <p>Get to know the talented individuals behind our company.</p> */}
          </div>
          <div className="row text-center">
            {teamMembers.map((member, index) => (
              <React.Fragment key={index}>
                <div className="col-md-6 my-3 col-12">
                  <div className="team-grids text-center">
                    <img
                      src={member.image}
                      className="img-fluid"
                      alt=""
                      style={{ height: '208px', width: '190px' }}
                    />
                    <div className="team-info">
                      <div className="caption">
                        <div className="social-icons-section text-center">
                          <a className="fac" href="#url">
                            <i className="fab fa-facebook-f" />
                          </a>
                          <a className="twitter" href="#url">
                            <i className="fab fa-twitter" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h4>{member.name}</h4>
                  <h6>
                    <span
                      style={{
                        backgroundColor: '#77000d',
                        borderRadius: '20px',
                        padding: '8px 20px',
                        color: 'white',
                        fontWeight: 'bold',
                      }}
                    >
                      {member.role}
                    </span>
                  </h6>
                </div>
                <div className="col-md-6  my-3 lg:my-5 col-12">
                  <div className="about-teammate">
                    <p
                      className="para"
                      style={{ fontSize: '1.2rem', fontWeight: '200' }}
                    >
                      {member.description}
                    </p>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </section>

      {/* <Container className={classes.customcontainer}>
        <Grid container xs={12} md={12} className={classes.spacing}>
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <h1
                className={classes.groupTopHeadOr}
                style={{ marginTop: '-40px' }}
              >
                Our Team
              </h1>
              <p className={classes.para}>
                The founder is a technology professional. Through the summer of
                COVID-19, she put together a team of summer interns from global
                schools to research student needs in various geographies and
                help build a minimum viable platform for sponsor-a-student.
                <b>Advisors, volunteers and well-wishers</b> contributed to the
                concept building of sponsor-a-student! The journey has just
                begun, and we welcome any suggestions to ensure the platform
                fulfills the purpose!
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Container> */}
      {/* <div
        className={classes.linepattern}
        style={{
          marginTop: '0px',
        }}
      ></div> */}
      {/* <div className={classes.bgcolor}>
        <Container className={classes.customcontainer}>
          <Grid container xs={12} md={12} className={classes.spacing1}>
            <Grid item md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
              <h1 className={classes.groupTopHeadOr}>
                The Story And Our Purpose
              </h1>
            </Grid>
            <Grid xs={12} container spacing={3} alignItems="center">
              <Grid item md={6} sm={12} xs={12}>
                <CardMedia
                  className={classes.cardMedia}
                  image={poster51}
                  title="Image title"
                  component="img"
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <p className={classes.para}>
                  Over the years, numerous requests to support education came
                  the founder’s way, mostly through personal networks. A kind
                  teacher reaching out for students from low income background;
                  a compassionate doctor requesting support for children of low
                  income staff in her hospital. And many, many more.
                  <br></br> Every little bit of support extended seemed to make
                  a difference. Success came in the form of the academic
                  accomplishments and transformed lives.
                  <br></br>Can there be more of these transformations, she often
                  questioned? Could the generosity she had witnessed among
                  personal friends and colleagues at work be harnessed to
                  support education? Can these students who benefitted give back
                  to the community, by pledging to support others like
                  themselves?
                  <br></br>The thought lingered on, became a calling and
                  COVID-19 lent a sense of greater urgency, the result is
                  sponsor-a-student!
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div> */}

      <VideoSection  setPage={props.setPage}/>
    </>
  );
}
