import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import StudentInstruction from './components/StudentInstruction';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  formatTab: {
    textTransform: 'none',
  },
}));

export default function HowItWorks(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChangeIndex = index => {
    setValue(index);
  };

  return (
    <div>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <StudentInstruction setPage={props.setPage} />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        {/* Other components */}
      </TabPanel>
    </div>
  );
}
