import React from 'react'
import '../css/style.css'
// import home1 from '../assets/home1.jpg'
import home1 from '../assets/newui/1 (1).png'

const FeaturedPosts = (props) => {
    const post = {props};
  return (
    <div>
        {/* <section className="w3l-servicesblock py-5">
            <div className="container pb-lg-5">
                <div className="row pb-xl-5 align-items-center">
                    <div className="col-lg-6 position-relative home-block-3-left pb-lg-0 pb-5">
                        <div className="position-relative">
                            <img src={home1} alt="" className="img-fluid radius-image"/>
                        </div>
                        <div className="imginfo__box">
                            <h6 className="imginfo__title">Get Involved Today!</h6>
                            <p>Explore opportunities to make a difference in students' lives.</p>
                            <a href="contact"><span className="fa fa-envelope mr-2"></span> Contact Us</a>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 offset-xl-1 mt-lg-0 mt-5 pt-lg-0 pt-5">
                        <h3 className="title-style">Unlock Opportunities with Us</h3>
                        <p className="mt-lg-4 mt-3 mb-sm-5 mb-4">Our platform aims to bridge the gap between students and sponsors by providing a seamless and transparent sponsorship experience. With our innovative approach, we empower students to pursue their educational dreams without financial constraints. Sponsors have the opportunity to directly impact the lives of students by supporting them in their academic journey.</p>

                        <div className="two-grids mt-md-0 mt-md-5 mt-4">
                            <div className="grids_info">
                                <i className="fas fa-users primary-clr-bg"></i>
                                <div className="detail">
                                    <h4>Empower Students</h4>
                                    <p>Connect with deserving students and support them in achieving their academic goals.</p>
                                </div>
                            </div>
                            <div className="grids_info mt-5">
                                <i className="fas fa-handshake green-clr-bg"></i>
                                <div className="detail">
                                    <h4>Facilitate Sponsorships</h4>
                                    <p>Streamline the sponsorship process and ensure transparent communication between sponsors and students.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
    </div>
  )
}

export default FeaturedPosts