// Functionality of this code : This code has the sidebar on admin pannel
// Input : None
// Output : Access Codes admin page
// Written by: CIS

import React, { Component } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import DashBoard from './Dashboard';
import StudentTable from './StudentTable';
import SponsorTable from './SponsorTable';
import Requests from './Requests';
import FullRequests from './FullReq';
import AccessCodesTable from './AccessCode';
import OrganizationsTable from './Organization';
import ProvidersTable from './Providers';
import Vouchers from './Vouchers';
import Uniquegift from './Uniquegift';
import ReqDetails from './ReqDetails';
import AssignedVoucher from './AssignedVoucher';

export default class Sidebar extends Component {
  render() {
    return (
      <>
        <aside
          class="main-sidebar elevation-4"
          style={{ marginTop: '4%', color: 'red' }}
        >
          <div class="sidebar">
            <div class="user-panel mt-3 pb-3 mb-3 d-flex">
              <div class="image">
                {/* <img src="task\src\assects\img\Lucifer.jfif" class="img-circle elevation-2" alt="User Image" /> */}
              </div>
              <div class="info">
                <Link to="#" class="d-block">
                  {' '}
                  <h5
                    style={{
                      marginTop: '35%',
                      marginBottom: '35px',
                      color: '#2998D1',
                    }}
                  >
                    Sponsor-a-Student
                  </h5>
                </Link>
              </div>
            </div>

            {/* <!-- SidebarSearch Form --> */}
            {/* <div class="form-inline">
              <div class="input-group" data-widget="sidebar-search">
                <input
                  class="form-control form-control-sidebar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <div class="input-group-append">
                  <button class="btn btn-sidebar">
                    <i class="fas fa-search fa-fw"></i>
                  </button>
                </div>
              </div>
            </div> */}

            {/* <!-- Sidebar Menu --> */}
            <nav class="mt-2">
              <ul
                class="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                {/* <!-- Add icons to the links using the .nav-icon class
                 with font-awesome or any other icon font library --> */}
                <li class="nav-item">
                  <Link to="/Dashboard" class="nav-link">
                    <i class="nav-icon fas fa-tachometer-alt text-primary"></i>
                    <p style={{ color: '#2998D1' }}>DashBoard </p>
                  </Link>
                </li>

                <li class="nav-item">
                  <Link to="StudentTable" class="nav-link">
                    <i class="nav-icon fas fa-user-graduate mr-2 text-primary"></i>
                    {/* <i class="fas fa-database mr-2"></i> */}
                    <p style={{ color: '#2998D1' }}>Student</p>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="SponsorTable" class="nav-link">
                    <i class="nav-icon fas fa-people-carry mr-2 text-primary"></i>
                    <p style={{ color: '#2998D1' }}>Sponsor</p>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="ReqDetails" class="nav-link">
                    <i class="nav-icon fas fa-layer-group mr-2 text-primary"></i>
                    <p style={{ color: '#2998D1' }}>Assigned Request</p>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="PendingRequests" class="nav-link">
                    <i class="nav-icon fas fa-hourglass-start mr-2 text-primary"></i>
                    <p style={{ color: '#2998D1' }}>Unassigned Requests</p>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="FullfilledRequests" class="nav-link">
                    <i class="nav-icon fas fa-clipboard-check mr-2 text-primary"></i>
                    <p style={{ color: '#2998D1' }}>Fullfilled Requests</p>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="Organizations" class="nav-link">
                    <i class="nav-icon far fa-building mr-2 text-primary"></i>
                    <p style={{ color: '#2998D1' }}>Organization</p>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="Providers" class="nav-link">
                    <i class="nav-icon fas fa-handshake mr-2 text-primary"></i>
                    <p style={{ color: '#2998D1' }}>Providers</p>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="AccessCodes" class="nav-link">
                    <i class="nav-icon fas fa-layer-group mr-2 text-primary"></i>
                    <p style={{ color: '#2998D1' }}>Access Codes</p>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="Uniquegifts" class="nav-link">
                    <i class="nav-icon fas fa-layer-group mr-2 text-primary"></i>
                    <p style={{ color: '#2998D1' }}>Unique Gifts</p>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="AssignedVoucher" class="nav-link">
                    <i class="nav-icon fas fa-layer-group mr-2 text-primary"></i>
                    <p style={{ color: '#2998D1' }}>Purchased Vouchers</p>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </aside>
        <Routes>
          <Route exact path="Dashboard" element={<DashBoard />}></Route>
          <Route path="StudentTable" element={<StudentTable />}></Route>
          <Route path="SponsorTable" element={<SponsorTable />}></Route>
          <Route path="PendingRequests" element={<Requests />}></Route>
          <Route path="FullfilledRequests" element={<FullRequests />}></Route>
          <Route path="AccessCodes" element={<AccessCodesTable />}></Route>
          <Route path="Organizations" element={<OrganizationsTable />}></Route>
          <Route path="Providers" element={<ProvidersTable />}></Route>
          <Route path="Uniquegifts" element={<Uniquegift />}></Route>
          <Route path="ReqDetails" element={<ReqDetails />}></Route>
          <Route path="AssignedVoucher" element={<AssignedVoucher />}></Route>
        </Routes>
      </>
    );
  }
}
