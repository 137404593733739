// Functionality of this code : This code has the card functionality for Featured Post section on Main Page
// Input : None
// Output : Home Page
// Written by: Initial team VK, KR

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import moment from 'moment';

const useStyles = makeStyles({
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: '100%',
    borderBottom: '1px solid #ddd',
  },
  cardContenth1: {
    color: '#F4113D',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '30px',
    textTransform: 'capitalize',
  },
  cardGrid: { width: 1012 },
  cardContentDetail: {
    margin: '18px 0',
  },
  cardContentsmall: {
    color: '#ed0a3f',
    fontSize: '16px',
  },
  cardContentnum: {
    color: '#2998D1',
    fontSize: '16px',
  },
  cardContent: {
    padding: '30px 30px 40px 30px',
  },
});

//Home page has two small posters below the main poster.
export default function FeaturedPost(props) {
  const classes = useStyles();
  const { post } = props;
  return (
    <Grid container item xs={12} md={6}>
      <Card>
        <Grid item xs={12} md={12}>
          <CardMedia
            className={classes.cardMedia}
            image={post.image}
            title="Image title"
            component="img"
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <CardContent className={classes.cardContent}>
            <Grid item xs={12} md={12}>
              <Typography className={classes.cardContenth1}>
                {post.title}
              </Typography>
              <Typography className={classes.cardContentDetail}>
                {post.description}
              </Typography>
              <Grid xs={12} className={classes.cardContentbottom}>
                <Grid
                  xs={8}
                  sm={8}
                  md={8}
                  style={{ float: 'left', marginBottom: '20px' }}
                >
                  <Typography className={classes.cardContentsmall}>
                    Continue reading...
                  </Typography>
                </Grid>

                <Grid
                  xs={4}
                  sm={4}
                  md={4}
                  style={{ float: 'right', marginBottom: '20px' }}
                >
                  <Typography className={classes.cardContentnum}>
                    {moment().format('MMM D')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      </Card>
    </Grid>
  );
}
FeaturedPost.propTypes = {
  post: PropTypes.object,
};
