// Functionality of this code : This code contains success page
// Input : None
// Output : Success page ->Payment transactions
// Written by: CIS

import React, { useEffect } from 'react';
import * as firebase from 'firebase';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function Success(props) {
  const navigate = useNavigate();
  const [data, setData] = React.useState('');
  const [show, setShow] = React.useState(false);
  const [msg, setMsg] = React.useState(true);
  const onSubmit = async () => {
    navigate('/');
    props.setPage('vouchers');
  };

  useEffect(() => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async function (idToken) {
        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            auth: idToken,
          },
        };
        await fetch(
          process.env.REACT_APP_PROXY + '/payment/getTrans',
          requestOptions
        )
          .then(res => res.json())
          .then(async data => {
            if (data.success === true) {
              setShow(true);
            } else {
              setMsg(false);
            }
            let id = data.data[0].transaction_id;
            await axios
              .get(
                `https://api.sandbox.hit-pay.com/v1/payment-requests/${id}`,
                {
                  headers: {
                    'X-BUSINESS-API-KEY':
                      'fa676435477c5d181de8444931e7e1ae8fe2bf04eccf6559f2b8eab7376b6f84',
                  },
                }
              )
              .then(async res => {
                if (res.data.status == 'completed') {
                  let dataResp = res.data;
                  setData(dataResp);
                  const requestOptionsTrans = {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      auth: idToken,
                    },
                    body: JSON.stringify({ dataResp }),
                  };
                  await fetch(
                    process.env.REACT_APP_PROXY + '/payment/upTrans',
                    requestOptionsTrans
                  )
                    .then(res => res.json())
                    .then(async data => {
                      // if (data.success === true) {
                      //   setShow(true);
                      // }
                    });
                }
              });
          })
          .catch(error => {});
      });
  }, []);

  return (
    <>
      <div>
        {show === false && msg === true ? (
          <Box
            sx={{
              display: 'flex',
              marginLeft: '50%',
              marginTop: '10%',
              marginBottom: '50px',
            }}
          >
            <CircularProgress color="success" />
          </Box>
        ) : (
          <div>
            {msg === false ? (
              <div
                style={{
                  textAlign: 'center',
                  padding: '40px 0',
                  background: '#EBF0F5',
                  marginTop: '50px',
                }}
              >
                <div
                  style={{
                    background: 'white',
                    padding: '60px',
                    width: '500px',
                    borderRadius: '4px',
                    boxShadow: '0 2px 3px #C8D0D8',
                    display: 'inline-block',
                    margin: '0 auto',
                  }}
                >
                  <div
                    style={{
                      borderRadius: '200px',
                      height: '200px',
                      width: '200px',
                      background: '#F8FAF5',
                      margin: '0 auto',
                    }}
                  >
                    <i
                      style={{
                        color: 'rgb(253 6 6)',
                        fontSize: '100px',
                        lineHeight: '200px',
                        marginLeft: '-15px',
                      }}
                      class="checkmark"
                    >
                      !
                    </i>
                  </div>
                  <h1
                    style={{
                      color: 'rgb(253 6 6)',
                      fontFamily: 'Nunito Sans", "Helvetica Neue", sans-serif',
                      fontWeight: '900',
                      fontSize: '40px',
                      marginBottom: '90px',
                    }}
                  >
                    Error
                  </h1>
                  <p
                    style={{
                      color: ' #404F5E',
                      fontFamily: 'Nunito Sans", "Helvetica Neue", sans-serif',
                      fontSize: '20px',
                      margin: '0px',
                    }}
                  >
                    Something Went Wrong
                  </p>
                </div>
                <button
                  className="btn btn-dark"
                  style={{
                    margin: '25px 40% 20px',
                    width: '20%',
                    height: '50px',
                    fontSize: '25px',
                  }}
                  onClick={onSubmit}
                >
                  Back
                </button>
              </div>
            ) : (
              <div
                style={{
                  textAlign: 'center',
                  padding: '40px 0',
                  background: '#EBF0F5',
                  marginTop: '50px',
                }}
              >
                <div
                  style={{
                    background: 'white',
                    padding: '60px',
                    borderRadius: '4px',
                    boxShadow: '0 2px 3px #C8D0D8',
                    display: 'inline-block',
                    margin: '0 auto',
                  }}
                >
                  <div
                    style={{
                      borderRadius: '200px',
                      height: '200px',
                      width: '200px',
                      background: '#F8FAF5',
                      margin: '0 auto',
                    }}
                  >
                    <i
                      style={{
                        color: '#9ABC66',
                        fontSize: '100px',
                        lineHeight: '200px',
                        marginLeft: '-15px',
                      }}
                      class="checkmark"
                    >
                      ✓
                    </i>
                  </div>
                  <h1
                    style={{
                      color: '#88B04B',
                      fontFamily: 'Nunito Sans", "Helvetica Neue", sans-serif',
                      fontWeight: '900',
                      fontSize: '40px',
                      marginBottom: '90px',
                    }}
                  >
                    Success
                  </h1>
                  <p
                    style={{
                      color: ' #404F5E',
                      fontFamily: 'Nunito Sans", "Helvetica Neue", sans-serif',
                      fontSize: '20px',
                      margin: '0px',
                    }}
                  >
                    Your transaction has been completed Successfully
                  </p>
                </div>
                <button
                  className="btn btn-dark"
                  style={{
                    margin: '25px 40% 20px',
                    width: '20%',
                    height: '50px',
                    fontSize: '25px',
                  }}
                  onClick={onSubmit}
                >
                  Back
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
