// Functionality of this code : This code has the Online Voucher detail's table on admin pannel
// Input : None
// Output : Online Voucher details admin page
// Written by: CIS

import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import tableIcons from './MaterialTableIcons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
import { Button } from '@material-ui/core';

toast.configure();

export default function Vouchers() {
  const [error, setError] = React.useState('');
  const [codes, setVoucher] = React.useState([]);
  const [data, setData] = React.useState([]);

  const columns = [
    {
      title: 'Voucher Number',
      field: 'voucher_number',
    },
    {
      title: 'Voucher Type ',
      field: 'voucher_type',
    },
    {
      title: 'Vendor',
      field: 'vendor',
    },
    {
      title: 'URL',
      field: 'url',
    },
    {
      title: 'Amount',
      field: 'amount',
      type: 'numeric',
    },
    // {
    //   title: 'Expiry Date',
    //   field: 'expiry_date',
    //   type: 'date',
    //   dateSetting: {
    //     format: 'dd/MM/yyyy',
    //   },
    // },
    {
      title: 'Status',
      field: 'status',
    },
  ];
  useEffect(() => {
    getVouchers();
  }, []);

  const getVouchers = () => {
    fetch(process.env.REACT_APP_PROXY + '/admin/vouchers')
      .then(res => res.json())
      .then(data => {
        let resp = data.data;
        if (data.success === false) {
          setError(data.message);
        } else {
          const unique = [...new Set(resp)];
          setVoucher(unique);
        }
      });
  };

  const addVouchers = value => {
    fetch(process.env.REACT_APP_PROXY + '/admin/addVouchers', value)
      .then(res => res.json())
      .then(data => {
        if (data.success === true) {
          toast.success(data.message, { autoClose: 1000 });
        } else {
          toast.error(data.message, { autoClose: 1000 });
        }
      });
  };

  const editVouchers = value => {
    fetch(process.env.REACT_APP_PROXY + '/admin/updateVouchers', value)
      .then(res => res.json())
      .then(data => {
        if (data.success === true) {
          toast.success(data.message, { autoClose: 1000 });
        } else {
          toast.error(data.message, { autoClose: 1000 });
        }
      });
  };

  const deleteVouchers = value => {
    fetch(process.env.REACT_APP_PROXY + '/admin/deleteVouchers', value)
      .then(res => res.json())
      .then(data => {
        if (data.success === true) {
          toast.success(data.message, { autoClose: 1000 });
        } else {
          toast.error(data.message, { autoClose: 1000 });
        }
      });
  };

  const [file, setFile] = useState({});
  const [fileName, setFileName] = useState('');

  const saveFile = e => {
    setFile(e.target.files[0]);
  };

  const uploadFile = async e => {
    const formData = new FormData();
    formData.append('dataFile', file);
    try {
      axios
        .post(process.env.REACT_APP_PROXY + '/admin/upload', formData, {})
        .then(res => {
          getVouchers();
          toast.success('File Uploaded Successfully', { autoClose: 2000 });
        });
    } catch (ex) {
      toast.error(ex, { autoClose: 2000 });
    }
  };

  return (
    <div
      className="content-wrapper"
      style={{ marginTop: '6%', overflow: 'hidden' }}
    >
      <MaterialTable
        title={<h3 style={{ color: '#F4113D' }}>Vouchers</h3>}
        icons={tableIcons}
        columns={columns}
        data={codes}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  newData,
                }),
              };
              addVouchers(requestOptions);
              setVoucher([...codes, newData]);
              resolve();
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  newData,
                }),
              };
              editVouchers(requestOptions);
              setTimeout(() => {
                const dataUpdate = [...codes];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                setVoucher([...dataUpdate]);
                resolve();
              }, 1000);
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  oldData,
                }),
              };
              deleteVouchers(requestOptions);
              setTimeout(() => {
                const dataDelete = [...codes];
                const index = oldData.tableData.voucher_number;
                dataDelete.splice(index, 1);
                setVoucher([...dataDelete]);
                resolve();
              }, 1000);
              resolve();
            }),
        }}
        options={{
          sorting: true,
          exportButton: true,
          actionsColumnIndex: -1,
          headerStyle: { color: '#2998D1' },
        }}
      />
      <Button>
        Upload Excel File : &nbsp; <input type="file" onChange={saveFile} />
      </Button>
      <Button
        color="secondary"
        variant="contained"
        component="span"
        onClick={uploadFile}
      >
        Upload
      </Button>
    </div>
  );
}
