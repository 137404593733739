// Functionality of this code : This page contains theme css
// Input : None
// Output : Theme page
// Written by: Initial team VK, KR

import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#2998d1',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: 'Calibri',
    button: {
      fontWeight: '700',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {},
    },
  },
});

export default theme;
