import React, { useState } from 'react';
import Switchs from './Switchs';
import Student from './Student';
import Sponser from './Sponser';
import Organisation from './Organisation';

const Instruction = (props) => {
  // State variables for toggles
  const [checkedStudent, setCheckedStudent] = useState(true);
  const [checkedSponsor, setCheckedSponsor] = useState(false);
  const [checkedOrganisation, setCheckedOrganisation] = useState(false);

  // Handler function for toggles
  const handleChange = (event) => {
    const { name, checked } = event.target;
    if (name === 'checkedStudent' && checked) {
      setCheckedStudent(true);
      setCheckedSponsor(false);
      setCheckedOrganisation(false);
    } else if (name === 'checkedSponsor' && checked) {
      setCheckedStudent(false);
      setCheckedSponsor(true);
      setCheckedOrganisation(false);
    } else if (name === 'checkedOrganisation' && checked) {
      setCheckedStudent(false);
      setCheckedSponsor(false);
      setCheckedOrganisation(true);
    } 
  };


  return (
    <div>
      <section className="student-work-section">
        <div className="container">
          <div className="inner-student-work">
            <div className="tabs-student">
              <Switchs
                checkedStudent={checkedStudent}
                checkedSponsor={checkedSponsor}
                checkedOrganisation={checkedOrganisation}
                handleChange={handleChange}
              />
              {/* Conditional rendering based on toggle state */}
              {checkedStudent && <Student  setPage={props.setPage} />}
              {checkedSponsor && <Sponser  setPage={props.setPage}/>}
              {checkedOrganisation && <Organisation  setPage={props.setPage} />}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Instruction;
