// Functionality of this code : This code has the assigned voucher's table on admin pannel
// Input : None
// Output : Assigned Vouchers admin page
// Written by: CIS

import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import tableIcons from './MaterialTableIcons';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

export default function AssignedVoucher() {
  const [error, setError] = React.useState('');
  const [student, setStudent] = React.useState([]);
  const columns = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Email',
      field: 'email',
    },
    {
      title: 'Product ID',
      field: 'product_id',
      align: 'center',
    },
    {
      title: 'Product Name',
      field: 'product_name',
    },
    {
      title: 'Amount',
      field: 'amount',
      align: 'center',
    },
    {
      title: 'URL',
      field: 'url',
    },
    {
      title: 'Expiry Date',
      field: 'expiry_date',
      type: 'date',
      dateSetting: {
        format: 'dd/MM/yyyy',
      },
    },
    {
      title: 'Is Used',
      field: 'is_used',
      align: 'center',
    },
  ];

  useEffect(() => {
    getCode();
  }, []);

  const getCode = () => {
    fetch(process.env.REACT_APP_PROXY + '/admin/assignedVoucher')
      .then(res => res.json())
      .then(data => {
        let resp = data.data;
        if (data.success === false) {
          setError(data.message);
        } else {
          const unique = [...new Set(resp)];
          setStudent(unique);
        }
      });
  };

  // const addProvider = value => {
  //   fetch(process.env.REACT_APP_PROXY + '/admin/addProvider', value)
  //     .then(res => res.json())
  //     .then(data => {
  //       if (data.success === true) {
  //         toast.success(data.message, { autoClose: 1000 });
  //       } else {
  //         toast.error(data.message, { autoClose: 1000 });
  //       }
  //     });
  // };

  // const editProvider = value => {
  //   fetch(process.env.REACT_APP_PROXY + '/admin/updateSponsor', value)
  //     .then(res => res.json())
  //     .then(data => {
  //       if (data.success === true) {
  //         toast.success(data.message, { autoClose: 1000 });
  //       } else {
  //         toast.error(data.message, { autoClose: 1000 });
  //       }
  //     });
  // };

  const deleteProvider = value => {
    fetch(process.env.REACT_APP_PROXY + '/admin/deletePurchased', value)
      .then(res => res.json())
      .then(data => {
        if (data.success === true) {
          toast.success(data.message, { autoClose: 1000 });
        } else {
          toast.error(data.message, { autoClose: 1000 });
        }
      });
  };

  return (
    <div
      className="content-wrapper"
      style={{ marginTop: '6%', overflow: 'hidden' }}
    >
      {/* <h3 style={{color:'red'}}>Students</h3> */}
      <MaterialTable
        title={<h3 style={{ color: '#F4113D' }}>Purchased Voucher</h3>}
        icons={tableIcons}
        columns={columns}
        data={student}
        editable={{
          // onRowAdd: newData =>
          //   new Promise((resolve, reject) => {
          //     const requestOptions = {
          //       method: 'POST',
          //       headers: {
          //         'Content-Type': 'application/json',
          //       },
          //       body: JSON.stringify({
          //         newData,
          //       }),
          //     };
          //     addProvider(requestOptions);
          //     setStudent([...sponsor, newData]);
          //     resolve();
          //   }),
          // onRowUpdate: (newData, oldData) =>
          //   new Promise((resolve, reject) => {
          //     const requestOptions = {
          //       method: 'POST',
          //       headers: {
          //         'Content-Type': 'application/json',
          //       },
          //       body: JSON.stringify({
          //         newData,
          //       }),
          //     };
          //     editProvider(requestOptions);
          //     setTimeout(() => {
          //       const dataUpdate = [...sponsor];
          //       const index = oldData.tableData.id;
          //       dataUpdate[index] = newData;
          //       setStudent([...dataUpdate]);
          //       resolve();
          //     }, 1000);
          //   }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  oldData,
                }),
              };
              deleteProvider(requestOptions);
              setTimeout(() => {
                const dataDelete = [...student];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setStudent([...dataDelete]);
                resolve();
              }, 1000);
            }),
        }}
        options={{
          sorting: true,
          exportButton: true,
          actionsColumnIndex: -1,
          headerStyle: { color: '#2998D1' },
        }}
      />
    </div>
  );
}
