// Functionality of this code : This page contains the Privacy policy page section
// Input : None
// Output : Privacy policy page
// Written by: Initial team VK, KR

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 800,
  },
}));

//static page  privacy policy
export default function PrivacyPolicy(props) {
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      <Container maxWidth="lg">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Grid item xs={12} md={12}>
          <CardActionArea component="a" href="#">
            <Card className={classes.card}>
              <div className={classes.cardDetails}>
                <CardContent>
                  <Typography variant="h5" color="primary">
                    PRIVACY POLICY
                  </Typography>
                  <br />
                  <Typography variant="body2" paragraph align="justify">
                    (1) This Privacy Policy forms a part of the Terms and
                    Conditions governing your relationship with Sammantrana
                    Consulting Pte. Ltd. (referred to as{' '}
                    <b> “Sammantrana”, "we", "our", "us"</b>) and should be read
                    in conjunction with the Terms and Conditions entered into
                    between you and Sammantrana (the{' '}
                    <b> “Terms and Conditions”</b>) in relation to the Services
                    provided by Sammantrana (the <b> Services</b>) though its
                    Website. We take the security and privacy of the Personal
                    Data (defined below) of our Students, Sponsors and
                    Organisations and users of our website,
                    [Sponsor-a-student.io] (referred to as the “Website”) very
                    seriously.
                  </Typography>
                  <Typography variant="body2" paragraph align="justify">
                    (2) This Privacy Policy will assist you in understanding how
                    we collect, use, disclose and/or process the Personal Data
                    you have provided to us or that we possess about you, as
                    well as to assist you in making an informed decision before
                    providing us with any of your Personal Data.
                  </Typography>
                  <Typography variant="body2" paragraph align="justify">
                    (3) This Privacy Policy supplements but does not supersede
                    nor replace any other consents which you may have previously
                    provided to us. We may from time to time update this Privacy
                    Policy to ensure that it is consistent with our future
                    developments, industry trends and/or any changes in legal or
                    regulatory requirements.
                  </Typography>
                  <Typography variant="body2" paragraph align="justify">
                    (4) Please note that capitalised words and expressions
                    shall, unless otherwise specified or the context otherwise
                    requires, have the corresponding meaning assigned to them in
                    the Terms and Conditions of the use of our Website.
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    1. PERSONAL DATA
                  </Typography>
                  <Typography>
                    In this Privacy Policy, the term <b> “Personal Data” </b>{' '}
                    refers to information (whether true or not) that is
                    connected to you as an identifiable individual, defined
                    under the Personal Data Protection Act 2012 (No. 26 of 2012)
                    (“PDPA”) to mean data, whether true or not, about an
                    individual who can be identified from that data, or from
                    that data and other information to which an organisation has
                    or is likely to have access. Examples of such Personal Data
                    that we may collect include:
                  </Typography>
                  <Typography>
                    i.{' '}
                    <b>
                      {' '}
                      your name, student identification number, mailing address,
                      education institute, personal or work email address,
                      telephone number(s), date of birth, bank account
                      information, and any other information relating to you
                      which you{' '}
                    </b>{' '}
                    have provided us in your Account registration, Request
                    submission or matching of a Request to the Sponsor or any
                    other forms of interaction with you on the Website;
                  </Typography>
                  <Typography>
                    ii. information about your use of our Website and Services,
                    including cookies, IP address, Account details, but only to
                    the extent that Sammantrana may identify you from such
                    information;
                  </Typography>
                  <Typography>
                    iii. your educational background, income levels and
                    employment history; and
                  </Typography>
                  <Typography>
                    iv. information about your usage of and interaction with our
                    Website and/or services including computer and connection
                    information, device capability, bandwidth, statistics on
                    page views, and traffic to and from our Website.
                  </Typography>
                  <Typography></Typography>
                  <Typography variant="h6" paragraph align="justify">
                    2. COLLECTION OF INFORMATION
                  </Typography>
                  <Typography>2.1 We collect your Personal Data:</Typography>
                  <Typography>
                    (a) <b> Directly from you</b>. You may give us your Personal
                    Data which will be transferred and stored on Google Cloud
                    servers (and will be subject to any terms and conditions
                    applicable thereto) when you do any of the following
                    (whether on your own behalf or on behalf of your
                    Organisation):
                  </Typography>
                  <Typography>(i) use or access our Website;</Typography>
                  <Typography>
                    (ii) create an Account on our Website;
                  </Typography>
                  <Typography>
                    (iii) To process, carry out and deliver your funding
                    Request(s);
                  </Typography>
                  <Typography>(iv) use our Services;</Typography>
                  <Typography>
                    (v) request marketing materials to be sent to you; or
                  </Typography>
                  <Typography>
                    (vi) for feedback and complaints or legal purposes in
                    enforcing Sammantrana’s rights, procuring legal advice and
                    dispute resolution;
                  </Typography>
                  <Typography>
                    (vii) to detect, prevent and investigate fraud, crime,
                    offences, money-laundering, counter-terrorist financing and
                    bribery;
                  </Typography>
                  <Typography>
                    (viii) to assist us in conducting or co-operating in
                    investigations or proceedings relating to fraud or other
                    illegal activity where we believe it is reasonable and
                    appropriate to do so; and{' '}
                  </Typography>
                  <Typography>
                    (ix) purposes reasonably related to the aforesaid.{' '}
                  </Typography>
                  <Typography>
                    [AP: Please confirm the purposes of collection of Personal
                    Data as stated above.]
                  </Typography>
                  <Typography>
                    (b) <b> From your authorised representatives</b>. Where
                    applicable, we may also collect your Personal Data from your
                    authorised representatives. These include persons whom you
                    have authorised and persons who have been validly identified
                    as acting on your behalf pursuant to our security
                    procedures.{' '}
                  </Typography>
                  <Typography>
                    (c) <b> When you interact with our Website</b>. As you
                    interact with our Website, we may automatically collect your
                    Personal Data. We may collect such personal data by using
                    website cookies.{' '}
                  </Typography>
                  <Typography>2.2 Cookies</Typography>
                  <Typography>
                    We use cookies to provide visitors with a personalised
                    experience on our Website. Cookies are pieces of information
                    that a website transfers to the memory or hard drive of a
                    visitor’s computer for record-keeping purposes. We use
                    cookies to allow access without re-entering the visitor’s
                    user ID, to make improvements, and to better tailor our
                    Website to our visitors’ needs. We also use this information
                    to verify that visitors meet the criteria required to
                    process their requests. Most browsers used by visitors have
                    options that allow the visitor to control whether the
                    browser will accept cookies, reject cookies, or notify the
                    visitor each time a cookie is sent. However, if you disable
                    or refuse cookies, please note that some parts of our
                    Website may become inaccessible or not function properly. In
                    addition, you can delete cookies from your browser program
                    at any time.
                  </Typography>
                  <Typography>2.3 Change of purpose</Typography>
                  <Typography>
                    We will only use your Personal Data for the purposes for
                    which we collected it, unless we reasonably consider that we
                    need to use it for another reason and that reason is
                    compatible with the original purpose. If there is any change
                    in the purposes for which we collect your Personal Data, we
                    will inform you of such change by way of email. Your
                    continued use of the Website and our Services thereafter
                    shall be deemed to be an acceptance of such change. If you
                    wish to get an explanation as to how the processing for the
                    new purpose is compatible with the original purpose, please
                    contact us at [Contact Detail]. If we need to use your
                    Personal Data for an unrelated purpose, we will notify you
                    and will explain the legal basis which allows us to do so.
                    [AP: Please provide contact details as highlighted above.]
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    3. DISCLOSURE OF INFORMATION
                  </Typography>
                  <Typography>
                    3.1 We may disclose your Personal Data to third parties in
                    limited circumstances as set out below:
                  </Typography>
                  <Typography>
                    (a) We may share a Student’s Personal Data with
                    Organisations which include but are not limited to
                    educational partners, educational institutions, volunteer
                    organisations and/or Sponsors in connection with the
                    services we provide to you.
                  </Typography>
                  <Typography>
                    (b) We may share your Personal Data with our suppliers,
                    agents or contractors in connection with services they
                    perform for us or pursuant to agreements with our suppliers.
                  </Typography>
                  <Typography>
                    (c) We may share your Personal Data with third parties to
                    comply with a legal obligation, when we believe in good
                    faith that an applicable law requires it, at the request of
                    government authorities conducting an investigation, to
                    verify or enforce our contractual rights or other applicable
                    policies, to detect and protect against fraud or any
                    technical or security vulnerabilities, to respond to an
                    emergency, or otherwise to protect the rights, property,
                    safety or security of third parties and visitors to our
                    website or the public.
                  </Typography>
                  <Typography>
                    (d) As we continue to develop our business, we may sell or
                    purchase assets. If another entity acquires or merges with
                    us, your Personal Data will be disclosed to such entity.
                  </Typography>
                  <Typography>
                    (e) Also, if any bankruptcy or reorganisation proceeding is
                    brought by or against us, all such information will be
                    considered an asset of ours and as such it is possible they
                    will be sold or transferred to third parties.
                  </Typography>
                  <Typography>
                    3.2 We do not disclose your Personal Data to unaffiliated
                    third parties for their independent use unless we have
                    obtained your express consent to do so and pursuant to
                    executing confidentiality agreements with such unaffiliated
                    third parties. We also require all third parties to respect
                    the security of your Personal Data and to treat it in
                    accordance with the law.
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    4. SECURITY AND RETENTION OF INFORMATION
                  </Typography>
                  <Typography>
                    4.1 We only retain your Personal Data for as long as is
                    necessary for us to use your Personal Data as described
                    above. However, please be advised that we may retain some of
                    your Personal Data after you cease to use our Services, for
                    instance if the data is necessary to meet our legal
                    obligations, such as retaining the information for tax and
                    accounting purposes.
                  </Typography>
                  <Typography>
                    4.2 We take every reasonable step to ensure that your
                    Personal Data is only processed for the minimum period
                    necessary in connection with:
                    <br />
                    (i) the purposes set out in this Privacy Policy;
                    <br />
                    (ii) any additional purposes notified to you at or before
                    the time of collection of the relevant Personal Data or
                    commencement of the relevant processing;
                    <br />
                    or (iii) as required or permitted by applicable law; and
                    thereafter, for the duration of any applicable limitation
                    period. In short, once your Personal Data is no longer
                    required, we will destroy or delete it in a secure manner.
                  </Typography>
                  <Typography>
                    4.3 In some circumstances we may anonymise your Personal
                    Data (so that it can no longer be associated with you) for
                    research or statistical purposes in which case we may use
                    this information indefinitely without further notice to you.{' '}
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    5. INTERNATIONAL TRANSFERS OF INFORMATION
                  </Typography>
                  <Typography>
                    We may transfer to and store the Personal Data we collect
                    about you in, countries other than the country in which the
                    Personal Data was originally collected. Where we or our
                    agents, contractors or third party service providers store
                    or transfer your Personal Data to other countries, we will
                    protect the Personal Data as described in this Privacy
                    Policy and comply with applicable legal requirements
                    providing adequate protection for the transfer of data to
                    countries outside of Singapore.{' '}
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    6. SECURITY
                  </Typography>
                  <Typography>
                    We take commercially reasonable precautions to keep all
                    information obtained from our online visitors secure against
                    unauthorised access (e.g. data breach) and use and we
                    periodically review our security measures. Although there is
                    no way that we can absolutely guarantee the security of your
                    Personal Data, we are committed to employing reasonable
                    security measures, regularly reviewing our security
                    practices (security risk assessments / audits), and
                    providing regular awareness training. You are responsible
                    for keeping your login information and passwords
                    confidential. The Website uses [Specify Security
                    Certificates/Tools] for its Website security certificates.
                    Please be aware that these protection tools do not protect
                    information that is not collected through our Website, such
                    as information provided to us by e-mail. [AP: Please provide
                    the security certificates or tools that will be used, as
                    highlighted above.]
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    7. COLLECTION OF INFORMATION FROM CHILDREN
                  </Typography>
                  <Typography>
                    7.1 We do not knowingly collect Personal Data from children
                    under the age of 13. If you are under the age of 13, please
                    do not provide Personal Data of any kind whatsoever.{' '}
                  </Typography>
                  <Typography>
                    7.2 If a child under the age of 13 provides us with Personal
                    Data, a parent or guardian of that child may have this
                    information deleted from our records by contacting us
                    through [Contact Details]. [AP: Please provide contact
                    details as highlighted above.]
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    8. ACCURACY OF PERSONAL DATA
                  </Typography>
                  <Typography>
                    8.1 We will make reasonable efforts to ensure that the
                    Personal Data we collect is accurate and complete. Please
                    help us by ensuring that all Personal Data that you submit
                    is complete and accurate.
                  </Typography>
                  <Typography>
                    8.2 Should you provide Sammantrana any Personal Data of
                    yourself or others:
                  </Typography>
                  <Typography>
                    (a) you represent and warrant to Sammantrana that (i) each
                    individual (including yourself) whose Personal Data has been
                    provided to us, has been notified of the purposes for which
                    data will be collected, processed, used or disclosed; and
                    (ii) such individual’s consent for the collection,
                    processing, use and disclosure of such Personal Data by
                    Sammantrana has been obtained and authorised by such
                    individual.
                  </Typography>
                  <Typography>
                    (b) you shall notify us immediately upon becoming aware of
                    any withdrawal by any individual of his/her consent to the
                    collection, processing, use and/or disclosure by Sammantrana
                    of his/her Personal Data provided to Sammantrana. The
                    withdrawal of such consent may affect the Services which
                    Sammantrana is able to provide to you or such.
                  </Typography>
                  <Typography>
                    (c) you shall ensure at all times that any information
                    provided (including any Personal Data) to us is correct,
                    accurate and complete, and that any consent given in
                    relation to Personal Data shall, subject to all applicable
                    laws and regulations, survive death, incapacity, bankruptcy
                    or insolvency of any such individual and the termination or
                    expiration of any account in connection with the use of the
                    Services.
                  </Typography>
                  <Typography>
                    8.3 You can access and make changes to any of the Personal
                    Data that you have provided by logging into our Website and
                    updating your preferences under [“My Account”], by
                    contacting us through [Contact Details]. We will correct or
                    complete your Personal Data as soon as reasonably
                    practicable.
                  </Typography>
                  <Typography>
                    8.4 We will not be responsible for relying on inaccurate or
                    incomplete Personal Data arising from you not updating us of
                    any changes in your Personal Data that you had initially
                    provided us with. Failure to provide complete, accurate,
                    true and correct information may result in our inability to
                    provide you with Services you have requested and/or to
                    verify your Account or validate any Request. [AP: Please
                    provide contact details as highlighted above, and confirm if
                    the above manner of requesting a correction to Personal Data
                    is fine.]
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    9. ACKOWLEDGEMENTS AND DISCLAIMERS
                  </Typography>
                  <Typography>
                    9.1 Where you have chosen a password to access certain
                    Services of the Website, you are responsible for keeping
                    your password confidential. We ask you not to share your
                    password with anyone.
                  </Typography>
                  <Typography>
                    9.2 You acknowledge and accept that the inherent nature of
                    the internet and/or telecommunications services is such that
                    transmissions may be subject to interruption, interception,
                    hacking, fluctuation, inaccuracy, defect, corruption, loss,
                    connection error, transmission blackout, delayed or failed
                    transmission and/or incorrect, garbled or incomplete data
                    transmission. Sammantrana will not be liable for breakdown,
                    interruption, failure, closure or malfunctions in
                    communications facilities not under its control that may
                    affect the accuracy, authenticity or timeliness of messages
                    and transactions that may be sent and does not warrant that
                    any identified defect will be corrected.
                  </Typography>
                  <Typography>
                    9.3 You acknowledge that your personal information is
                    transmitted to us at your own risk. Please contact us
                    immediately if you become aware or have reason to believe
                    there has been any unauthorised use of your personal
                    information.
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    10. LINKS TO OTHER SITES
                  </Typography>
                  <Typography>
                    Our Website may contain links to other sites. These sites
                    are not covered by this Privacy Policy, and we are not
                    responsible for the privacy practices or the content of
                    those other sites.
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    11. CHANGES TO THE PRIVACY POLICY
                  </Typography>
                  <Typography>
                    We reserve the right to modify this Privacy Policy and
                    related business practices at any time by posting updated
                    text on this page. Please check this page periodically for
                    updates.
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    12. CONTACT US
                  </Typography>
                  <Typography>
                    If you have any comments, questions or complaints about our
                    Privacy Policy, please contact us through [Contact Details].
                    [AP: Please provide contact details as highlighted above.]
                  </Typography>

                  <br />
                </CardContent>
              </div>
            </Card>
          </CardActionArea>
        </Grid>

        <br />
        <br />
      </Container>
    </>
  );
}
