import React from 'react';
import student3d from './assets/newui/student3d.png'
import money3d from './assets/newui/money3d.png'
import org3d from './assets/newui/org3d.png'



const AboutSection = () => (
  <section className="about-section py-5">
    <div className="container py-lg-5 py-4">
      <div
        className="title-heading-w3 mx-auto text-center mb-sm-5 mb-4 pb-xl-4"
        style={{ maxWidth: '800px' }}
      >
        <h3 className="title-style mb-2">
        Enabling Education through Sponsorship!
        </h3>
        <p>
          Our platform aims to bridge the gap between students and sponsors by providing a seamless and transparent sponsorship experience.
        </p>

        <p>
          <b>
            Join us to make a difference !
          </b>
        </p>
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-lg-4 col-md-6">
          <div className="about-single p-3">
            <div className="about-icon mb-4">
              {/* <i className="fas fa-hands-helping primary-clr-bg"></i> */}
              <img src={money3d} alt='' width='200px' />
            </div>
            <div className="about-content">
              <h5 className="mb-2">Sponsor</h5>
              <p>
                As a sponsor, you have the opportunity to directly sponsor a request, no middle man, no platform fees. Your contributions will cover essential expenses or essential needs of a student.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 mt-md-0 mt-3">
          <div className="about-single p-3">
            <div className="about-icon mb-4">
              {/* <i className="fas fa-users green-clr-bg"></i> */}
              <img src={org3d} alt='' width='200px' />
            </div>
            <div className="about-content">
              <h5 className="mb-2">Organization</h5>
              <p>
                Organizations play a pivotal role in connecting sponsors with students in need.  They endorse or recommend deserving students to be registered on platform.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 mt-lg-0 mt-2">
          <div className="about-single p-3">
            <div className="about-icon mb-4">
              {/* <i className="fas fa-graduation-cap blue-clr-bg"></i> */}
              <img src={student3d} alt='' width='200px' />
            </div>
            <div className="about-content">
              <h5 className="mb-2">Students</h5>
              <p>
                Students are the beneficiaries of our platform. The platform offers an option in which they can commit to pay it forward at the same time they raise a request for support.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default AboutSection;
