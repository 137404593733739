// Functionality of this code : This code has the Sponsor detail's table on admin pannel
// Input : None
// Output : Sponsor details admin page
// Written by: CIS

import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import tableIcons from './MaterialTableIcons';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

export default function SponsorTable() {
  const [error, setError] = React.useState('');
  const [sponsor, setStudent] = React.useState([]);
  const [abc, setAbc] = React.useState([]);
  const [consumed, setConsume] = React.useState([]);
  const [available, setAvailable] = React.useState([]);
  const [temp, setTemp] = React.useState([]);

  const columns = [
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'Email',
      field: 'email',
    },
    // {
    //   title: 'Role',
    //   field: 'role',
    // },
    // {
    //   title: 'University',
    //   field: 'university',
    // },
    // {
    //   title: 'Field',
    //   field: 'field_of_study',
    // },
    // {
    //   title: 'Student Year',
    //   field: 'student_year',
    // },
  ];

  const detailCol = [
    {
      title: 'Need Type',
      field: 'need_type',
    },
    {
      title: 'Amount',
      field: 'donate_max_amount',
    },
    {
      title: 'Consumed',
      field: 'amount',
    },
    {
      title: 'Available',
      field: 'available',
    },
  ];

  useEffect(() => {
    getCode();
  }, []);

  const getCode = async () => {
    await fetch(process.env.REACT_APP_PROXY + '/admin/sponsor')
      .then(res => res.json())
      .then(data => {
        if (data.success === false) {
          setError(data.message);
        } else {
          let resp = data.data;

          const unique = [...new Set(resp)];
          unique.map((e, i) => {
            if (!e.available) {
              e.available = e.donate_max_amount;
              e.amount = 0;
            }
          });

          let uniqueObjArray = [
            ...new Map(data.data.map(item => [item['uid'], item])).values(),
          ];
          uniqueObjArray.forEach(e => {
            let sum = 0;
            let sumAmt = 0;
            resp.map(ele => {
              if (e.uid == ele.uid) {
                sum += ele.available;
                sumAmt += ele.donate_max_amount;
              }
            });
            e.totalAvailable = sum;
            e.totalAmt = sumAmt;
          });

          setAbc(unique);
          setStudent(uniqueObjArray);
        }
      });
  };
  // const addProvider = value => {
  //   fetch(process.env.REACT_APP_PROXY + '/admin/addProvider', value)
  //     .then(res => res.json())
  //     .then(data => {
  //       if (data.success === true) {
  //         toast.success(data.message, { autoClose: 1000 });
  //       } else {
  //         toast.error(data.message, { autoClose: 1000 });
  //       }
  //     });
  // };

  // const editProvider = value => {
  //   fetch(process.env.REACT_APP_PROXY + '/admin/updateSponsor', value)
  //     .then(res => res.json())
  //     .then(data => {
  //       if (data.success === true) {
  //         toast.success(data.message, { autoClose: 1000 });
  //       } else {
  //         toast.error(data.message, { autoClose: 1000 });
  //       }
  //     });
  // };

  const deleteProvider = value => {
    fetch(process.env.REACT_APP_PROXY + '/admin/deleteSponsor', value)
      .then(res => res.json())
      .then(data => {
        if (data.success === true) {
          toast.success(data.message, { autoClose: 1000 });
        } else {
          toast.error(data.message, { autoClose: 1000 });
        }
      });
  };

  return (
    <div
      className="content-wrapper"
      style={{ marginTop: '6%', overflow: 'hidden' }}
    >
      <MaterialTable
        title={<h3 style={{ color: '#F4113D' }}>Sponsors</h3>}
        icons={tableIcons}
        columns={[
          {
            title: 'Name',
            field: 'name',
          },
          {
            title: 'Total Amount',
            field: 'totalAmt',
          },
          {
            title: 'Email',
            field: 'email',
          },
          {
            title: 'Total Available',
            field: 'totalAvailable',
          },
        ]}
        data={sponsor}
        editable={{
          // onRowAdd: newData =>
          //   new Promise((resolve, reject) => {
          //     const requestOptions = {
          //       method: 'POST',
          //       headers: {
          //         'Content-Type': 'application/json',
          //       },
          //       body: JSON.stringify({
          //         newData,
          //       }),
          //     };
          //     addProvider(requestOptions);
          //     setStudent([...sponsor, newData]);
          //     resolve();
          //   }),
          // onRowUpdate: (newData, oldData) =>
          //   new Promise((resolve, reject) => {
          //     const requestOptions = {
          //       method: 'POST',
          //       headers: {
          //         'Content-Type': 'application/json',
          //       },
          //       body: JSON.stringify({
          //         newData,
          //       }),
          //     };
          //     editProvider(requestOptions);
          //     setTimeout(() => {
          //       const dataUpdate = [...sponsor];
          //       const index = oldData.tableData.id;
          //       dataUpdate[index] = newData;
          //       setStudent([...dataUpdate]);
          //       resolve();
          //     }, 1000);
          //   }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  oldData,
                }),
              };
              deleteProvider(requestOptions);
              setTimeout(() => {
                const dataDelete = [...sponsor];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setStudent([...dataDelete]);
                resolve();
              }, 1000);
            }),
        }}
        detailPanel={[
          {
            tooltip: 'Show Details',
            render: rowData => {
              const data = JSON.parse(JSON.stringify(abc));
              return (
                <>
                  <MaterialTable
                    columns={[
                      {
                        title: 'Need Type',
                        field: 'need_type',
                      },
                      {
                        title: 'Amount',
                        field: 'donate_max_amount',
                      },
                      {
                        title: 'Consumed',
                        field: 'amount',
                      },
                      {
                        title: 'Available',
                        field: 'available',
                      },
                    ]}
                    // actions={state.actions}
                    icons={tableIcons}
                    data={data.filter(row => row.uid === rowData.uid)}
                    options={{
                      actionsColumnIndex: -1,
                      search: false,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
                      toolbar: false,
                      paging: false,
                      actionsCellStyle: {
                        minWidth: 80,
                      },
                    }}
                  />
                </>
              );
            },
          },
        ]}
        options={{
          sorting: true,
          exportButton: true,
          actionsColumnIndex: -1,
          headerStyle: { color: '#2998D1' },
        }}
      />
    </div>
  );
}
