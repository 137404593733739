import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Grid, IconButton } from '@material-ui/core';
import { LinkedIn, Instagram } from '@material-ui/icons';
import { orange } from '@material-ui/core/colors';
import logo from '../assets/newlogo.png'
import poster from '../assets/poster-new1.png'

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: '#0e0e0e',
    color: '#fff',
    padding: '50px 0',
  },
  footerTitle: {
    fontSize: '18px',
    color:'white',
    marginBottom: '24px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  footerList: {
    marginBottom: '10px',
  },
  footerLink: {
    fontSize: '18px',
    color: '#fff',
    opacity: 0.9,
    
    '&:hover': {
      opacity: 1,
      textDecoration:'none',
      color:'purple'
    },
  },
  copyRight: {
    alignSelf: 'center',
    fontSize: '15px',
    color:'gray',
    opacity: 1,
  },
  socialIcon: {
    fontSize: '15px',
    marginRight: '5px',
    color: '#fff',
    marginBottom:'20px',
    padding:'10px',
    width: '32px',
    height: '32px',
    lineHeight: '32px',
    borderRadius: '50%',
    textAlign: 'center',
    '&:last-child': {
      marginRight: 0,
    },
    '&:hover': {
      background: '#5FCAFF',
    },
  },
}));

export default function Footer({
  isAuthenticated,
  logOut,
  logoSrc,
  setPage,
  firebase,
  setNewOpen,
  setContact,
}) {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container>
        <div className="footer-29 py-5">
          <div className="container py-lg-4">
            <div className="row footer-top-29">
              <div className="col-lg-12">
                <div className="row">
                <div className="col-md-3 col-6 footer-list-29">
                    <Typography variant="h6" className={classes.footerTitle}>
                      Sponsor Student
                    </Typography>
                    <ul className={classes.footerList}>
                      <li>
                        <img src={logo} width={100} />
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-3 col-6 footer-list-29">
                    <Typography variant="h6" className={classes.footerTitle}>
                      Company
                    </Typography>
                    <ul className={classes.footerList}>
                      <li>
                        <Link href="#"   onClick={() => {
                setPage('about');
              }} className={classes.footerLink}>
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link href="#"   onClick={() => {
                setPage('howitworks');
              }} className={classes.footerLink}>
                          How it Works
                        </Link>
                      </li>
                    </ul>
                  </div>
                  
                  <div className="col-md-3 col-6 footer-list-29 mt-md-0 mt-4">
                    <Typography variant="h6" className={classes.footerTitle}>
                      Support
                    </Typography>
                    <ul className={classes.footerList}>
                      <li>
                        <Link
                          href="#"
                          onClick={() => {
                            setPage('termsofservice');
                          }}
                          className={classes.footerLink}
                        >
                          Terms of Service
                        </Link>
                      </li>
                      <li>
                        <Link href="#" onClick={() => {
              setPage('privacypolicy');
            }} className={classes.footerLink}>
                          Privacy Policy
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-3 col-6 footer-list-29 mt-md-0 mt-4">
                    <Typography variant="h6" className={classes.footerTitle}>
                    Social Media
                    </Typography>
                    <div className="main-social-footer-29">
                  <IconButton href="#" className={classes.socialIcon}>
                    <LinkedIn />
                  </IconButton>
                  <IconButton href="#" className={classes.socialIcon}>
                    <Instagram />
                  </IconButton>
                  {/* Add other social icons as necessary */}
                </div>
                  </div>
                  
                </div>
              </div>
              {/* <div className="col-lg-3 footer-contact-list mt-lg-0 mt-md-5 mt-2">
                <Typography variant="h6" className={classes.footerTitle}>
                  Social Media
                </Typography>
                <div className="main-social-footer-29">
                  <IconButton href="#" className={classes.socialIcon}>
                    <LinkedIn />
                  </IconButton>
                  <IconButton href="#" className={classes.socialIcon}>
                    <Instagram />
                  </IconButton>
                </div> */}
                {/* <Typography variant="body2" className={classes.copyRight}>
                  © {new Date().getFullYear()} Marketplace. All rights reserved.
                  Design by{' '}
                  <Link
                    href="https://www.cisin.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CIS
                  </Link>
                </Typography> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}
