import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: '600px',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  border: 'none',
  p: 2, // Padding for spacing
  '@media (min-width: 600px)': {
    width: 'auto',
  },
};

function CommonModal({ open, handleClose, title, children }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <div id="modal-modal-description" sx={{ mt: 2 }}>
          {children}
        </div>
      </Box>
    </Modal>
  );
}

export default CommonModal;
