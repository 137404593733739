// Functionality of this code : This page contains the Index page section
// Input : None
// Output : Index page (main file)
// Written by: Initial team VK, KR

import { React, StrictMode } from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import App from './App';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

// Redux
import { Provider } from 'react-redux';
import store from './store';

// staring point of application
ReactDOM.render(
  // Addressing the issues identified by strict mode now will
  //  make it easier for you to take advantage of concurrent rendering in future
  <StrictMode>
    {/* apply globle theme */}
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* app page entery point  */}
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
