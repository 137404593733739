// Functionality of this code : This page contains User profile
// Input : None
// Output : User Profile page
// Written by: Initial team VK, KR

var UserProfile = (function () {
  var full_name = '';

  var getName = function () {
    return full_name; // Or pull this from cookie/localStorage
  };

  var setName = function (name) {
    full_name = name;
    // Also set this in cookie/localStorage
  };

  return {
    getName: getName,
    setName: setName,
  };
})();

export default UserProfile;
