// Functionality of this code : This code contains buy unique gift voucher
// Input : None
// Output : Buy unique gift voucher
// Written by: CIS

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as firebase from 'firebase';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { Button, CardMedia, Checkbox } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import axios from 'axios';
import qs from 'qs';

import WatchLaterIcon from '@material-ui/icons/WatchLater';
import books from '../assets/books.jpg';
import housing from '../assets/housing.jpg';
import laptop from '../assets/laptop.jpg';
import school from '../assets/school.jpg';
import insurance from '../assets/insurance.jpg';
import moment from 'moment';
import mealPlan from '../assets/mealPlan.jpg';
import airmiles from '../assets/airmiles.jpg';
import glasses from '../assets/glasses.png';
import mrt from '../assets/mrt.jpg';
import certification from '../assets/certification.jpg';
import accessibility from '../assets/accessibility.jpg';
import counsel from '../assets/counsel.png';
import others from '../assets/others.jpg';

const useStyles = makeStyles(theme => ({
  cardMedia: {
    width: 120,
  },
  card: { wordWrap: 'break-word' },
  cardContenth1: {
    color: '#ed0a3f',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '30px',
  },
  cardGrid: { width: 1012 },
  cardContentDetail: {
    letterSpacing: '0.20px',
    wordWrap: 'break-word',
    marginLeft: '12px',
  },
  rectangleBox: {
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #00000029',
    height: '400px',
    left: '278px',
    position: 'absolute',
    top: '589px',
    width: '414px',
  },
  headLoginButton: {
    backgroundColor: '#232323',
    color: '#f2f2f2',
    '&:hover': {
      background: '#232323',
    },
  },
}));

export default function UniquegGift(props) {
  const [datas, setData] = React.useState([]);
  const [temp_datas, setTempData] = React.useState([]);
  const [invoiceData, setinvoiceData] = React.useState([]);
  const [need_type, setNeed_type] = React.useState([]);
  const [msg, setMsg] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [needData, setneedData] = React.useState([]);
  const classes = useStyles();

  let decrement = (item, index) => {
    const items = datas;
    let qua = item.quantity;
    if (qua > 1) {
      qua = item.quantity - 1;

      let count1 = true;
      if (count1) {
        need_type.forEach(function (el, i) {
          if (
            el.category === item.category &&
            el.amount === item.amount &&
            el.food_option === item.food_option &&
            el.auth_code != item.auth_code
          ) {
            need_type.splice(i, 1);
            count1 = false;
          }
        });
      }
      item.quantity = qua;
      setNeed_type([...need_type]);
      items.splice(index, 1, item);
      setData([...items]);
    }
  };

  let increment = async (item, index) => {
    let qua = item.quantity + 1;
    const items = datas;
    if (qua <= item.times) {
      let count = true;
      temp_datas.map(items_data => {
        if (count) {
          if (
            item.food_option == items_data.food_option &&
            item.category == items_data.category &&
            item.amount == items_data.amount &&
            item.auth_code !== items_data.auth_code
          ) {
            invoiceData.push(items_data);
            count = false;
            need_type.push(items_data);
          }
        }
      });
      item.quantity = qua;
      items.splice(index, 1, item);
      setinvoiceData([...invoiceData]);
      setData([...items]);
      setNeed_type([...need_type]);
    }
  };

  function timerMessage() {
    setTimeout(() => {
      setMsg(false);
    }, 1500);
  }

  useEffect(() => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then(async function (idToken) {
        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            auth: idToken,
          },
        };
        fetch(process.env.REACT_APP_PROXY + '/user/uniquegift', requestOptions)
          .then(res => res.json())
          .then(async data => {
            if (data.success === true) {
              if (data.data) {
                let temp = [];
                temp = data.data;
                if (props.data) {
                  setLoading(true);
                  var needFilter = temp.filter(
                    obj => obj.category == props.data || obj.category == 'Any'
                  );

                  const resultArray = [];
                  needFilter.map(item => {
                    if (
                      resultArray.find(object => {
                        if (
                          object.food_option === item.food_option &&
                          object.amount === item.amount &&
                          item.category == 'Food and Beverage'
                        ) {
                          object.times++;
                          return true;
                        } else if (
                          object.category === item.category &&
                          object.amount === item.amount &&
                          item.category != 'Food and Beverage'
                        ) {
                          object.times++;
                          return true;
                        } else {
                          return false;
                        }
                      })
                    ) {
                    } else {
                      item.quantity = 1;
                      item.times = 1;
                      resultArray.push(item);
                    }
                  });
                  setTempData([...needFilter]);
                  setData([...resultArray]);
                  setinvoiceData([...resultArray]);
                } else {
                  setLoading(false);
                  const resultArray = [];
                  await temp.map(item => {
                    if (
                      resultArray.find(object => {
                        if (
                          object.food_option === item.food_option &&
                          object.amount === item.amount &&
                          item.category == 'Food and Beverage'
                        ) {
                          object.times++;
                          return true;
                        } else if (
                          object.category === item.category &&
                          object.amount === item.amount &&
                          item.category != 'Food and Beverage'
                        ) {
                          object.times++;
                          return true;
                        } else {
                          return false;
                        }
                      })
                    ) {
                    } else {
                      item.times = 1;
                      item.quantity = 1;
                      resultArray.push(item);
                    }
                  });
                  setTempData([...temp]);
                  setData([...resultArray]);
                  setinvoiceData([...resultArray]);
                }
              } else {
                setLoading(false);
              }
            } else {
              setMsg(false);
            }
          })
          .catch(error => {});
      });
  }, []);
  let data3 = false;

  const handleOnTap = async (e, category, amount, foodType) => {
    var tempdata = [];
    const { checked, value, id } = e.target;
    if (checked) {
      if (!data3) {
        tempdata.push({
          id: id,
          auth_code: value,
          amount: amount,
          category: category,
          food_option: foodType,
        });
      }
      var x = need_type.concat(tempdata);
      setNeed_type(x);
    } else {
      let l = need_type.length;
      for (var i = 0; i < l; i++) {
        if (need_type[i]) {
          if (
            need_type[i].food_option == foodType &&
            need_type[i].category == category &&
            need_type[i].amount == amount
          ) {
            var index = need_type.indexOf(need_type[i]);
            need_type.splice(index, 1);
            setNeed_type([...need_type]);
            i = i - 1;
          }
        }
      }
    }
  };

  const onSubmit = () => {
    let result = invoiceData.filter(o1 =>
      need_type.some(o2 => o1.auth_code === o2.auth_code)
    );
    props.setPage('invoice');
    props.setData(result);
  };

  const onBack = () => {
    props.setPage('dashboard');
  };

  return (
    <>
      {msg === true || datas.length != 0 ? (
        <Typography
          variant="body1"
          paragraph
          style={{
            marginTop: '90px',
            marginBottom: '40px',
            textAlign: 'center',
            color: 'black',
            fontSize: '22px',
          }}
        >
          Choose from below
        </Typography>
      ) : (
        ''
      )}
      <Container maxWidth="lg" style={{ marginBottom: '10px' }}>
        {!datas.length && msg === true ? (
          <Box sx={{ display: 'flex', marginLeft: '50%' }}>
            <CircularProgress color="success" />
            {timerMessage()}
          </Box>
        ) : (
          <Grid container spacing={4}>
            {datas.length == 0 && msg === false ? (
              <h1 style={{ margin: '26% 0 25% 40%' }}>No Voucher Available</h1>
            ) : (
              <>
                {/* {datas} */}
                {datas.map((gift, index) => (
                  <Grid item key={gift.auth_code} xs={12} sm={6} md={4} lg={4}>
                    <Card
                      className={classes.card}
                      style={{ minHeight: '200px' }}
                    >
                      <Checkbox
                        checked={
                          need_type.some(e => gift.auth_code === e.auth_code)
                            ? true
                            : false
                        }
                        id={gift.auth_code}
                        value={gift.auth_code}
                        onChange={e =>
                          handleOnTap(
                            e,
                            gift.category,
                            gift.amount,
                            gift.food_option
                          )
                        }
                      />
                      <CardMedia
                        style={{ width: 75, margin: '-48px 0px 0px 25px' }}
                        image={
                          gift.category === 'Books'
                            ? books
                            : gift.category === 'Tuition'
                            ? school
                            : gift.category === 'Food and Beverage'
                            ? mealPlan
                            : gift.category === 'Pair Of Glasses'
                            ? glasses
                            : gift.category === 'Any'
                            ? others
                            : others
                        }
                        title="Image title"
                        component="img"
                      />
                      <CardContent className={classes.cardContent}>
                        {/* <Typography
                          gutterBottom
                          variant="h3"
                          className={classes.cardContenth1}
                        >
                          {gift.product_name}
                        </Typography> */}
                        {need_type.some(e => gift.auth_code === e.auth_code) ? (
                          <div disabled={need_type.length === 0}>
                            <button
                              className="btn btn-light"
                              style={{ margin: '12px' }}
                              onClick={() => decrement(gift, index)}
                            >
                              {' '}
                              -{' '}
                            </button>
                            {gift.quantity} &nbsp;
                            <button
                              className="btn btn-light"
                              onClick={() => increment(gift, index)}
                            >
                              {' '}
                              +{' '}
                            </button>
                            <br />
                          </div>
                        ) : null}

                        <Typography className={classes.cardContentDetail}>
                          <span style={{ fontWeight: 600 }}>Category :</span>
                          {gift.category}{' '}
                          {gift.food_option ? '(' + gift.food_option + ')' : ''}
                        </Typography>

                        <Typography className={classes.cardContentDetail}>
                          <span style={{ fontWeight: 600 }}>Amount :</span> $
                          {gift.amount} x ({gift.times})
                        </Typography>
                        {/* <Typography className={classes.cardContentDetail}>
                    <span style={{ fontWeight: 600 }}>Auth Code :</span>{' '}
                    {gift.auth_code}
                  </Typography> */}
                        {/* <Typography className={classes.cardContentDetail}>
                          <span style={{ fontWeight: 600 }}>
                            {' '}
                            Active Date :{' '}
                          </span>{' '}
                          {format(new Date(gift.active_date), 'yyyy-MM-dd')}
                        </Typography>
                        <Typography className={classes.cardContentDetail}>
                          <span style={{ fontWeight: 600 }}>
                            {' '}
                            Expiry Date :{' '}
                          </span>{' '}
                          {format(new Date(gift.expiry_date), 'yyyy-MM-dd')}
                        </Typography> */}
                        {/* <Typography className={classes.cardContentDetail}>
                    <span style={{ fontWeight: 600 }}>Voucher GUID :</span>{' '}
                    {gift.voucher_guid}
                  </Typography> */}
                        {/* <Typography className={classes.cardContentDetail}>
                      <span style={{ fontWeight: 600 }}>URL :</span>{' '}
                      {gift.short_url}
                    </Typography> */}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        )}
        {msg === true || datas.length != 0 ? (
          <div style={{ margin: '25px 0px 25px 35%' }}>
            <button
              className="btn btn-dark"
              style={{
                width: '20%',
                height: '50px',
                margin: '20px',
                backgroundColor: '#232323',
                color: '#f2f2f2',
                fontSize: '19px',
                fontWeight: '700',
                padding: '6px 27px',
                '&:hover': {
                  background: '#232323',
                },
              }}
              onClick={onBack}
            >
              Back
            </button>
            <button
              disabled={need_type.length === 0}
              className="btn btn-dark"
              style={{
                // margin: '20px 40% 10px',
                width: '20%',
                height: '50px',
                backgroundColor: '#232323',
                color: '#f2f2f2',
                fontSize: '19px',
                fontWeight: '700',
                padding: '6px 27px',
                '&:hover': {
                  background: '#232323',
                },
              }}
              onClick={onSubmit}
            >
              Proceed
            </button>
          </div>
        ) : (
          <button
            className="btn btn-dark"
            style={{
              width: '20%',
              height: '50px',
              backgroundColor: '#232323',
              color: '#f2f2f2',
              fontSize: '19px',
              fontWeight: '700',
              margin: '20px 43%',
              padding: '6px 27px',
              '&:hover': {
                background: '#232323',
              },
            }}
            onClick={onBack}
          >
            Back
          </button>
        )}
      </Container>
    </>
  );
}
