// Functionality of this code : This code has the provider's table on admin pannel
// Input : None
// Output : Provider admin page
// Written by: CIS

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import tableIcons from './MaterialTableIcons';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

export default function ProvidersTable() {
  const [error, setError] = React.useState('');
  const [provider, setStudent] = React.useState([]);
  const columns = [
    {
      title: 'ID',
      field: 'p_id',
    },
    {
      title: 'Provider',
      field: 'provider',
    },
  ];

  useEffect(() => {
    getCode();
  }, []);

  const getCode = () => {
    fetch(process.env.REACT_APP_PROXY + '/admin/providers')
      .then(res => res.json())
      .then(data => {
        let resp = data.data;
        if (data.success === false) {
          setError(data.message);
        } else {
          const unique = [...new Set(resp)];
          setStudent(unique);
        }
      });
  };

  const addProvider = value => {
    fetch(process.env.REACT_APP_PROXY + '/admin/addProvider', value)
      .then(res => res.json())
      .then(data => {
        if (data.success === true) {
          toast.success(data.message, { autoClose: 1000 });
        } else {
          toast.error(data.message, { autoClose: 1000 });
        }
      });
  };

  const editProvider = value => {
    fetch(process.env.REACT_APP_PROXY + '/admin/updateProvider', value)
      .then(res => res.json())
      .then(data => {
        if (data.success === true) {
          toast.success(data.message, { autoClose: 1000 });
        } else {
          toast.error(data.message, { autoClose: 1000 });
        }
      });
  };

  const deleteProvider = value => {
    fetch(process.env.REACT_APP_PROXY + '/admin/deleteProvider', value)
      .then(res => res.json())
      .then(data => {
        if (data.success === true) {
          toast.success(data.message, { autoClose: 1000 });
        } else {
          toast.error(data.message, { autoClose: 1000 });
        }
      });
  };

  return (
    <div
      className="content-wrapper"
      style={{ marginTop: '6%', overflow: 'hidden' }}
    >
      <MaterialTable
        title={<h3 style={{ color: '#F4113D' }}>Providers</h3>}
        icons={tableIcons}
        columns={columns}
        data={provider}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  newData,
                }),
              };
              addProvider(requestOptions);
              setStudent([...provider, newData]);
              resolve();
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  newData,
                }),
              };
              editProvider(requestOptions);
              setTimeout(() => {
                const dataUpdate = [...provider];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                setStudent([...dataUpdate]);
                resolve();
              }, 1000);
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              const requestOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  oldData,
                }),
              };
              deleteProvider(requestOptions);
              setTimeout(() => {
                const dataDelete = [...provider];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setStudent([...dataDelete]);
                resolve();
              }, 1000);
            }),
        }}
        options={{
          sorting: true,
          exportButton: true,
          actionsColumnIndex: -1,
          headerStyle: { color: '#2998D1' },
        }}
      />
    </div>
  );
}
