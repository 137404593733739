// Functionality of this code : This code has the admin pannel
// Input : None
// Output : admin page
// Written by: CIS

// import logo from './logo.svg';
import { ToastContainer } from 'react-toastify';

import Sidebar from './Sidebar';
import AdminLogin from './adminLogin';

function Adminapp() {
  return (
    <>
      <div class="wrapper">
        <ToastContainer />
        {/* <Header /> */}
        <Sidebar />
        {/* <Dashboard /> */}
        {/* <Footer></Footer> */}
      </div>
    </>
  );
}

export default Adminapp;
