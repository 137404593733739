// Functionality of this code : This page contains the University page section
// Input : None
// Output : University page
// Written by: Initial team VK, KR

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import UniversityRequestTable from './components/UniversityRequestTable';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  formatTab: {
    textTransform: 'none',
  },
}));

//Main landing page for university that encapsulate allrequests page.
export default function UniversityRequest(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };
  useEffect(() => {}, []);
  return (
    <div className='bannner-image'>
      {/* <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab
            className={classes.formatTab}
            label="New Requests"
            {...a11yProps(0)}
          />
          <Tab
            className={classes.formatTab}
            label="Approved Requests"
            {...a11yProps(0)}
          />
          <Tab
            className={classes.formatTab}
            label="Fullfilled Requests"
            {...a11yProps(0)}
          />
        </Tabs>
      </AppBar> */}
      <SwipeableViews
        disabled
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <UniversityRequestTable type="Active" />
        </TabPanel>
        {/* <TabPanel value={value} index={1} dir={theme.direction}>
          <UniversityRequestTable type="Active" />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <UniversityRequestTable type="Active" />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <UniversityRequestTable type="current" />
        </TabPanel> */}
      </SwipeableViews>
    </div>
  );
}
