// Functionality of this code : This page contains the Terms of service page section
// Input : None
// Output : Terms of service page
// Written by: Initial team VK, KR

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 800,
  },
}));

//Terms & condition Page
export default function TermsOfService(props) {
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      <Container maxWidth="lg">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Grid item xs={12} md={12}>
          <CardActionArea component="a" href="#">
            <Card className={classes.card}>
              <div className={classes.cardDetails}>
                <CardContent>
                  <Typography variant="h5" color="primary">
                    TERMS & CONDITIONS
                  </Typography>
                  <br />
                  <Typography variant="h6" paragraph align="justify">
                    1.GENERAL
                  </Typography>
                  <Typography variant="body2" paragraph align="justify">
                    1.1 These terms and conditions (the “Terms and Conditions”)
                    govern the use of Sponsor-a-student.io (the “Website”).
                  </Typography>
                  <Typography variant="body2" paragraph align="justify">
                    1.2 The Website operates as an online platform to meet the
                    funding needs of underprivileged students in their tertiary
                    education pursuits by connecting these students (the
                    “Student”) in our partnered educational institutions or
                    registered organisations (the “Organisation”) with sponsors
                    (the “Sponsor”) and facilitating the donations of the
                    Sponsor to the requests made by the Students or Organisation
                    (the “Services”). For the purposes of these Terms and
                    Conditions, “you” shall mean any user of the Website
                    including but not limited to a Student, Sponsor or
                    Organisation.
                  </Typography>
                  <Typography variant="body2" paragraph align="justify">
                    1.3 By accessing or using this Website, you are entering
                    into a legally binding agreement with Sammantrana Consulting
                    Pte. Ltd. (Company registration no. 201827897K and having
                    its registered address at 5 Keppel Bay View #08-20,
                    Reflections at Keppel Bay, Singapore 098404) (“Sammantrana”,
                    “we”, “our” or “us”) and its subsidiaries, associates and
                    officers and you agree to be bound by these Terms and
                    Conditions and our Privacy Policy.
                  </Typography>
                  <Typography variant="body2" paragraph align="justify">
                    1.4 Please read these Terms and Conditions and our Privacy
                    Policy carefully and if you do not wish to be bound by these
                    Terms and Conditions and Privacy Policy, you should not
                    continue to use or access the Website or our Services
                    immediately.
                  </Typography>
                  <Typography>
                    1.5 We reserve the right, in our sole discretion, to update
                    or revise these Terms and Conditions. Your continued use and
                    access of the Website and our Services would constitute your
                    agreement to the modifications of these Terms and Conditions
                    and shall be effective upon the date of each revision and
                    each posting. We are under no obligation to separately
                    inform you of any such revision.
                  </Typography>
                  <Typography>
                    1.6 We reserve the right to update or modify the Website
                    from time to time. We do not guarantee that the Website, or
                    any content on it, will always be available or be
                    uninterrupted. We may pursuant to Clause 9 of these Terms
                    and Conditions suspend or withdraw or restrict the
                    availability of all or any part of the Website at any time
                    without ascribing any reasons.
                  </Typography>
                  <Typography>
                    1.7 By accessing and using the Website, you confirm,
                    represent and warrant to us as follows: (a) where you are
                    accessing the Website as a Student or individual Sponsor,
                    you warrant and represent that: (i) you have attained 18
                    years of age, or, if you are younger than 18 years of age,
                    that you have obtained the consent of your parent or legal
                    guardian, or you are under the supervision of your parent or
                    legal guardian; and (ii) you are fully able and competent to
                    enter into and abide by the terms, conditions, obligations,
                    affirmations, representations, and warranties set forth in
                    these Terms and Conditions, and to abide by and comply with
                    these Terms and Conditions; (b) where you are accessing the
                    Website as an authorised representative of a company or
                    corporate Sponsor or Organisation, such company or
                    corporation is duly incorporated (where applicable) and
                    validly existing under the laws of the country of its
                    incorporation, with full power and authority to perform and
                    comply with your obligations in relation to the use of the
                    Website and the transactions contemplated thereunder (if
                    any) and pursuant to these Terms and Conditions; (c) all
                    actions, conditions and steps required to be taken,
                    fulfilled and done (including the obtaining of any necessary
                    consents) in order to enable you to lawfully perform and
                    comply with your obligations in relation to the use of the
                    Website, the transactions contemplated thereunder and
                    pursuant to these Terms and Conditions, and to ensure that
                    such obligations are legally binding on and enforceable
                    against you, have been taken, fulfilled and done; (d) your
                    entry into, or performance of or compliance with your
                    obligations in relation to the use of the Website, the
                    transactions contemplated thereunder and pursuant to these
                    Terms and Conditions do not and will not breach any law to
                    which you are subject to, your constitutional documents or
                    any agreement to which you are a party to or which is
                    binding on you and your assets; (e) your obligations in
                    relation to the use of the Website, the transactions
                    contemplated thereunder and pursuant to these Terms and
                    Conditions are valid, binding and enforceable; (f) all
                    information furnished by you or on your behalf is true,
                    authentic, accurate, up-to-date, verifiable and complete;
                    and (g) it is not illegal or unlawful for you to access the
                    Website from the territory in which you are doing so.
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    2. ACCOUNT REGISTRATION FOR STUDENTS / SPONSORS /
                    ORGANISATIONS
                  </Typography>
                  <Typography>
                    In order to access the Services, a Student / Sponsor /
                    Organisation must create a user account (an “Account”) and
                    be successfully registered by us on the Website as a user (a
                    “Registered User”).
                  </Typography>
                  <Typography>
                    2.1 Account Registration for Students (a) As part of the
                    registration process, a Student may be required to provide
                    certain information to us, including, but not limited to a
                    Student’s first and last name, tertiary education
                    institution’s email address, student identification number,
                    country / state where the Student is located, course
                    enrolled, year of enrolment and choose a password
                    (collectively, the “ID”). (b) As part of the verification
                    process, the Student will be required to acknowledge the
                    verification email sent to their education institution’s
                    email address. (c) In the event that a Student is below 18
                    years of age, that Student’s parents or legal guardian will
                    be required to furnish the ID on behalf of the Student.
                  </Typography>
                  <Typography>
                    2.2 Account Registration for Sponsors (a) As part of the
                    registration process, a Sponsor may be required to provide
                    certain information to us, including, but not limited to a
                    Sponsor’s first and last name, personal/work email address
                    and choose a password (collectively, the “ID”). (b) A
                    Sponsor will also be required to indicate their preferences
                    for funding including but not limited to the maximum amount
                    that they would be donating [which is subject to any changes
                    by written notice to Sammantrana], frequency of donation and
                    any other preferences such as region, state, university and
                    age group of students. [AP: Please confirm the list of
                    preferences which the Sponsor will be allowed to indicate on
                    the registration form and whether the maximum amount that
                    can be donated can be varied from time to time by written
                    notice to Sammantrana.]
                  </Typography>
                  <Typography>
                    2.3 Account Registration for Organisations As part of the
                    registration process, a representative of the Organisation,
                    on behalf of the Organisation, may be required to provide
                    certain information to us including, but not limited to a
                    user ID and password, contact email and work contact number
                    (collectively, the “ID”). [AP: Please confirm the types of
                    information that the representative of the Organisation will
                    be required to provide when creating a genetic profile on
                    the Website.]
                  </Typography>
                  <Typography>
                    2.4 Sammantrana reserves the right to deactivate an Account
                    if the Student, Sponsor or Organisation is seen to be using
                    proxy IPs (Internet Protocol addresses) in order to attempt
                    to hide the use of multiple Accounts, or disrupts any of our
                    Services in any way.
                  </Typography>
                  <Typography>
                    2.5 In applying to be a Registered User, you represent,
                    warrant and undertake to us on the date of registration and
                    on each day thereafter that you are a Registered User that
                    all registration information you submit is accurate,
                    truthful, complete and lawful and you own or are authorised
                    to furnish such registration information. You further
                    warrant that you will maintain the accuracy of such
                    information and update the information as and when
                    necessary.
                  </Typography>

                  <Typography>
                    2.6 You are solely responsible for all activity that occurs
                    on your Account, for use of the Website by you or anyone
                    else using your ID, for preventing any unauthorised use of
                    your ID and for maintaining the confidentiality of your ID
                    and keeping it secure. You agree not to use the registered
                    Account and/or ID of another user at any time or to disclose
                    your password to any third party. Please notify us
                    immediately if you believe there has been any breach of
                    security such as the disclosure, theft or unauthorised use
                    of your ID.
                  </Typography>
                  <Typography>
                    2.7 By creating an Account on the Website, you agree to
                    receive certain communications in connection with use of the
                    Website. You will also receive our marketing emails such as
                    our newsletters. [AP: Please confirm if Sammantrana will be
                    sending marketing emails/newsletters to its users.]
                  </Typography>
                  <Typography>
                    2.8 By submitting your information and agreeing to our Terms
                    and Conditions, you consent to our collection, use, and
                    disclosure of your personal data to parties such as
                    Sammantrana’s employees, service providers, vendors, and
                    systems for the purposes of processing your application and
                    registration as a Registered User of the Website; for
                    communication regarding a Student’s funding request(s)
                    through email, notifying a Sponsor on the funding request(s)
                    raised by a Student; direct mail and facsimile messages; for
                    payment and/or credit control purposes; to notify you of any
                    changes to our policies or Services which may affect you; to
                    respond to queries and feedback; for identification and
                    Website access; maintaining and updating your user details;
                    and informing you of new developments, Services of
                    Sammantrana and other third parties which we are associated
                    with.
                  </Typography>
                  <Typography>2.9 Deleting your Account</Typography>
                  <Typography>
                    If you no longer wish to be a Registered User or use the
                    Services, you may delete your Account at any time, provided
                    that:
                  </Typography>
                  <Typography>
                    (a) for Students, all pending funding requests raised by you
                    will no longer be fulfilled or matched with a Sponsor;
                  </Typography>
                  <Typography>
                    (b) for Sponsors, you will no longer receive notifications
                    on any Student’s funding requests and access to the Sponsor
                    page; and
                  </Typography>
                  <Typography>
                    (c) for Organisations, you will no longer have access to the
                    overview of the requests raised by the Students or meet the
                    funding requests of the Students. [AP: Please confirm if
                    Sammantrana would allow for re-activation of deleted
                    Accounts.]
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    3. SUBMISSION OF A FUNDING REQUEST & STUDENT’S PLEDGE
                  </Typography>
                  <Typography>
                    3.1 Once Sammantrana registers the Student as a Registered
                    User, the Student may raise a funding request with us for a
                    need or a micro-need such as tuition fees, accommodation
                    costs, meal plans, laptops, books and materials, health
                    insurance, air tickets, boarding fees or any other
                    education-related expense by completing and submitting the
                    request (the “Request”) on the Website.
                  </Typography>
                  <Typography>
                    3.2 The Student will also be required to furnish details of
                    the Request such as the amount requested, due date for
                    payment of the Request, and where applicable, the invoice
                    number, payment URL by a third-party payment provider or
                    vendor or web address to a product listing in relation to
                    the Request and make a declaration whether the Student has
                    received a government bursary or scholarship for that
                    academic year.
                  </Typography>
                  <Typography>
                    3.3 The final step before submitting the Request would be
                    for the Student to pledge to contribute back to Sammantrana
                    immediately after the Request has been submitted or at a
                    later date. The Student may choose to either commit to a set
                    number of volunteer hours with a charity or organisation of
                    Sammantrana’s choice or commit to sponsor future Requests of
                    other students at a later date.
                  </Typography>
                  <Typography>
                    3.4 By submitting a Request, the Student undertakes that all
                    the details provided to us are true and accurate.
                    Sammantrana shall be entitled to reject or discontinue the
                    processing and fulfilment of any Request containing false or
                    inaccurate information without any further reference or
                    notice to the Student.
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    4. MATCHING & PAYMENT OF A REQUEST [AP: We have provided for
                    the Sponsor to make payment directly to the third-party
                    payment provider or vendor or to the product webpage as
                    informed by the Student in their Request in clause 4.2
                    below, please let us know if this needs to be amended.]
                  </Typography>
                  <Typography>
                    4.1 Once Sammantrana registers the Sponsor as a Registered
                    User, the Sponsor will be notified by email whenever a
                    Student’s Request which meets their donation preferences
                    have been identified.
                  </Typography>
                  <Typography>
                    4.2 The Sponsor will be able to view the list of Requests
                    assigned to the Sponsor by signing into their Account. The
                    Sponsor may make payment for a Request via their preferred
                    mode of payment by credit card or debit card (credit card
                    and debit cards collectively, Cards and each a Card) or such
                    other payment methods as may be provided by any third-party
                    payment provider or vendor, by:
                  </Typography>
                  <Typography>
                    (a) accessing and making payment on the web address of any
                    third-party payment site as provided by the Student in such
                    Request;
                  </Typography>
                  <Typography>
                    (b) accessing and making payment on the web address for any
                    third-party online product listing provided by the Student
                    in such Request, with direct delivery of said product to the
                    Student; or
                  </Typography>
                  <Typography>
                    (c) any such other third-party payment methods as may be
                    permitted from time to time on the Website.
                  </Typography>
                  <Typography>
                    4.3 If the Card belongs to any other person, the Sponsor
                    hereby represents, warrants and agrees that he/she has
                    obtained their permission to use the Card for the payment of
                    the Request.
                  </Typography>
                  <Typography>
                    4.4 The Sponsor agrees and acknowledges that:
                  </Typography>
                  <Typography>
                    (a) He/she will cooperate in relation to any financial crime
                    screening that is required and to assist us in complying
                    with any prevailing laws or regulations in place;
                  </Typography>
                  <Typography>
                    (b) He/she shall be responsible to resolve any disputes with
                    the institution issuing the relevant Card on his/her own;
                  </Typography>
                  <Typography>
                    (c) In the event that the Sponsor’s payment by Card is
                    issued outside of Singapore, the Sponsor will be liable for
                    any additional charges in relation thereto; and
                  </Typography>
                  <Typography>
                    (d) In making payment for the Request, he/she will be
                    subject to the additional terms and conditions of third
                    party service providers.
                  </Typography>
                  <Typography>
                    4.5 The Sponsor is solely responsible for observing the
                    rules of and assessing the accuracy of any information
                    provided under any third-party payment links and assumes the
                    entire risk of any loss (whether direct, indirect or
                    consequential) incurred from the use of such third-party
                    sites. Sammantrana disclaims all responsibility and
                    liability for any use of third-party sites by any Student,
                    Sponsor or Organisation for the fulfilment of any Request,
                    any information collected from such third-party sites, any
                    fault or error in payment from the use of such third-party
                    sites, and any damages or other harm caused by such
                    third-party sites whether to any Student, Sponsor or
                    Organisation.
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    5. ORGANISATION’S VALIDATION AND REVIEW OF REQUESTS
                  </Typography>
                  <Typography>
                    5.1 Once Sammantrana registers the Organisation as a
                    Registered User, the Organisation will be able to review the
                    Requests raised by the Students that are introduced by the
                    Organisation to the Website or are currently students of the
                    Organisation.
                  </Typography>
                  <Typography>
                    5.2 Where applicable, the Organisation would have conducted
                    means testing on the Students before their Account can be
                    registered.
                  </Typography>
                  <Typography>
                    5.3 The Organisation will verify the Requests submitted by
                    the Students linked or tagged to the Organisation
                    independently.
                  </Typography>
                  <Typography>
                    5.4 Where a Sponsor has funded the Request, the Organisation
                    would also be similarly notified for Requests in relation to
                    tuition fees and needs that the Organisation has validated
                    such requests for.
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    6. PROPRIETARY RIGHTS
                  </Typography>
                  <Typography>
                    6.1 This Website is owned and operated by Sammantrana.
                  </Typography>
                  <Typography>
                    6.2 The content located on this Website, including, but not
                    limited to the information, text, software, scripts,
                    graphics, photographs, sounds, music, videos, button icons,
                    illustrations, audio clips, data compilations, compilation,
                    arrangement, interactive features made available on or
                    through this Website (collectively, the “Content”), are
                    protected by copyright, trademark and other forms of
                    proprietary rights. All rights, title and interest in the
                    Content are owned by, licensed to or controlled by
                    Sammantrana.
                  </Typography>
                  <Typography>
                    6.3 The names, images and logos identifying
                    Sponsor-a-Student (the “Marks”) are subject to copyright,
                    design rights and trademarks of Sammantrana and all rights
                    to the Marks are expressly reserved by us. You shall have no
                    right or licence to download, reproduce or use any such
                    Marks or the name of Sponsor-a-student whether for use as a
                    link to any website or otherwise, except with our prior
                    written approval.
                  </Typography>
                  <Typography>
                    6.4 Reproduction of part or all of the Contents in any form
                    is prohibited other than in accordance with the following
                    permissions:
                  </Typography>
                  <Typography>
                    (a) provided that these Terms and Conditions are complied
                    with, you may:
                  </Typography>
                  <Typography>(i) view and display the Content; and</Typography>
                  <Typography>
                    (ii) print or download to a local or network hard drive
                    extracts from or whole pages for record keeping purposes,
                    provided no copyright notice may be deleted from any such
                    printouts and downloads; and
                  </Typography>
                  <Typography>
                    (b) no paper or digital copies of any materials that have
                    been printed off or downloaded in any way from the Website
                    may be modified, and no illustration, photograph, video or
                    audio sequence or any graphics therefrom may be used
                    separately from any accompanying text.
                  </Typography>
                  <Typography>
                    6.5 By accessing and using the Website, save as permitted in
                    paragraph 6.4, you shall not imitate, modify, copy, extract,
                    reverse engineer, disassemble, decompile, distribute,
                    transmit, display, perform, reproduce, re-utilise, publish,
                    license, create derivative works from, transfer, or sell, in
                    full or in part and in any way, the Website or any Content
                    therein. You acknowledge that unauthorised modification
                    and/or interference, misuse, abuse or unauthorised use of
                    the Website or any Content may violate our intellectual
                    property rights, and may also be an offence under applicable
                    laws.
                  </Typography>
                  <Typography>
                    6.6 Without prejudice to the foregoing, you further agree
                    that you shall not:
                  </Typography>
                  <Typography>
                    (a) use parts of the Website to create and/or publish your
                    own database;
                  </Typography>
                  <Typography>
                    (b) frame or use framing techniques to enclose any trade
                    mark, logo, or other proprietary information of Website;
                  </Typography>
                  <Typography>
                    (c) link to any part of the Website for any purpose, unless
                    our express written consent has been obtained; or
                  </Typography>
                  <Typography>
                    (d) remove, circumvent, disable, damage or otherwise
                    interfere with security-related features of the Website,
                    including but not limited to any features that are designed
                    to verify the identity of the Registered User;
                  </Typography>
                  <Typography>
                    (e) prevent or restrict the access to or use of any
                    particular functionalities and/or facilities of the Website;
                    or
                  </Typography>
                  <Typography>
                    (f) prevent or restrict the access to, use of, or the
                    copying of any Content that is made available or accessible
                    through the Website.
                  </Typography>
                  <Typography>
                    6.7 We reserve our rights to report any unauthorised use or
                    misuse of the information to the relevant authorities,
                    whether located in Singapore or overseas.
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    7. PROHIBITIONS OF USAGE OF WEBSITE
                  </Typography>
                  <Typography>
                    7.1 You may not use this Website for any purpose that is
                    unlawful or prohibited by these Terms and Conditions, or to
                    solicit the performance of any illegal activity or other
                    activity which infringes the rights of Sammantrana.
                    Notwithstanding any other rights or restrictions in these
                    Terms and Conditions, you may not use this Website to:
                  </Typography>
                  <Typography>
                    (a) transmit, distribute, store or destroy via or through
                    the Website any information, data, text, images, files,
                    links, or software in violation of any applicable law or
                    regulation, except in connection with your authorised use of
                    this Website or otherwise in response to specific requests
                    for information by Sammantrana;
                  </Typography>
                  <Typography>
                    (b) send any unsolicited emails that constitutes as spam to
                    or through this Website;
                  </Typography>
                  <Typography>
                    (c) disrupt or damage the Website, its contents or its
                    security measures or to harass or disparage Sammantrana or
                    its Services or personnel;
                  </Typography>
                  <Typography>
                    (d) introduce to the Website or any other computer or
                    website viruses, worms, Trojan horses and/or harmful codes;
                  </Typography>
                  <Typography>
                    (e) obtain unauthorised access to any computer system;
                  </Typography>
                  <Typography>
                    (f) impersonate any other person, including but not limited
                    to, a Registered User of this Website or an employee or
                    administrator of Sammantrana;
                  </Typography>
                  <Typography>
                    (g) transmit, distribute, store or destroy any material in a
                    manner that will invade the privacy, violate or infringe any
                    personal or proprietary right (including intellectual
                    property rights) of any person or entity or that is
                    libelous, obscene, threatening, abusive or hateful;
                  </Typography>
                  <Typography>
                    (h) misrepresent the identity of a Registered User or use a
                    false e-mail address;
                  </Typography>
                  <Typography>
                    (i) tamper with or obtain access to this Website or any
                    component of this Website;
                  </Typography>
                  <Typography>
                    (j) conduct fraudulent activities; and
                  </Typography>
                  <Typography>
                    (k) collect or harvest information regarding other
                    Registered Users of the Website for any reason whatsoever,
                    including, without limitation, for sending such Registered
                    Users unsolicited commercial e-mails.
                  </Typography>
                  <Typography>
                    7.2 If you default negligently or wilfully in any of the
                    obligations set forth in these Terms and Conditions
                    (including our Privacy Policy), you shall be liable for all
                    the losses and damages that this may cause to Sammantrana
                    and/or our affiliates, partners or licensors.
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    8. USER CONDUCT
                  </Typography>
                  <Typography>
                    8.1 In addition, as a condition of submitting a Request or
                    any information to the Website, you agree that:
                  </Typography>
                  <Typography>
                    (a) you are solely responsible for the information you
                    provide including your Registration Information, and all
                    information you provide will be accurate, honest, truthful
                    and complete in all respects and Sammantrana gives no
                    representation, warranty or undertaking that any
                    information, data or other content that You store on or
                    provide to us will not be subject to inadvertent damage,
                    temporary unavailability, corruption or loss; and
                  </Typography>
                  <Typography>
                    (b) Sammantrana will not be required or will be liable to
                    review the information you provide in relation to a Request
                    or your Registration Information posted onto the Website.
                  </Typography>
                  <Typography>
                    8.2 We also have the right to disclose your identity to any
                    third party who is claiming any content you posted or
                    uploaded to our Website constitutes a violation of their
                    intellectual property rights or of their right to privacy,
                    in accordance with our Privacy Policy.
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    9. END OF USE
                  </Typography>
                  <Typography>
                    9.1 We may suspend or terminate your Account or cease
                    providing you with all or part of the Services at any time
                    for any or no reason, including, but not limited to, not
                    permitting any Request to be fulfilled by a Sponsor
                    notwithstanding that a Request has been submitted, reviewed
                    or approved if we reasonably believe:{' '}
                  </Typography>
                  <Typography>
                    (a) you have violated these Terms and Conditions;
                  </Typography>
                  <Typography>
                    (b) you create risk or possible legal exposure for us;
                  </Typography>
                  <Typography>
                    (c) your Account should be removed due to unlawful conduct;
                  </Typography>
                  <Typography>
                    (d) your Account should be removed due to prolonged
                    inactivity;{' '}
                  </Typography>
                  <Typography>
                    (e) inappropriate or unauthorised use of an ID in
                    registering your Account has been made out; or
                  </Typography>
                  <Typography>
                    (f) if you are a Student, that you have not attained 18
                    years of age and/or have misrepresented your obtainment of
                    parental or guardian consent or supervision in creating an
                    Account with us.
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    10. DISCLAIMER OF LIABILITY
                  </Typography>
                  <Typography>
                    10.1 The site is presented "as is." Sammantrana makes no
                    representations or warranties of any kind whatsoever,
                    express or implied, in connection with these terms and
                    conditions or the Website, and exclude all representations,
                    warranties and conditions of any kind whatsoever, express or
                    implied, in connection with these terms and conditions or
                    the Website, including but not limited to warranties or
                    conditions of satisfactory quality, merchantability,
                    non-infringement or fitness for a particular purpose, except
                    to the extent such representations and warranties are not
                    legally excludable.
                  </Typography>
                  <Typography>
                    10.2 You agree that, to the fullest extent permitted by
                    applicable law, we will not be responsible or liable
                    (whether in contract, tort (including negligence) or
                    otherwise), under any circumstances, for any:
                  </Typography>
                  <Typography>
                    (a) interruption of the Service provided to the Student,
                    Sponsor or Organisation;
                  </Typography>
                  <Typography>
                    (b) loss or damage arising from the access or use of the
                    Website or reliance on information in the Website whether
                    furnished by the Student, Sponsor or Organisation;
                  </Typography>
                  <Typography>
                    (c) access delays or access interruptions to the Website;{' '}
                  </Typography>
                  <Typography>
                    (d) data non-delivery, misdelivery, corruption, destruction
                    or other modification;{' '}
                  </Typography>
                  <Typography>
                    (e) loss or damages of any sort incurred as a result of
                    dealings with or the presence of off-website links on the
                    Website;{' '}
                  </Typography>
                  <Typography>
                    (f) computer viruses, system failures or malfunctions which
                    may occur in connection with your use of the Website,
                    including during hyperlink to or from third party websites;{' '}
                  </Typography>
                  <Typography>
                    (g) any inaccuracies or omissions in content in the Requests
                    submitted; or
                  </Typography>
                  <Typography>
                    (h) events beyond our reasonable control.
                  </Typography>
                  <Typography>
                    10.3 Further, to the fullest extent permitted by law, we
                    will not be liable for any indirect, special, punitive,
                    incidental, or consequential damages of any kind (including
                    lost profits) related to the Website or your use thereof
                    regardless of the form of action whether in contract, tort
                    (including negligence) or otherwise, even if we have been
                    advised of the possibility of such damages.
                  </Typography>
                  <Typography>
                    10.4 You agree that no claims or action arising out of, or
                    related to, the use of the Website or these Terms and
                    Conditions may be brought by you more than one (1) year
                    after the cause of action relating to such claim or action
                    arose.
                  </Typography>
                  <Typography>
                    10.5 The Contents of this Website do not constitute
                    financial, legal or other professional advice. If financial,
                    legal or other professional advice is required, services of
                    a competent professional should be sought.{' '}
                  </Typography>
                  <Typography>
                    10.6 You are responsible for configuring your information
                    technology, computer programmes and platform to access our
                    Website. You should use your own virus protection software.
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    11. PERSONAL DATA
                  </Typography>
                  <Typography>
                    11.1 You acknowledge and confirm that you have reviewed our
                    privacy policy (a copy of which is available on our Website
                    [Insert Link]) (the “Privacy Policy”) and agree to its
                    terms.{' '}
                  </Typography>
                  <Typography>
                    11.2 Any personal data submitted to us whether through the
                    Website or otherwise is dealt with according to the Privacy
                    Policy and we may collect and use personal data belonging to
                    you in accordance with the provisions of the Personal Data
                    Protection Act 2012 (the “PDPA”).
                  </Typography>
                  <Typography>
                    11.3 If you provide or have provided any information
                    (including personal data) relating to another person to us,
                    you warrant and represent to us that you have obtained that
                    person’s consent for the collection, use and disclosure of
                    their information as set out in these Terms and Conditions
                    and the Privacy Policy and in accordance with the provisions
                    of the PDPA.{' '}
                  </Typography>
                  <Typography>
                    11.4 Any consent given pursuant to these Terms and
                    Conditions in relation to any personal data shall, subject
                    to all applicable laws and regulations, survive death,
                    incapacity, bankruptcy or insolvency of any such individual.{' '}
                  </Typography>
                  <Typography>
                    11.5 If Sammantrana engages in a merger, acquisition,
                    dissolution, reorganization, sale of some or all of its
                    assets or stock, financing, public offering of securities,
                    acquisition of all or a portion of our business, a similar
                    transaction or proceeding, or steps in contemplation of such
                    activities (e.g. due diligence), some or all of the
                    information of the Registered Users may be shared or
                    transferred, subject to standard confidentiality
                    arrangements.
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    12. INDEMNITY
                  </Typography>
                  <Typography>
                    You agree to indemnify, defend and hold harmless
                    Sammantrana, its directors, officers, employees,
                    consultants, agents, and affiliates, from any and all third
                    party claims, liability, damages and/or costs (including,
                    but not limited to, legal fees) arising from your use or
                    misuse of this Website or Services or your violation or
                    breach of the Terms and Conditions, whether or not
                    referenced herein. You also agree to indemnify us for any
                    loss, damages, or costs, including reasonable attorneys'
                    fees, resulting from your use of software robots, spiders,
                    crawlers, or similar data gathering and extraction tools, or
                    any other action you take that imposes an unreasonable
                    burden or load on our infrastructure.
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    13. NO AGENCY
                  </Typography>
                  <Typography>
                    No agency, partnership, joint venture, employer-employee or
                    franchisor-franchisee relationship is intended or created by
                    these Terms and Conditions. No agency, partnership, joint
                    venture, employer-employee or franchisor-franchisee
                    relationship is intended, exists or is created between
                    Sammantrana and any user, Student, Sponsor and/or
                    Organisation.
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    14. THIRD PARTY LINKS
                  </Typography>
                  <Typography>
                    14.1 Sammantrana is not responsible for the content of any
                    off-website pages or any other pages linked to or from the
                    Website and assumes no liability for the unaffiliated sites
                    to which it is linked.
                  </Typography>
                  <Typography>
                    14.2 Your linking to or from any off-website pages or other
                    websites is at your own risk. Sammantrana is in no way
                    responsible for examining or evaluating, and we do not
                    warrant the offerings of, off-website pages or any other
                    websites linked to or from the Website, nor do we assume any
                    responsibility or liability for the actions, content,
                    products or services of such pages and websites, including,
                    without limitation, their privacy policies and terms and
                    conditions. You should carefully review the terms and
                    conditions and privacy policies of all off-website pages and
                    other websites that you visit.
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    15. ASSIGNMENT
                  </Typography>
                  <Typography>
                    15.1 This agreement between You and us as constituted by
                    these Terms and Conditions as modified from time to time may
                    not be assigned by You without the prior written approval of
                    Sammantrana but may be assigned without Your consent by
                    Sammantrana. Any purported assignment by You in violation of
                    this section shall be void.
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    16. FORCE MAJEURE
                  </Typography>
                  <Typography>
                    16.1 Sammantrana shall not be in breach of these Terms and
                    Conditions nor liable for delay in performing or failure to
                    perform, any of its obligations or Services under these
                    Terms and Conditions if such delay or failure results from
                    events, circumstances or causes beyond its reasonable
                    control including without limitation Acts of God,
                    governmental acts, war, fire, floods, explosions or civil
                    commotions.
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    17. SEVERABILITY
                  </Typography>
                  <Typography>
                    17.1 If a term of these Terms and Conditions is or becomes
                    illegal, invalid or unenforceable in any jurisdiction, that
                    will not affect the legality, validity or enforceability in
                    that jurisdiction of any other term of these Terms and
                    Conditions.
                  </Typography>
                  <Typography variant="h6" paragraph align="justify">
                    18.GOVERNING LAW AND DISPUTES
                  </Typography>
                  <Typography>
                    18.1 By visiting this Website, you agree that the laws of
                    Singapore, without regard to principles of conflict of laws,
                    will govern these Terms and Conditions and any claim arising
                    from or related to any use of our Services that might arise
                    between you and Sammantrana.
                  </Typography>
                  <Typography>
                    18.2 The courts of Singapore will have
                    [exclusive][non-exclusive] jurisdiction over any legal
                    action or the proceedings against Sammantrana arising out of
                    or related to any use of our Services that might arise
                    between you and Sammantrana. [AP: Please confirm if the
                    courts of Singapore will have exclusive or non-exclusive
                    jurisdiction over any legal action/proceedings against
                    Sammantrana.]
                  </Typography>

                  <br />
                </CardContent>
              </div>
            </Card>
          </CardActionArea>
        </Grid>

        <br />
        <br />
      </Container>
    </>
  );
}
