// Functionality of this code : This code contains Student voucher details
// Input : None
// Output : Student voucher
// Written by: CIS

import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as firebase from 'firebase';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import tableIcons from '../admin/MaterialTableIcons';

export default function StudentVoucher(props) {
  const theme = useTheme();
  const [gOptions, setG] = React.useState([]);
  const [gifts, setGift] = React.useState([]);
  const [gOptionsUsed, setGUsed] = React.useState([]);
  const [giftsUsed, setGiftUsed] = React.useState([]);

  const columnsG = [
    {
      title: 'Voucher Name',
      field: 'label',
    },
    {
      title: 'Amount',
      field: 'amount',
    },
    {
      title: 'URL',
      field: 'url',
    },
    {
      title: 'Gifted From',
      field: 'email',
    },
    {
      title: 'Gifted Date',
      field: 'date',
      type: 'date',
    },
  ];

  const onBack = () => {
    props.setPage('dashboard');
  };

  let temp = [];
  let tempUsed = [];

  const dataF = async () => {
    await firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then(async function (tokenResult) {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            auth: tokenResult.token,
          },
          body: JSON.stringify({ tokenResult }),
        };
        let val;

        fetch(process.env.REACT_APP_PROXY + '/user/cartItemStu', requestOptions)
          .then(res => res.json())
          .then(async data => {
            data.data.map(e => {
              val = e;
              giftsUsed.push(val);
            });

            await giftsUsed.map(e => {
              let obj = {};
              obj.value = e.id;
              obj.label = e.product_name;
              obj.id = e.id;
              obj.amount = e.amount;
              obj.product = e.product_id;
              obj.email = e.email;
              obj.date = e.gift_date;
              obj.expiry = e.expiry_date;
              obj.url = e.url;
              tempUsed.push(obj);
              setGUsed([...tempUsed]);
            });
          })
          .catch(error => {});
      });
  };

  React.useEffect(() => {
    dataF();
  }, []);

  return (
    <>
      <Container
        maxWidth="lg"
        component="main"
        style={{ paddingTop: 90, marginBottom: '70px' }}
      >
        <h2 style={{ textAlign: 'center' }}>Vouchers</h2>
        <MaterialTable
          // isLoading={gOptionsUsed.length > 0 ? false : true}
          icons={tableIcons}
          title=""
          options={{
            actionsColumnIndex: -1,
            search: false,
            pageSize: 3,
            pageSizeOptions: [3, 5, 10, 20],
            toolbar: false,
            paging: true,
            headerStyle: {
              backgroundColor: theme.palette.primary.main,
              color: 'white',
            },
            actionsCellStyle: {
              minWidth: 80,
            },
          }}
          columns={columnsG}
          data={gOptionsUsed}
        />
        <button
          className="btn btn-dark"
          style={{
            width: '20%',
            height: '50px',
            fontSize: '25px',
            margin: '3% 38% -50px',
          }}
          onClick={onBack}
        >
          Back
        </button>
      </Container>
    </>
  );
}
